import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { getTicket, deEscalateTicket } from "../../actions/ticket_actions";
import Loader from "react-loader";
import { formatMessages } from "../../helpers/ticket_helper";
import moment from "moment";
import { motion } from "framer-motion";

const Escalated = ({
  escalatedTickets,
  tickets,
  getTicket,
  deEscalateTicket,
  currentUser,
}) => {
  const [showList, setShowList] = useState(false);

  const [activeEmployeeID, setActiveEmployeeID] = useState();

  useEffect(() => {
    if (!escalatedTickets || escalatedTickets.length < 1) {
      setShowList(false);
    }
  });

  useEffect(() => {
    if (currentUser && currentUser.employeeID && !activeEmployeeID) {
      setActiveEmployeeID(parseInt(currentUser.employeeID, 10));
    }
  });

  return (
    <>
      {activeEmployeeID === 12 && (
        <>
          <motion.div
            className="archive"
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ opacity: { duration: 1 } }}
            onClick={() => {
              if (escalatedTickets && escalatedTickets.length >= 1) {
                setShowList((s) => !s);
              }
            }}
          >
            <motion.span
              animate={showList ? "open" : "closed"}
              initial="closed"
              variants={rotateVariants}
              className="fa-stack has-badge"
            >
              <i
                data-count={escalatedTickets ? escalatedTickets.length : 0}
                className="fas fa-level-up-alt"
              ></i>
            </motion.span>
          </motion.div>
          {showList ? (
            <EscalatedList
              isOpen={showList}
              escalatedTickets={escalatedTickets}
              tickets={tickets}
              getTicket={getTicket}
              deEscalateTicket={deEscalateTicket}
              closeList={() => setShowList(false)}
            />
          ) : null}
        </>
      )}
    </>
  );
};

const EscalatedList = ({
  escalatedTickets,
  tickets,
  getTicket,
  deEscalateTicket,
  isOpen,
  closeList,
}) => {
  return (
    <motion.div
      variants={listVariants}
      initial="closed"
      animate={isOpen ? "open" : "closed"}
      className="archived-list-container"
    >
      <h1 className="close-archived-list" onClick={closeList}>
        X
      </h1>
      <ul className="archived-list">
        {escalatedTickets.map((id) => (
          <EscalatedListItem
            key={id}
            id={id}
            ticket={tickets[id]}
            getTicket={getTicket}
            deEscalateTicket={deEscalateTicket}
          />
        ))}
      </ul>
    </motion.div>
  );
};

const EscalatedListItem = ({ id, ticket, getTicket, deEscalateTicket }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (ticket && ticket.escalated) {
      setLoading(false);
    } else {
      getTicket(id);
    }
  }, [ticket]);

  const handleDeEscalate = () => {
    setLoading(true);
    deEscalateTicket(id);
  };

  let firstMessage;

  if (ticket && ticket.details) {
    const [messages, recentMessages] = formatMessages(
      ticket.details.inboundTexts,
      ticket.details.inboundEmails
    );
    firstMessage = messages[0];
  }

  return (
    <li onClick={handleDeEscalate}>
      <Loader loaded={!loading} options={loaderOptions}>
        {ticket ? (
          <Fragment>
            <p className="archive-time">
              {moment.utc(ticket.escalated).local().format("dddd hh:mm A")}
            </p>
            {ticket.details.last_escalated_by && (
              <p>{ticket.details.last_escalated_by}</p>
            )}
            {ticket.details ? (
              <p className="archive-name">
                {ticket.details["first_name"]} {ticket.details["last_name"]}
              </p>
            ) : (
              <p className="archive-name">
                Name not found
              </p>
            )}
            <p className="archive-message">
              {firstMessage ? firstMessage.object.body : null}
            </p>
          </Fragment>
        ) : null}
      </Loader>
    </li>
  );
};

const loaderOptions = {
  lines: 13,
  length: 4,
  width: 2,
  radius: 6,
  scale: 1.0,
  corners: 1,
  color: "#41b29e",
  opacity: 0.25,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 0,
  shadow: false,
  hwaccel: false,
  position: "relative",
  // left: "10%"
};

const rotateVariants = {
  open: {
    scale: 1.2,
    transition: {
      duration: 0.1,
    },
  },
  closed: {
    scale: 1,
    transition: {
      duration: 0.1,
    },
  },
};

const listVariants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};

const mapStateToProps = (state) => ({
  escalatedTickets: state.entities.tickets.escalatedTickets || [],
  tickets: state.entities.tickets.tickets,
  currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  getTicket: (id) => dispatch(getTicket(id, true)),
  deEscalateTicket: (id) => dispatch(deEscalateTicket(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Escalated);
