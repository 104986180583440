import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";

const Errors = ({ errors }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [displayErrors, setDisplayErrors] = useState([]);

  useEffect(() => {
    // debugger
    if (!isOpen && errors && !isEqual(errors, displayErrors)) {
      setIsOpen(true);
      setDisplayErrors(errors);
    }
  }, [errors]);

  if (!isOpen || !displayErrors || displayErrors.length === 0) {
    return null;
  }
  return (
    <motion.div
      variants={errorDisplayVariants}
      initial="open"
      animate={isOpen ? "open" : "closed"}
      className="errors-container"
    >
      <li
        style={{ fontWeight: "bold", marginRight: "10px", textAlign: "left" }}
      >
        Error:
      </li>
      {displayErrors.map((error, i) => (
        <li key={i}>{error}</li>
      ))}
      <span className="close" onClick={() => setIsOpen(false)}>
        X
      </span>
    </motion.div>
  );
};

const errorDisplayVariants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};

export default Errors;
