export const SESSION_COOKIE = Object.freeze({
  API_TOKEN: "apiToken",
  CLEARANCE: "clearance",
  EMAIL: "email",
  EMPLOYEE_ID: "employeeId",
  ONLINE: "online",
  STS_ID: "sts_key_id",
  STS_SECRET: "sts_secret_key",
  STS_TOKEN: "sts_session_token",
});

export const createCookie = (name, value, days) => {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }

  document.cookie = name + "=" + value + expires + "; path=/";
};

export const readCookie = name => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    let val = c.substring(nameEQ.length, c.length)
    if (val === "null") return null;
    if (c.indexOf(nameEQ) === 0) return val;
  }
  return null;
};

export const eraseCookie = name => {
  createCookie(name, "", -1);
};

export const createSessionCookies = (sessionData) => {
  for (const [_, cookieStr] of Object.entries(SESSION_COOKIE)) {
    createCookie(cookieStr, sessionData[cookieStr], 2);
  }
};

export const eraseSessionCookies = () => {
  for (const [_, cookieStr] of Object.entries(SESSION_COOKIE)) {
    eraseCookie(cookieStr);
  }
};
