import React, { useEffect, useRef } from "react";
import { formatJSONDate } from "../helpers/date_helper";
import moment from "moment";

export const formatMessages = (inboundTexts, inboundEmails) => {
  const messages = [];

  // Handle Inbound / OutBound formatting
  for (let i = 0; i < inboundTexts.length; i++) {
    messages.push({
      type: "text",
      object: inboundTexts[i],
      in: inboundTexts[i].inbound === null ? true : inboundTexts[i].inbound,
    });
  }
  for (let i = 0; i < inboundEmails.length; i++) {
    messages.push({
      type: "email",
      object: inboundEmails[i],
      in: inboundEmails[i].inbound === null ? true : inboundEmails[i].inbound,
    });
  }

  messages.sort(
    (a, b) => new Date(b.object.created_at) - new Date(a.object.created_at)
  );

  let subject = "";

  const recentMessages = messages.map((m, i) => {
    const render = [];

    if (subject !== m.object.subject && m.object.subject) {
      subject = m.object.subject;
      render.push(
        <li className="subject" key={"subject" + i}>
          {subject}
        </li>
      );
    }
    render.push(
      <li className={m.in ? "inbound" : "outbound"} key={i}>
        {m.type[0]} -{" "}
        {new Date(m.object.processed || m.object.created_at).toLocaleString(
          "en-US",
          {
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }
        )}
        :{" "}
        {m.object.body.startsWith(
          "Thank you so much for reaching out to Fig Loans’ Customer Service. We hope you and yours are well."
        )
          ? "Inquiry Autoresponse Sent"
          : m.object.body}
      </li>
    );

    return render;
  });

  return [messages, recentMessages];
};

export const copyPaymentInfo = (payments) => {
  const el = document.createElement("textarea");

  el.value = formatPayments(payments);
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const formatPayments = (payments) => {
  let paymentInfo = `Payments \n`;

  payments
    .sort(
      (a, b) =>
        new Date(formatJSONDate(a.due)) - new Date(formatJSONDate(b.due))
    )
    .forEach((payment) => {
      paymentInfo += `ID: ${payment.id}  Amt: $${
        payment.expected_amt
      }   Due: ${moment(formatJSONDate(payment.due)).format("MMMM D, YYYY")} ${
        payment.processing ? "--Processing--" : ""
      }\n`;
    });

  return paymentInfo;
};

export const copyString = (string) => {
  const el = document.createElement("textarea");
  el.value = string;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
