export const formatRawPhone = (number) => {
  if (number === null) return "";
  if (typeof number !== "string") number = number.toString();
  return (
    "(" +
    number.slice(0, 3) +
    ") " +
    number.slice(3, 6) +
    "-" +
    number.slice(6, 10)
  );
};
