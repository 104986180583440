import { merge } from "lodash";
import { RECEIVE_EDIT_ERRORS } from "../actions/error_actions";
import { RECEIVE_USER } from "../actions/user_actions";

const editErrorsReducer = (state = [], action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_EDIT_ERRORS:
      if (!action.errors.responseJSON) {
        return merge({}, state, {
          0: "Something is wrong with the connection to the server"
        });
      }
      return merge({}, state, action.errors.responseJSON.errors);
    case RECEIVE_USER:
      return {};
    default:
      return state;
  }
};

export default editErrorsReducer;
