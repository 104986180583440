import { motion } from "framer-motion";
import React from "react";
import { connect } from "react-redux";
import { escalateTicket } from "../../../actions/ticket_actions";

const Escalate = ({ id, submit, escalateTicket }) => {
  const handleEscalate = () => {
    submit();
    escalateTicket(id);
  };
  return (
    <div className="escalate">
      <motion.button
        className="ui-button bright-ui"
        initial={{ scale: 1 }}
        whileTap={{ scale: 0.9 }}
        onClick={handleEscalate}
      >
        Escalate
      </motion.button>
      Ticket # {id}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  escalateTicket: (id) => dispatch(escalateTicket(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Escalate);
