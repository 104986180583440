import React, { useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {requestNewPaystubForLoan} from "../../features/incomeVerification/incomeVerificationSlice";
import Tip from "../Tip";
import DebouncedButton from "../DebouncedButton";
import { selectReRequestReasons } from "../../features/incomeVerification/incomeVerificationSlice";
import ReactHtmlParser from "react-html-parser"

const RequestNewPaystubForm = ({loanId, serverErrorMessages}) => {

  const dispatch = useDispatch();
  const [theReasons, setTheReasons] = useState([]);
  const reRequestReasons = useSelector(selectReRequestReasons);
  const reRequestReasonsKeys = Object.keys(reRequestReasons)

  const submitRequestNewPaystubForm = async () => {
    const formData = {
      loan_id: loanId,
      request_new_stub_reason: theReasons
    };
    await dispatch(requestNewPaystubForLoan(formData));
  };

  const handleReasonChange = (reason) => {
    let currReasons = theReasons;
    let updatedReasons = currReasons.includes(reason) ? currReasons.filter(x => x !== reason) : currReasons.concat(reason);
    setTheReasons(updatedReasons);
  }

  const incomeToolTipTextFormatter = (reason) => {
    return (
      <span className="tiptext income-tiptext">
        <div style={{ width: "175px" }}>{reason}</div>
      </span>
    );
  };

  const humanifyKey = (key) => {
    let cleanedKey = key.replace(/_/g, ' ')
    return cleanedKey.charAt(0).toUpperCase() + cleanedKey.slice(1)
  };

  return (
    <form className="income-verification-form">
      <h2>Re-request Income Docs</h2>
      {serverErrorMessages &&
        <div className="server-errors">
          <p>{serverErrorMessages}</p>
        </div>
      }
      <div className="form-section">
        <div className="form-label">
          <label htmlFor="hoobastank-the-reason">Why are you re-requesting docs? (select all that apply)</label>
        </div>
        <div className="form-input">
        {reRequestReasonsKeys.map((element, i) => (
          <div key={i}>
            <Tip text={incomeToolTipTextFormatter(ReactHtmlParser(reRequestReasons[element]))}>
              <label>
                <input 
                  type="checkbox" 
                  value={element} 
                  onClick={(e) => handleReasonChange(e.target.value)}
                  />
                  {humanifyKey(element)}
                </label>
            </Tip>
          </div>
        ))}
        </div>
        <div className="clear"></div>
        <div className="form-submit">
          <DebouncedButton className="ui-button" onClick={submitRequestNewPaystubForm}>
            Submit
          </DebouncedButton>
        </div>
      </div>
    </form>
  );
};

export default RequestNewPaystubForm;