import React from "react";
import "../../styles/css/blog.css";

const Preview = ({ html, open, closeAction }) => {
  return (
    <div id="blog">
      <div className="blog">
        <div className="container">
          <div className="panel-white">
            <div className="blog-about">
              <div className="blog-about-text">
                <h2 className="blog-name">A Fig a Day</h2>
                <p> A socially responsible lender </p>
              </div>
            </div>
          </div>
          <div className="panel-grey">
            <div id="post_container">{html}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
