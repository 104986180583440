import { domain } from "../helpers/route_helper";

export const updateMacro = (title, body, tags) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/update_macro`,
    data: {title, body, tags}
  });
};

export const fetchMacros = () => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/fetch_macros`
  });
};
