import React, { Component } from "react";
import { domain } from "../helpers/route_helper";
import { Link } from "react-router-dom";
import Navbar from "./Navbar_container";

class SurveyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: []
    }
  }

  getSurveyList = () => {
    return window.$
      .ajax({
        method: "GET",
        url: `${domain}/internal/api/surveys`
      })
      .then(res => {
        this.setState({
          surveys: res.surveys
        });
      });
  };

  componentDidMount() {
    this.getSurveyList();
  }


  render() {
    if (this.state.surveys.length === 0) {
      return <div />;
    }

    const display = this.state.surveys.map((s,i) => (
      <div className="survey-question">
        <Link to={`/surveys/${s.path}`}><h2 className="survey-prompt">{s.path} </h2></Link>({s.count})
      </div>
    ))

    return (
      <main>
        <Navbar />
        <div className="survey-list">
          {display}
        </div>
      </main>
    )
  }
}

export default SurveyList;
