import React from "react";
import { domain } from "../helpers/route_helper";

export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = { search: "", parameter: "User", suggestions: {} };
  }

  componentDidMount() {
    if (
      this.props.location.search &&
      this.props.location.pathname === "/user"
    ) {
      const search = this.props.location.search;
      const params = new URLSearchParams(search);

      for (let pair of params.entries()) {
        this.props.search(pair[0], pair[1]);
      }
    }
  }

  handleChange = e => {
    this.setState({ search: e.target.value, suggestions: {} });
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      let search = this.state.search;
      search = search.trim();
      search = search.toLowerCase();
      let parameter = this.state.parameter;

      if (parameter === "User") {
        if (search.includes("@")) {
          parameter = "Email";
        } else if (search.match(/[0-9]/)) {
          if (search.length >= 10) {
            search = search.split(/[\s()-]+/).join("");
            parameter = "Phone";
          } else {
            parameter = "ID";
          }
        } else if (search.split(" ").length === 2) {
          parameter = "Name";
        }
      }

      if (parameter === "Name") {
        window.$.ajax({
          method: "GET",
          url: `${domain}/internal/api/users/search`,
          data: { parameter, search }
        }).then(suggestions => {
          this.setState({ suggestions });
        });
      } else {
        this.search(parameter, search);
      }
    }
  };

  search = (parameter, search) => {
    this.props.load();
    this.props.search(parameter, search);
    this.props.history.push(`/user?${parameter}=${search}`);
  };

  handleCheck = parameter => {
    return () => {
      this.setState({ parameter });
    };
  };

  render() {
    let suggestions = null;

    const suggestionIds = Object.keys(this.state.suggestions);

    if (suggestionIds.length > 0) {
      suggestions = (
        <div className="search-suggestions">
          <ul>
            {suggestionIds.map(id => {
              let sugg = this.state.suggestions[id];
              return (
                <li onClick={() => this.search("ID", id)}>
                  {sugg.email} - {sugg.ssn}{" "}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }

    return (
      <div className="search">
        <input
          className="search-input"
          type="text"
          placeholder={`${
            this.state.parameter === "User"
              ? "ID,Email,Phone,Name"
              : this.state.parameter
          }...`}
          value={this.state.search}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />

        {suggestions}

        <div className="search-radios">
          <label>
            <input
              className="search-radio"
              type="radio"
              checked={this.state.parameter === "User"}
              onChange={this.handleCheck("User")}
            />
            User
          </label>
          <label>
            <input
              className="search-radio"
              type="radio"
              checked={this.state.parameter === "Loan"}
              onChange={this.handleCheck("Loan")}
            />
            Loan
          </label>
          <label>
            <input
              className="search-radio"
              type="radio"
              checked={this.state.parameter === "Payment"}
              onChange={this.handleCheck("Payment")}
            />
            Payment
          </label>
        </div>
      </div>
    );
  }
}
