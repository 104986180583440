import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { getTicket, unarchiveTicket } from "../../actions/ticket_actions";
import Loader from "react-loader";
import { formatMessages } from "../../helpers/ticket_helper";
import moment from "moment";
import { motion } from "framer-motion";

const Archive = ({ archivedTickets, tickets, getTicket, unarchiveTicket }) => {
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    if (!archivedTickets || archivedTickets.length < 1) {
      setShowList(false);
    }
  });

  return (
    <>
      <motion.div
        className="archive"
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1 }}
        whileTap={{ scale: 0.9 }}
        transition={{ opacity: { duration: 1 } }}
        onClick={() => {
          if (archivedTickets && archivedTickets.length >= 1) {
            setShowList((s) => !s);
          }
        }}
      >
        <motion.span
          animate={showList ? "open" : "closed"}
          initial="closed"
          variants={rotateVariants}
          className="fa-stack has-badge"
        >
          <i data-count={archivedTickets.length} className="fas fa-box-archive"></i>
        </motion.span>
      </motion.div>
      {showList ? (
        <ArchivedList
          isOpen={showList}
          archivedTickets={archivedTickets}
          tickets={tickets}
          getTicket={getTicket}
          unarchiveTicket={unarchiveTicket}
          closeList={() => setShowList(false)}
        />
      ) : null}
    </>
  );
};

const ArchivedList = ({
  archivedTickets,
  tickets,
  getTicket,
  unarchiveTicket,
  isOpen,
  closeList,
}) => {
  return (
    <motion.div
      variants={listVariants}
      initial="closed"
      animate={isOpen ? "open" : "closed"}
      className="archived-list-container"
    >
      <h1 className="close-archived-list" onClick={closeList}>
        X
      </h1>
      <ul className="archived-list">
        {archivedTickets.map((id) => (
          <ArchivedListItem
            key={id}
            id={id}
            ticket={tickets[id]}
            getTicket={getTicket}
            unarchiveTicket={unarchiveTicket}
          />
        ))}
      </ul>
    </motion.div>
  );
};

const ArchivedListItem = ({ id, ticket, getTicket, unarchiveTicket }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (ticket && ticket.archived) {
      setLoading(false);
    } else {
      getTicket(id);
    }
  }, [ticket]);

  const handleUnarchive = () => {
    setLoading(true);
    unarchiveTicket(id);
  };

  let firstMessage;

  if (ticket && ticket.details) {
    const [messages, recentMessages] = formatMessages(
      ticket.details.inboundTexts,
      ticket.details.inboundEmails
    );
    firstMessage = messages[0];
  }

  return (
    <li onClick={handleUnarchive}>
      <Loader loaded={!loading} options={loaderOptions}>
        {ticket ? (
          <Fragment>
            <p className="archive-time">
              {moment.utc(ticket.archived).local().format("dddd hh:mm A")}
            </p>
            {ticket.details ? (
              <p className="archive-name">
                {ticket.details["first_name"]} {ticket.details["last_name"]}
              </p>
            ) : (
              <p className="archive-name">
                Name not found
              </p>
            )}
            <p className="archive-message">
              {firstMessage ? firstMessage.object.body : null}
            </p>
          </Fragment>
        ) : null}
      </Loader>
    </li>
  );
};

const loaderOptions = {
  lines: 13,
  length: 4,
  width: 2,
  radius: 6,
  scale: 1.0,
  corners: 1,
  color: "#41b29e",
  opacity: 0.25,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 0,
  shadow: false,
  hwaccel: false,
  position: "relative",
  // left: "10%"
};

const rotateVariants = {
  open: {
    scale: 1.2,
    transition: {
      duration: 0.1,
    },
  },
  closed: {
    scale: 1,
    transition: {
      duration: 0.1,
    },
  },
};

const listVariants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};

const mapStateToProps = (state) => ({
  archivedTickets: state.entities.tickets.archivedTickets,
  tickets: state.entities.tickets.tickets,
});

const mapDispatchToProps = (dispatch) => ({
  getTicket: (id) => dispatch(getTicket(id, true)),
  unarchiveTicket: (id) => dispatch(unarchiveTicket(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Archive);
