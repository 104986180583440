import React from "react";
import { useDispatch } from "react-redux";
import CredentialForm from "./CredentialForm";
import { signIn } from "../features/sessions/sessionSlice";
import "../styles/css/login.css";

export default function Login() {
  const dispatch = useDispatch();

  const handleSubmit = (e, form) => {
    e.preventDefault();
    dispatch(signIn(form));
  };

  return (
    <div className="login-page">
      <div className="login-page-header"> Fig Tech Employee Portal </div>
      <div className="login-form">
        <div className="login-form-content">
          <div className="title">Sign in</div>
          <CredentialForm mfaFlow={false} submitAction={handleSubmit} />
        </div>
      </div>
    </div>
  );
}
