import { merge } from "lodash";
import { RECEIVE_SESSION_ERRORS } from "../actions/error_actions";

const sessionErrorsReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_SESSION_ERRORS:
      if (!action.errors.responseJSON) {
        return merge({}, state, {
          0: "Something is wrong with the connection to the server"
        });
      }
      return merge({}, state, action.errors.responseJSON.errors);
    default:
      return state;
  }
};

export default sessionErrorsReducer;
