import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  asyncFetchPostRequest,
  asyncFetchDeleteRequest,
  asyncFetchPatchRequest,
} from "../../util/async_api_util";
import {
  SESSION_COOKIE,
  eraseSessionCookies,
  createSessionCookies,
  readCookie,
  createCookie
} from "../../helpers/cookie_helper";

const URLS = Object.freeze({
  NEW_SESSION: "internal/api/employees/sign_in",
  SIGN_OUT: "internal/api/employees/sign_out",
  TOGGLE_ONLINE: "/internal/api/toggle_online",
  ENABLE_MFA: "internal/api/enable_2fa",
});

export const signIn = createAsyncThunk(
  "signIn",
  async ({ email, password, otp }, { rejectWithValue }) => {
    const formData = {
      employee: { email, password, otp },
    };
    const response = await asyncFetchPostRequest(URLS.NEW_SESSION, formData);
    const respCode = response.status;
    const data = await response.json();
    if (respCode !== 200) {
      return rejectWithValue(data, { respCode: respCode });
    }
    return data;
  }
);

export const toggleOnline = createAsyncThunk("toggleOnline", async (online) => {
  const reqData = {
    online: online.toString(),
  };
  return asyncFetchPatchRequest(URLS.TOGGLE_ONLINE, reqData);
});

export const signOut = createAsyncThunk("signOut", async () => {
  return asyncFetchDeleteRequest(URLS.SIGN_OUT);
});

export const enableMfa = createAsyncThunk(
  "enableMfa",
  async ({ email, password }, { rejectWithValue }) => {
    const formData = {
      email, password
    };
    const response = await asyncFetchPostRequest(URLS.ENABLE_MFA, formData);
    const respCode = response.status;
    const data = await response.json();
    if (respCode !== 200) {
      return rejectWithValue(data, { respCode: respCode });
    }
    return data;
  }
);

const noSessionState = {
  currentUser: {
    apiToken: null,
    clearance: null,
    email: null,
    employeeId: null,
    online: null,
    sts_key_id: null,
    sts_secret_key: null,
    sts_session_token: null,
  },
  mfa: {
    otpUri: null
  },
  errors: null,
  pending: false,
};

export const currentSessionState = {
  currentUser: {
    apiToken: readCookie(SESSION_COOKIE.API_TOKEN),
    clearance: readCookie(SESSION_COOKIE.CLEARANCE),
    email: readCookie(SESSION_COOKIE.EMAIL),
    employeeId: readCookie(SESSION_COOKIE.EMPLOYEE_ID),
    online: readCookie(SESSION_COOKIE.ONLINE),
    sts_key_id: readCookie(SESSION_COOKIE.STS_ID),
    sts_secret_key: readCookie(SESSION_COOKIE.STS_SECRET),
    sts_session_token: readCookie(SESSION_COOKIE.STS_TOKEN),
  },
  mfa: {
    otpUri: null
  },
  errors: null,
  pending: false,
};

export const selectSessionErrors = (state) => state.session.errors;
export const selectPendingStatus = (state) => state.session.pending;
export const selectOtpUri = (state) => state.session.mfa.otpUri;
export const onlineStatus = (state) => state.session.currentUser.online;

export const sessionSlice = createSlice({
  name: "session",
  initialState: noSessionState,
  reducers: {
    resetErrors(state) {
      state.errors = null;
    },     
    removeOtpUri(state) {
      state.mfa.otpUri = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(signIn.pending, (state) => {
        state.errors = null;
        state.pending = true;
      })
      .addCase(signIn.fulfilled, (state, { payload }) => {
        window.$.ajaxSetup({ data: { token: payload.apiToken } });
        createSessionCookies(payload);
        state.currentUser = payload;
        state.pending = false;
      })
      .addCase(signIn.rejected, (state, { payload }) => {
        state.errors = payload.errors;
        state.pending = false;
      })
      .addCase(signOut.pending, (state) => {
        state.errors = null;
        state.pending = true;
      })
      .addCase(signOut.fulfilled, (state) => {
        eraseSessionCookies();
        state.currentUser = noSessionState.currentUser;
        state.pending = false;
      })
      .addCase(signOut.rejected, (state) => {
        state.errors = [
          "You have been signed out, but something went wrong. Please reach out to engineering.",
        ];
        state.pending = false;
      })
      .addCase(toggleOnline.pending, (state) => {
        state.pending = true;
      })
      .addCase(toggleOnline.fulfilled, (state, { payload }) => {
        // if there is no user in state when fulfilled, set online to null
        if (!state.currentUser.apiToken) {
          state.currentUser.online = null;
        } else {
          let online = payload.online
          state.currentUser.online = online;
          createCookie(SESSION_COOKIE.ONLINE, online)
        }
        state.pending = false;
      })
      .addCase(toggleOnline.rejected, (state) => {
        // if there is a user present when rejected, display an error message.
        // otherwise, the user is currently singed out and on the log in screen so either expects to be logged out regularly
        // OR should receive error messages related to sign in/out (so we don't mess with errors here)
        if (state.currentUser.apiToken) {
          state.errors = [
            "Something went wrong, please try again. If the issue persists, please contact engineering.",
          ];
          // if there is no user in state, they should not be online in any case despite the rejection
        } else {
          state.currentUser.online = null;
        }
        state.pending = false;
      }).addCase(enableMfa.pending, (state) => {
          state.pending = true;
          state.mfa.otpUri = null;
          state.errors = null;
      }).addCase(enableMfa.fulfilled, (state, { payload }) => {
          state.pending = false;
          state.mfa.otpUri = payload.otp_uri;
          state.errors = null;
      }).addCase(enableMfa.rejected, (state, { payload }) => {
          state.pending = false;
          state.mfa.otpUri = null;
          state.errors = payload.errors;
      });
  },
});

export const { resetErrors, removeOtpUri } = sessionSlice.actions

export default sessionSlice.reducer;
