import React from "react";

const Header = ({ needReviewTotal }) => {
  return (
    <div className="controls" style={{ color: 'white'}} >
      <b>Total count: {needReviewTotal}</b>
    </div>
  );
};

export default Header;
