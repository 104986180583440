import { motion } from "framer-motion";
import React from "react";
import { connect } from "react-redux";
import { toggleOnline } from "../../features/sessions/sessionSlice";

const Online = ({ currentUser, toggleOnline }) => {
  const online = currentUser && !!currentUser.online;

  const handleClick = () => {
    toggleOnline(!online);
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}
      className="online-container"
    >
      <button
        onClick={handleClick}
        className={online ? "ui-button small" : "ui-button small offline"}
      >
        {online ? "ONLINE" : "OFFLINE"}
      </button>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  toggleOnline: (online) => dispatch(toggleOnline(online)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Online);
