import { domain } from "../helpers/route_helper";

export const changePayment = payment => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/loans/change_payment`,
    data: { payment }
  });
};

export const openStatementLink = loan => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/loans/open_link`,
    data: { loan }
  });
};

export const splitPayment = (
  loan_id,
  payment_id,
  size,
  date,
  warning_accepted
) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/loans/split_payment`,
    data: { loan_id, payment_id, size, date, warning_accepted }
  });
};

export const customPayments = (loan, date, amount, frequency) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/loans/custom_payments`,
    data: { loan, date, amount, frequency }
  });
};

export const editInfo = (parameter, text, loan_id, force) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/loans/edit_info`,
    data: { parameter, text, loan_id, force }
  });
};

export const manualDenialEmail = loan_id => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/loans/manual_denial_email`,
    data: { loan_id }
  });
};

export const manualApproval = (loan_id, amount) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/loans/manual_approval`,
    data: { loan_id, amount }
  });
};

export const calcEarlyRepay = (loan_id, date) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/loans/early_repay_calculate`,
    data: { loan_id, date }
  });
};

export const createEarlyRepay = (loan_id, date) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/loans/create_early_repay`,
    data: { loan_id, date }
  });
};

export const cancel = loan_id => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/loans/cancel`,
    data: { loan_id }
  });
};

export const confirmCB = loan_id => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/loans/confirm_cb`,
    data: { loan_id }
  });
};

export const toggleACH = loan_id => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/loans/toggle_ach`,
    data: { loan_id }
  });
};

export const clearAlert = loan_id => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/loans/clear_alert`,
    data: { loan_id }
  });
};

export const reopenLoan = loan_id => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/loans/reopen_loan`,
    data: { loan_id }
  });
};

export const submitImmediateAch = (loan_id, payment_id) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/loans/submit_immediate_ach`,
    data: { loan_id, payment_id }
  });
};

export const submitDecision = (loan_id, decision) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/loans/submit_decision`,
    data: { loan_id, decision }
  });
};

export const creditLoan = (loan_id, credit_amount) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/loans/credit_loan`,
    data: { loan_id, credit_amount }
  });
};

export const createLargerPayment = (loan_id, payment_id, amount) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/users/create_larger_payment`,
    data: { loan_id, payment_id, amount }
  });
};

export const appeal = loan_id => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/loans/appeal`,
    data: { loan_id }
  });
}
