import React, { Component } from "react";
import "../styles/css/ui.css";

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { clicked: true };
  }

  handleClose = clickSite => () => {
    if (this.props.closeAction) {
      this.props.closeAction();
      this.setState({ clicked: false });
    } else {
      this.setState({ clicked: false });
    }
    if (this.props.modalFeedback) {
      this.props.modalFeedback(clickSite);
    }
  };

  render() {
    return (
      <div>
        {this.state.clicked || this.props.open ? (
          <div>
            <div
              onClick={this.handleClose("background")}
              className={
                "modal-background" +
                (this.props.modal_class == "second_level_fixed"
                  ? " second-modal-background"
                  : "")
              }
            />
            <div className={"modal-box " + this.props.modal_class}>
              <div className="modal-content">
                <button onClick={this.handleClose("x")} className="modal-close">
                  X
                </button>
                {this.props.children}
                <div className="modal-text">{this.props.text}</div>
                {this.props.modalDisplayButtons ? (
                  <div className="modal-buttons">
                    <button onClick={this.handleClose("no")}>no</button>
                    <button onClick={this.handleClose("yes")}>yes</button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}
