import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import { resendApprovalText } from "../../../util/tasks_api_util";

const Analysis = ({ analysis }) => {
  const [seeMore, setSeeMore] = useState(true);

  const resendApproval = () => {
    resendApprovalText(analysis.applied_loan[0]).then(
      success => {
        alert("Text Sent");
      },
      error => {
        alert("An error occured.");
      }
    );
  };

  return (
    <div className="ticket-analysis">
      <motion.h2
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.5 }}
        className="section-title"
      >
        Analysis
      </motion.h2>
      {analysis?.active_loan?.length >= 1 && (
        <>
          <p className="analysis-header">Active Loan:</p>
        </>
      )}
      {analysis.active_loan.map((x, i) => (
        <li key={i}>{x}</li>
      ))}
      {seeMore && (
        <>
          <p className="analysis-header">Recent Application:</p> <br />
          {analysis.applied_loan.includes("Recently approved") ? (
            <button className="ui-button" onClick={resendApproval}>
              Resend Approval Text
            </button>
          ) : null}
          {analysis.applied_loan.map((x, i) => (
            <li key={i}>{x}</li>
          ))}
        </>
      )}
      {analysis?.applied_loan?.length >= 1 && (
        <p
          onClick={() => {
            setSeeMore(s => !s);
          }}
          className="see-more"
        >
          {!seeMore ? "Show More" : "Show Less"}
        </p>
      )}
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Analysis);
