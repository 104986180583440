import React, { Component } from "react";
import AWS from "aws-sdk";
import Modal from "./../Modal";
import { connect } from "react-redux";
import { domain } from "../../helpers/route_helper";
import { formatMessages } from "../../helpers/ticket_helper";
import ReactHtmlParser from "react-html-parser";
import Loader from "react-loader";

class TicketMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalText: "",
      modalDisplayButtons: false,
      modalFeedback: "",
      modalComponent: null,
      full_messages: null,
      messageListComponent: null
    };
    this.s3 = new AWS.S3({
      accessKeyId: this.props.currentUser.sts_key_id,
      secretAccessKey: this.props.currentUser.sts_secret_key,
      sessionToken: this.props.currentUser.sts_session_token,
      region: "us-west-2",
      signatureVersion: "v4"
    });
  }

  componentDidMount() {
    this.renderMessages();
  }

  componentDidUpdate(prevProps) {
    if (this.props.task.id !== prevProps.task.id) {
      this.renderMessages();
    }
  }

  renderMessages() {
    const [messages, recentMessages] = formatMessages(
      this.props.task.inboundTexts,
      this.props.task.inboundEmails
    );

    if (this.props.task.all_emails) {
      this.pullTransactionMessages(messages);
    }
  }

  openTransactionMessage = key => {
    const params = {
      Bucket: "figloans-pro",
      Key: key
    };

    this.s3.getSignedUrl("getObject", params, (err, url) => {
      fetch(url)
        .then(r => r.text())
        .then(text => {
          const json = JSON.parse(text);
          this.viewRawMessage(
            json.html_summary,
            json.saved_action ? JSON.parse(json.saved_action) : null,
            false
          );
        })
        .catch(err => {
          this.viewRawMessage(
            "<p>There was an error rendering the requested email. Please raise this issue in Slack if log in / log out does not fix this problem</p>",
            null,
            false
          );
        });
    });
  };

  pullTransactionMessages = messages => {
    if (false && this.state.full_messages) {
      this.expandMessages(this.state.full_messages);
    } else {
      const promises = this.props.task.all_emails.map((m, i) => {
        const params = {
          Bucket: "figloans-pro",
          MaxKeys: 50,
          Prefix: "transaction_emails/" + m
        };

        return new Promise(resolve => {
          this.s3.listObjects(params, (err, resp) => {
            if (resp && resp.Contents) {
              resolve(true);
              resp.Contents.map((m, i) => {
                const file_name = m.Key.split("/")[2];

                let year = file_name.substr(0, 4);
                let month = file_name.substr(4, 2);
                let day = file_name.substr(6, 2);
                let hour = file_name.substr(9, 2);
                let minutes = file_name.substr(11, 2);

                let dateString = `${year}-${month}-${day}T${hour}:${minutes}:00Z`;

                const message_format = {
                  in: false,
                  object: {
                    created_at: new Date(dateString),
                    body: file_name.split("_")[2],
                    inbound: false,
                    subject: "notification",
                    key: m.Key
                  },
                  type: "notification"
                };

                messages.push(message_format);
                return;
              });
            } else {
              resolve(false);
            }
          });
        });
      });
      Promise.all(promises)
        .then(values => {
          this.setState({ full_messages: messages });
          this.expandMessages(messages);
          this.scrollDown();
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  expandMessages = messages => {
    messages.sort(
      (a, b) => new Date(a.object.created_at) - new Date(b.object.created_at)
    );

    var subject = "";
    const recent_messages = messages.map((m, i) => {
      const render = [];
      const age =
        (new Date() - new Date(m.object.processed || m.object.created_at)) /
        (1000 * 60 * 60 * 24);
      const auto_response = m.object.body.startsWith(
        "Thank you so much for reaching out to Fig Loans’ Customer Service"
      );

      const class_name = auto_response
        ? "auto_response"
        : m.type == "notification"
        ? "notification message"
        : m.in
        ? age > 5
          ? "inbound_aged message"
          : "inbound message"
        : age > 5
        ? "outbound_aged message"
        : "outbound message";

      if (subject !== m.object.subject && m.object.subject) {
        subject = m.object.subject;
        render.push(
          <div className="message_row" key={"subject" + i}>
            <li className="subject" key={"subject" + i}>
              {subject}
            </li>
          </div>
        );
      }
      render.push(
        <div className="message_row" id={i} key={i}>
          {m.type == "notification" ? (
            <li
              className={class_name}
              key={i}
              onClick={() => this.openTransactionMessage(m.object.key)}
            >
              {m.object.body.startsWith(
                "Thank you so much for reaching out to Fig Loans’ Customer Service"
              )
                ? "Inquiry Autoresponse Sent"
                : m.object.body}
              {m.object.status === "undelivered" ? " ***MESSAGE NOT DELIVERED ***" : null}
            </li>
          ) : (
            <li className={class_name} key={i}>
              {m.object.body.startsWith(
                "Thank you so much for reaching out to Fig Loans’ Customer Service"
              )
                ? "Inquiry Autoresponse Sent"
                : m.object.body}
                {m.object.status === "undelivered" ? " *** MESSAGE NOT DELIVERED ***" : null}
              {m.object.body === "" && m.in ? (
                <i
                  onClick={() => this.viewRawMessage(m, null, true)}
                  className="fas fa-search"
                ></i>
              ) : null}
            </li>
          )}

          <div className="clear"></div>
          <li
            className={m.in ? "left timestamp" : "right timestamp"}
            key={"timestamp_" + i}
          >
            {new Date(m.object.processed || m.object.created_at).toLocaleString(
              "en-US",
              {
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit"
              }
            )}
            {" - "}
            {m.type[0] == "t" ? "text" : "email"}
          </li>
          <div className="clear"></div>
        </div>
      );

      return render;
    });

    let comp = (
      <div className="expanded-messages">
        {recent_messages}

        <div ref={this.props.forwardedRef}></div>
      </div>
    );

    // this.props.handleShowModal(comp, "modal_max_height");
    this.setState({ messageListComponent: comp });
  };

  scrollDown() {
    let list = document.getElementsByClassName("scroll-communication");
    for (let item of list) {
      let message_count = item.getElementsByClassName("message_row").length;
      if (message_count > 0) {
        let last_message_element = item.getElementsByClassName("message_row")[
          message_count - 1
        ];
        last_message_element.scrollIntoView();
      }
    }
  };

  viewRawMessage = (message, message_action, ping_backend) => {
    if (ping_backend) {
      this.setState({
        showModal: true,
        modalComponent: (
          <div style={{ padding: "20px" }}>
            <Loader />
          </div>
        )
      });
      window.$.ajax({
        method: "GET",
        url: `${domain}/internal/api/users/get_raw_message`,
        data: { message_id: message.object.id }
      }).then(
        payload => {
          this.setState({
            showModal: true,
            modalComponent: ReactHtmlParser(payload.html)
          });
        },
        error => {
          this.setState({
            showModal: true,
            modalComponent: <h1>{error.responseJSON.error}</h1>
          });
        }
      );
    } else {
      this.setState({
        showModal: true,
        modalComponent: (
          <div style={{ display: "block" }}>
            {message_action ? (
              <button
                onClick={() => {
                  window.$.ajax({
                    method: "POST",
                    url: `${domain}/internal/api/users/take_message_action`,
                    data: {
                      message_action: message_action.action,
                      loan_id: message_action.id
                    }
                  }).then(
                    payload => {
                      this.setState({
                        showModal: true,
                        modalComponent: <p>{payload.message}</p>
                      });
                    },
                    error => {
                      this.setState({
                        showModal: true,
                        modalComponent: <p>{error.responseJSON.error}</p>
                      });
                    }
                  );
                }}
              >
                {"Resend: " + message_action.action + " email"}
              </button>
            ) : (
              ""
            )}

            {ReactHtmlParser(message)}
          </div>
        )
      });
    }
  };

  closeModal = () =>
    this.setState({
      showModal: false,
      modalText: "",
      ticketAction: "",
      modalComponent: null,
      accountToLinkMethod: "ID",
      accountToLinkKey: "",
      processing: false,
      accountToLinkDetails: null
    });

  render() {
    return (
      <div
        key={`ticket-messages-${this.props.task.id}`}
        className="ticket-messages"
      >
        {this.state.messageListComponent}
        {this.state.showModal ? (
          <Modal
            modalFeedback={this.modalFeedback}
            modalDisplayButtons={this.state.modalDisplayButtons}
            closeAction={this.closeModal}
            text={this.state.modalText}
            modal_class="second_level_fixed"
          >
            {this.state.modalComponent}
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  errors: state.errors.session,
  currentUser: state.session.currentUser
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketMessages);
