import React, { Component } from "react";

class Identification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotation: 0,
    };
  }

  render() {
    return (
      <div style={{ display: "block" }}>
        Id: {this.props.identification.ident_id}{" "}
        <button
          onClick={() => this.setState({ rotation: this.state.rotation + 90 })}
        >
          Clockwise 90 Degrees
        </button>
        <button
          onClick={() => this.setState({ rotation: this.state.rotation - 90 })}
        >
          CounterClockWise 90 Degrees
        </button>
        <a target="_blank" href={this.props.identification.ident_image_url} download>
          <button>download</button>
        </a>
        <div className="image_container">
          <img
            src={this.props.identification.ident_image_url}
            alt="Logo"
            className="identification_image"
            style={{ transform: "rotate(" + this.state.rotation + "deg)" }}
          />
        </div>
      </div>
    );
  }
}

export default Identification;
