import {
  asyncFetchGetRequest,
  asyncFetchPostRequest,
  asyncFetchPatchRequest
} from "../util/async_api_util";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showFlashMessage } from "../features/flashMessage/flashMessageSlice";
import { closeBankruptcyModal } from "../features/fcra/fcraSlice";

const BASE = "internal/api/fcra_dashboard/";

export const URLS = Object.freeze({
  LOOKUP: BASE + "fcra_lookup",
  FILE_BANKRUPTCY: BASE + "fcra_file_bankruptcy",
  DISCHARGE_BANKRUPTCY: BASE + "fcra_discharge_bankruptcy",
  SEND_103_MAILER: BASE + "fcra_mailer"
});

export const lookup = createAsyncThunk(
  "lookup",
  async ({ loanId, reportDate }, { rejectWithValue }) => {
    const formData = {
      loan_id: loanId,
      report_date: reportDate,
    };
    const resp = await asyncFetchGetRequest(URLS.LOOKUP, formData);
    const errors = await resp.errors;
    if (errors) {
      return rejectWithValue(resp)
    }
    return resp;
  }
);

export const fileBankruptcy = createAsyncThunk(
  "fileBankruptcy",
  async ({ loanId, bankruptcyCaseCode, bankruptcyType, bankruptcyEventDate, }, { rejectWithValue, dispatch }) => {
    const formData = {
      id: loanId,
      case_code: bankruptcyCaseCode,
      b_type: bankruptcyType,
      date_filed: bankruptcyEventDate
    };
    const resp = await asyncFetchPostRequest(URLS.FILE_BANKRUPTCY, formData);
    const respCode = resp.status;
    const data = await resp.json();
    if (respCode !== 200) {
      return rejectWithValue(data, { respCode: respCode });
    }
    dispatch(showFlashMessage({ message: "Successfully filed bankruptcy" }));
    dispatch(closeBankruptcyModal());
    return data;
  }
)

export const dischargeBankruptcy = createAsyncThunk(
  "dischargeBankruptcy",
  async ({ loanId, bankruptcyCaseCode, bankruptcyType, bankruptcyEventDate, }, { rejectWithValue, dispatch }) => {
    const formData = {
      id: loanId,
      case_code: bankruptcyCaseCode,
      b_type: bankruptcyType,
      date_discharged: bankruptcyEventDate
    };
    const resp = await asyncFetchPatchRequest(URLS.DISCHARGE_BANKRUPTCY, formData);
    const errors = await resp.errors;
    if (errors) {
      return rejectWithValue(resp)
    }
    dispatch(showFlashMessage({ message: "Successfully discharged bankruptcy" }));
    dispatch(closeBankruptcyModal());
    return resp;
  }
)

export const sendMailer = createAsyncThunk(
  "sendMailer",
  async ({ loanId, code }, { rejectWithValue, dispatch }) => {
    const formData = { 
      id: loanId,
      code: code
    };
    const resp = await asyncFetchPostRequest(URLS.SEND_103_MAILER, formData);
    const respCode = resp.status;
    const data = await resp.json();
    if (respCode !== 200) {
      return rejectWithValue(data, { respCode: respCode });
    }
    dispatch(showFlashMessage({ message: "Successfully sent mailer" }));
    return data;
  }
);