export const RECEIVE_SESSION_ERRORS = "RECEIVE_SESSION_ERRORS";
export const RECEIVE_EDIT_ERRORS = "RECEIVE_EDIT_ERRORS";

export const receiveSessionErrors = errors => ({
  type: RECEIVE_SESSION_ERRORS,
  errors
});

export const receiveEditErrors = errors => ({
  type: RECEIVE_EDIT_ERRORS,
  errors
});
