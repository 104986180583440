import * as APIUtil from "../util/macro_api_util.js";
export const RECEIVE_MACROS = "RECEIVE_MACROS";
export const RECEIVE_MACRO = "RECEIVE_MACRO";

export const receiveMacros = macros => ({
  type: RECEIVE_MACROS,
  macros
});

export const receiveMacro = macro => ({
  type: RECEIVE_MACRO,
  macro
})

export const updateMacro = (title, body, tags) => dispatch => (
  APIUtil.updateMacro(title, body, tags).then(
    macro => dispatch(receiveMacro(macro))
  )
)

export const fetchMacros = () => dispatch => (
  APIUtil.fetchMacros().then(
    macros => dispatch(receiveMacros(macros))
  )
)
