import { createSlice } from '@reduxjs/toolkit';

const flashMessageSlice = createSlice({
  name: 'flashMessage',
  initialState: {
    message: '',
    duration: 0,
    visible: false,
  },
  reducers: {
    showFlashMessage: (state, action) => {
      state.message = action.payload.message;
      state.duration = action.payload.duration;
      state.visible = true;
    },
    hideFlashMessage: (state) => {
      state.visible = false;
    },
  },
});

export const { showFlashMessage, hideFlashMessage } = flashMessageSlice.actions;
export default flashMessageSlice.reducer;