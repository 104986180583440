import React, { useState, useEffect, useRef } from "react";
import Loader from "react-loader";
import ViewDocuments from "./dashboard/ViewDocuments"
import { updateSSN } from "../util/user_api_util";
import { connect } from "react-redux";

import { createLargerPayment, creditLoan } from "../actions/loan_actions";

function AdvancedServiceFunctions(props) {
  const [selectedLoanId, setSelectedLoanId] = useState(
    props.loans && props.loans.length > 0 ? props.loans[0].id : null
  );
  const [action, setAction] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
  }, [selectedLoanId]);

  useEffect(() => {
    if (action) {
      nextPage(action);
    }
  }, [action]);

  const nextPage = functionName => {
    let text;
    switch (functionName) {
      case "credit_loan":
        text = `Please enter the amount you would like to credit to loan id ${selectedLoanId}`;
        break;
      case "update_ssn":
        text = `Please enter the new ssn for user id ${props.id}`;
        break;
      case "custom_payments":
        text = `Please select a payment and enter a payment amount.`;
        break;
    }
    let comp = (
      <div>
        {text}
        {functionName === "credit_loan" ? (
          <>
            <br />
            <input name="credit_amount" placeholder={"Credit Amount"} />
          </>
        ) : null}
        {functionName === "update_ssn" ? (
          <>
            <br />
            <input name="update_ssn" placeholder={"SSN"} />
          </>
        ) : null}
        {functionName === "custom_payments" ? (
          props.loans.filter(x => x.id == selectedLoanId)[0].paymentsDue ? (
            <>
              <br />
              <select name="custom_payments_pmt_id">
                <option selected disabled value={"n/a"}>
                  Select a payment
                </option>
                {Object.values(
                  props.loans.filter(x => x.id == selectedLoanId)[0].paymentsDue
                ).map(x => (
                  <option value={x.id}>{x.id}</option>
                ))}
              </select>
              <br />

              <input
                name="custom_payments_amount"
                placeholder={"Payment Amount"}
              />
            </>
          ) : null
        ) : null}
        <div className="button-row">
          <button onClick={() => handleSubmit(functionName)}>Confirm</button>
          <button onClick={props.close}>Cancel</button>
        </div>
      </div>
    );

    if (
      functionName === "custom_payments" &&
      !props.loans.filter(x => x.id == selectedLoanId)[0].paymentsDue
    ) {
      setCurrentPage(<h1>Loan {selectedLoanId} has no upcoming payments</h1>);
    } else {
      setCurrentPage(comp);
    }
  };

  const handleSubmit = functionName => {
    const onSuccess = (success = null) => {
      functionName === "credit_loan"
        ? setCurrentPage(
            <div>
              <h1>Success!</h1>
              <h2>
                Remaining Credit (leftover credit, rolls over to user credit): $
                {success.remaining}
              </h2>
              <h2>
                User Credit (applied to user's next loan): $
                {success.user_credit}
              </h2>
            </div>
          )
        : setCurrentPage(<h1>Success!</h1>);
    };
    const onError = error => {
      setCurrentPage(
        <h1 style={{ color: "red" }}>{error.responseJSON.error}</h1>
      );
    };
    setCurrentPage(
      <div>
        <Loader />
      </div>
    );
    if (functionName === "credit_loan") {
      let creditAmount = document.getElementsByName("credit_amount")[0].value;
      if (!creditAmount) {
        setCurrentPage(
          <h1 style={{ color: "red" }}>
            Please enter a value for credit amount.
          </h1>
        );
      } else {
        props.creditLoan(selectedLoanId, creditAmount, onSuccess, onError);
      }
    }
    if (functionName === "update_ssn") {
      let newSSN = document.getElementsByName("update_ssn")[0].value;
      if (!newSSN) {
        setCurrentPage(
          <h1 style={{ color: "red" }}>Please enter a value for SSN.</h1>
        );
      } else {
        updateSSN(props.id, newSSN).then(
          success => {
            setCurrentPage(<h1>Success!</h1>);
          },
          error => {
            setCurrentPage(<h1>{error.responseJSON.error}</h1>);
          }
        );
      }
    }
    if (functionName === "custom_payments") {
      let paymentId = document.getElementsByName("custom_payments_pmt_id")[0]
        .value;
      let amount = document.getElementsByName("custom_payments_amount")[0]
        .value;

      if (paymentId === "n/a") {
        setCurrentPage(
          <h1 style={{ color: "red" }}>
            Please select a value for payment id.
          </h1>
        );
      } else if (!amount || amount < 25) {
        setCurrentPage(
          <h1 style={{ color: "red" }}>
            Please enter an amount larger than $25
          </h1>
        );
      } else {
        props.createLargerPayment(
          selectedLoanId,
          paymentId,
          amount,
          onSuccess,
          onError
        );
      }
    }
  };

  return <div className="advanced-service-functions">
    { !!currentPage ? currentPage : (
      <>
        <h2>Customer Service Advanced Functions</h2>
        {props.loans ? (
          <h3>
            Loan ID{" "}
            <select
              id="advanced-modal-id-select"
              onChange={e => {
                setSelectedLoanId(e.target.value);
              }}
            >
              {props.loans.map((x, i) =>
                i == 0 ? (
                  <option value={x.id}>{x.id}</option>
                ) : (
                  <option value={x.id}>{x.id}</option>
                )
              )}
            </select>
          </h3>
        ) : null}
        <div className="button-row">
          <button onClick={() => setAction("update_ssn")}>Update SSN</button>
          {props.loans ? (
            <>
              <ViewDocuments
                user_id={props.id}
                loan_id={selectedLoanId}
                handleShowModal={comp => setCurrentPage(comp)}
                handleProcessing={() => setCurrentPage(<div><Loader /></div>)} />
              <button onClick={() => setAction("credit_loan")}>
                Credit Loan
              </button>
              <button onClick={() => setAction("custom_payments")}>
                Custom Payments
              </button>
            </>
          ) : null}
        </div>
      </>
    )}
  </div>;
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  createLargerPayment,
  creditLoan
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedServiceFunctions);
