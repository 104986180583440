import React, { Component } from "react";
import Navbar from "./Navbar_container";
import { domain } from "../helpers/route_helper";
import TicketVisualizer from './TicketVisualizer'

export default class Visualize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      loans1: "",
      date1: "",
      name1: "1",
      color1: "",
      loading1: false,
      normalize1: false,
      predict1: false,
      combine1: false,
      freq: null,
      num: 1,
      inquiryData: {},
      chartType: 'vintage'
    };
  }

  componentDidMount() {
    const {chart} = this.props.match.params
    this.setState({chartType: chart})
  }

  componentDidUpdate(prevProps) {
    const {chart} = this.props.match.params
    const prevChart = prevProps.match.params.chart
    if (chart !== prevChart) {
      this.setState({chartType: chart})
    }
  }

  render() {
    let chartComp
    switch (this.state.chartType){
      case 'cs':
        chartComp = (
          <TicketVisualizer />
        )
        break;
      default:
        chartComp = null;
    }

    return (
      <div>
        <Navbar />
          {chartComp}
      </div>
    );
  }
}
