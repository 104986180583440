import { connect } from "react-redux";

import Navbar from "./Navbar";

const mapStateToProps = state => ({
  currentUser: state.session.currentUser
});
const mapDispatchToProps = () => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
