import { connect } from "react-redux";
import {
  changePayment,
  openStatementLink,
  splitPayment,
  customPayments,
  manualDenialEmail,
  manualApproval,
  calcEarlyRepay,
  createEarlyRepay,
  cancel,
  confirmCB,
  toggleACH,
  clearAlert,
  reopenLoan,
  submitDecision,
  appeal
} from "../actions/loan_actions";
import LoanView from "./LoanView";

const mapStateToProps = state => ({
  errors: state.errors.edit,
  currentUser: state.session.currentUser
});

const mapDispatchToProps = dispatch => ({
  changePayment: payment => dispatch(changePayment(payment)),
  customPayments: (loan, date, amount, frequency) =>
    dispatch(customPayments(loan, date, amount, frequency)),
  openStatementLink: loan => dispatch(openStatementLink(loan)),
  splitPayment: (
    loan_id,
    payment_id,
    size,
    date,
    warning_accepted,
    onSuccess,
    onError
  ) =>
    dispatch(
      splitPayment(
        loan_id,
        payment_id,
        size,
        date,
        warning_accepted,
        onSuccess,
        onError
      )
    ),
  manualDenialEmail: loanId => dispatch(manualDenialEmail(loanId)),
  manualApproval: (loanId, amount) => dispatch(manualApproval(loanId, amount)),
  calcEarlyRepay: (loanId, date) => dispatch(calcEarlyRepay(loanId, date)),
  createEarlyRepay: (loanId, date) => dispatch(createEarlyRepay(loanId, date)),
  cancel: loanId => dispatch(cancel(loanId)),
  confirmCB: loanId => dispatch(confirmCB(loanId)),
  toggleACH: loanId => dispatch(toggleACH(loanId)),
  clearAlert: loanId => dispatch(clearAlert(loanId)),
  reopenLoan: loanId => dispatch(reopenLoan(loanId)),
  submitDecision: (loanId, decision, onSuccess, onError) =>
    dispatch(submitDecision(loanId, decision, onSuccess, onError)),
  appeal: loanId => appeal(loanId)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanView);
