import React, { Component } from "react";
import { connect } from "react-redux";
import { addInfo } from "../actions/user_actions";
import { editInfo } from "../actions/loan_actions";
import { editUserInfo } from "../actions/ticket_actions";
import { dashboardEditInfo } from "../actions/task_actions";
import Loader from "react-loader";

class Editable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startingText: props.text,
      text: props.text,
      editing: false,
      force: false,
      loading: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.text !== state.startingText) {
      state.startingText = props.text;
      state.text = props.text;
      state.editing = false;
    }

    return state;
  }

  handleChange = (e) => this.setState({ text: e.target.value });

  handleSubmit = () => {
    let text;
    if (this.props.parameter === "phone") {
      text = this.state.text.split(/[\s()-]+/).join("");
    } else {
      text = this.state.text;
    }

    if (this.props.validation) {
      if (!this.props.validation(text)) {
        alert(`Invalid Entry for field: ${this.props.parameter}`);
        return;
      }
    }

    if (this.props.type === "user" && this.props.ticket) {
      this.setState({ loading: true });
      this.props
        .editUserInfo(this.props.id, this.props.parameter, text)
        .then(() => this.setState({ loading: false }));
    } else if (this.props.type === "user" && this.props.dashboard) {
      this.props.submitDashboardEditInfo(
        this.props.parameter,
        text,
        this.props.id,
        this.state.force
      );
    } else if (this.props.type === "user" && !this.props.dashboard) {
      this.props.submitUserInfo(
        this.props.parameter,
        text,
        this.props.id,
        this.state.force
      );
    } else if (this.props.type === "loan") {
      this.props.submitLoanInfo(
        this.props.parameter,
        text,
        this.props.id,
        this.state.force
      );
    }

    if (this.props.afterSubmit) {
      this.props.afterSubmit(text);
    }

    this.setState({ editing: false, text: this.props.text });
  };

  copyTextToClipboard = (ev) => {
    // this is a hacky workaround to copy the innerhtml of a div
    // to the clipboard
    var tmpTextArea = document.createElement("textarea");
    tmpTextArea.id = "temp_element";
    tmpTextArea.style.height = 0;
    document.body.appendChild(tmpTextArea);
    tmpTextArea.value = ev.target.innerHTML;
    var selector = document.querySelector("#temp_element");
    selector.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.log(`Error trying to copy to clipboard\nError: ${err}`);
    } finally {
      document.body.removeChild(tmpTextArea);
    }
  };

  render() {
    let comp;
    if (this.props.parameter === "comment") {
      comp = (
        <textarea
          value={this.state.text}
          onChange={this.handleChange}
          key={1}
          style={{ verticalAlign: "top" }}
        />
      );
    } else {
      comp = (
        <input
          className="editable-input"
          type="text"
          value={this.state.text}
          onChange={this.handleChange}
          key={1}
        />
      );
    }
    return (
      <div style={{ display: "inline" }}>
        {this.state.editing ? (
          [
            comp,
            this.props.dashboard ? <br key={4} /> : "",
            <button
              className="editable-button"
              onClick={this.handleSubmit}
              key={2}
            >
              Save
            </button>,
            <button
              className="editable-button"
              onClick={() => this.setState({ editing: false })}
              key={3}
            >
              Cancel
            </button>,
            <label>
              <input
                type="checkbox"
                value={this.state.force}
                checked={this.state.force}
                onChange={() => this.setState({ force: !this.state.force })}
              />
              Force
            </label>,
          ]
        ) : (
          <div
            onContextMenu={(ev) => {
              ev.preventDefault();
              this.setState({ editing: true });
            }}
            onClick={(ev) => {
              this.copyTextToClipboard(ev);
            }}
            className={
              this.props.dashboard ? "editable-dashboard" : "editable-user-info"
            }
          >
            <Loader loaded={!this.state.loading} options={loaderOptions}>
              {this.props.text && this.props.text !== ""
                ? this.props.text
                : "..."}
            </Loader>
          </div>
        )}
      </div>
    );
  }
}

const loaderOptions = {
  lines: 13,
  length: 4,
  width: 2,
  radius: 6,
  scale: 1.0,
  corners: 1,
  color: "#41b29e",
  opacity: 0.25,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 0,
  shadow: false,
  hwaccel: false,
  position: "relative",
  left: "10%",
};

const mapDispatchToProps = (dispatch) => ({
  submitUserInfo: (parameter, text, userId, force) =>
    dispatch(addInfo(parameter, text, userId, force)),
  submitLoanInfo: (parameter, text, loanId, force) =>
    dispatch(editInfo(parameter, text, loanId, force)),
  submitDashboardEditInfo: (parameter, text, id, force) =>
    dispatch(dashboardEditInfo(parameter, text, id, force)),
  editUserInfo: (id, parameter, text) =>
    dispatch(editUserInfo(id, parameter, text)),
});

export default connect(null, mapDispatchToProps)(Editable);
