import React from "react";

export const PaymentDetails = ({ paymentDetailsObject }) => (
  <div>
    <h2>
      <b>Payment Details</b>
    </h2>
    <ul className="fcra-info" style={{ padding: 0, margin: 0 }}>
      {Object.entries(paymentDetailsObject).map(([id, payment]) => (
        <li key={id} className="payment-detail">
          <span data-label="Payment ID:">
            {id}
          </span>
          <ul className="fcra-info" style={{ padding: 0, margin: 0, marginLeft: "20px" }}>
            <li className="payment-detail" data-label="Original due date:">
              {payment.original_due_date}
            </li>
            <li className="payment-detail" data-label="Status:">
              {payment.status}
            </li>
            <li className="payment-detail" data-label="Paid amount:">
              ${payment.amount_paid}
            </li>
            <li className="payment-detail" data-label="Scheduled amount:">
              ${payment.payment_amount}
            </li>
          </ul>
        </li>
      ))}
    </ul>
  </div>
);