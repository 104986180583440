import React, { useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ImageRender = ({ url }) => {
  const [rotation, setRotation] = useState(0);

  return (
    <div className="paystub-image">
      {/* Copied code snippet below from https://www.npmjs.com/package/react-zoom-pan-pinch/v/1.1.2#usage and added buttons to perform rotation, taken from Identifications.js */}
      <TransformWrapper 
        initialScale={1}
        initialPositionX={200}
        initialPositionY={100}
        >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className="tools" style={{ textAlign: "center" }}>
              <button
                onClick={() => setRotation(rotation + 90)}
              >
                Clockwise 90 Degrees
              </button>
              <button
                onClick={() => setRotation(rotation - 90)}
              >
                CounterClockWise 90 Degrees
              </button>
              <button onClick={zoomIn}>Zoom In +</button>
              <button onClick={zoomOut}>Zoom Out -</button>
              <button onClick={resetTransform}>Reset Zoom</button>
            </div>
            <div className="income-image-scroll" style={{ display: "flex", justifyContent: "center", height: "624px", overflow: "auto" }}>
              <TransformComponent>
                <img src={url} style={{ transform: `rotate(${rotation}deg)`, width: "100%", border: "2px solid #555" }} alt="document" />
              </TransformComponent>
            </div>
          </React.Fragment>
        )}
      </TransformWrapper>
    </div>
  )
};

export default ImageRender;