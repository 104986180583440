import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {asyncFetchGetRequest, asyncFetchPatchRequest} from "../../util/async_api_util";

const URLS = Object.freeze({
  NEXT_LOAN_NEEDING_REVIEW: 'internal/api/income/review',
  VERIFY_INCOME: 'internal/api/income/verify_income',
  REQUEST_NEW_PAYSTUB: 'internal/api/income/request_new_stub',
  REREQUEST_REASONS: 'internal/api/income/rerequest_reasons'
});

// Async function to fetch next loan for review
export const fetchNextLoan = createAsyncThunk('incomeVerification/fetchNextLoan', async () => {
  return asyncFetchGetRequest(URLS.NEXT_LOAN_NEEDING_REVIEW);
});
// Async function to send verify income form data to server
export const verifyIncomeForLoan = createAsyncThunk('incomeVerification/verifyIncomeForLoan',
  async formData => {
    return asyncFetchPatchRequest(URLS.VERIFY_INCOME, formData);
  }
);
// Async function to send request new paystub form data to server
export const requestNewPaystubForLoan = createAsyncThunk('incomeVerification/requestNewPaystubForLoan',
  async formData => {
    return asyncFetchPatchRequest(URLS.REQUEST_NEW_PAYSTUB, formData);
  }
);
// Async function to fetch dictionary of re-request reasons
export const fetchReRequestReasons = createAsyncThunk('incomeVerification/fetchReRequestReasons', async () => {
  return asyncFetchGetRequest(URLS.REREQUEST_REASONS);
});

/**
 * Helper function to sort the paystubs returned from the server
 *
 * Note: this method will do a primary sort on the `reviewed_at` field, and then a secondary sort on `uploaded_at`.
 * So the top entries will NOT have been reviewed yet, and then will go in order of most recently uploaded
 *
 * @param {Object} paystub_1 - A paystub associated with a loan
 * @param {Object} paystub_2 - Another paystub associated with a loan
 * @param {string} paystub_1.reviewed_at - The datetime a paystub was reviewed
 * @param {string} paystub_1.uploaded_at - The datetime a paystub was uploaded by the customer
 * @param {string} paystub_1.url - A presigned S3 url to display the paystub image
 * @param {string} paystub_1.stub_file_name - The file name of the image that was uploaded by the customer
 * @param {string} paystub_1.stub_file_type - The file type of the image that was uploaded by the customer
 *
 * @returns {number} The sort order after comparison
 * */
export const sortPaystubs = (paystub_1, paystub_2) => {
  if (!paystub_1.reviewed_at && paystub_2.reviewed_at) return -1;
  if (paystub_1.reviewed_at && !paystub_2.reviewed_at) return 1;

  const ps1_date = new Date(paystub_1.uploaded_at);
  const ps2_date = new Date(paystub_2.uploaded_at);

  if (ps1_date > ps2_date) return -1;
  if (ps2_date > ps1_date) return 1;
};

// Selector to access the root store and pull out the minPaystubDate from the server
export const selectMinPaystubDate = state => state.entities.incomeVerification.minPaystubDate;
export const selectLoanId = state => state.entities.incomeVerification.loanId;
export const selectUserName = state => state.entities.incomeVerification.userName;
export const selectErrors = state => state.entities.incomeVerification.errors;
export const selectIncomeForLoanVerified = state => state.entities.incomeVerification.incomeForLoanVerified;
export const selectStatus = state => state.entities.incomeVerification.status;
export const selectNewPaystubRequested = state => state.entities.incomeVerification.newPaystubRequested;
export const selectPaystubs = state => state.entities.incomeVerification.paystubs;
export const selectNeedReviewTotal = state => state.entities.incomeVerification.needReviewTotal;
export const selectReRequestReasons = state => state.entities.incomeVerification.reRequestReasons;

// Reducer slice for income verification
export const incomeVerificationSlice = createSlice({
  name: 'incomeVerification',
  initialState: {
    loanId: null,
    incomeForLoanVerified: false,
    paystubs: null,
    minPaystubDate: null,
    lastTicketId: null,
    errors: null,
    status : null,
    needReviewTotal: null,
    reRequestReasons: null
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchNextLoan.pending, (state, action) => {
      state.status = 'Checking for a loan that needs review...';
    })
    .addCase(fetchNextLoan.fulfilled, (state, action) => {
      let p = action.payload;
      if (p.need_review_total > 0) {
        let d = p.review_detail;
        state.loanId = d.loan_id;
        state.userName = d.user_name;
        state.paystubs = Object.values(d.paystubs).sort(sortPaystubs);
        state.minPaystubDate = d.paystub_must_be_dated_on_or_after;
        state.lastTicketId = d.last_ticket_id;
        state.incomeForLoanVerified = false;
        state.errors = null;
        state.newPaystubRequested = false;
        state.needReviewTotal = p.need_review_total;
      } else {
        state.loanId= null;
        state.status = 'There are no loans that need review';
        state.needReviewTotal = p.need_review_total;
      }
    }).addCase(verifyIncomeForLoan.fulfilled, (state, action) => {
      let p = action.payload;
      if (p.errors) {
        state.errors = p.errors;
      } else {
        // MD: No errors means the income for the loan was verified
        state.incomeForLoanVerified = true;
        state.errors = null;
      }
    }).addCase(verifyIncomeForLoan.rejected, (state, action) => {
      state.errors = 'There was an error on the server. Please contact Engineering.';
    }).addCase(requestNewPaystubForLoan.fulfilled, (state, action) => {
      let p = action.payload;
      if (p.errors) {
        state.errors = p.errors;
      } else {
        // Same deal here, no errors means the request went through
        state.newPaystubRequested = true;
        state.errors = null;
      }
    }).addCase(fetchReRequestReasons.fulfilled, (state,action) => {
      state.reRequestReasons = action.payload.reasons;
    }).addCase(fetchReRequestReasons.rejected, (state) => {
      state.errors = 'There was an error on the server. Please contact Engineering.';
    })
  }
});

export default incomeVerificationSlice.reducer