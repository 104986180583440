import React, { useState } from "react";
import { readCookie } from "../../helpers/cookie_helper";
import { GraphiQL } from 'graphiql';
import { buildClientSchema, getIntrospectionQuery } from 'graphql';

import { signOut } from "../../features/sessions/sessionSlice";
import { connect } from "react-redux";

import { domain } from "../../helpers/route_helper";
import { SESSION_COOKIE } from "../../helpers/cookie_helper";

import 'graphiql/graphiql.css';

const EmbeddedSandbox = ({ logout }) => {
  const [query, setQuery] = useState('');
  const [schema, setSchema] = useState('');


  const graphQLFetcher = (graphQLParams) => {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };

    // Since this is not a proper auth token, we cannot just use the default createGraphQLFetcher
    // with an auth header token... should this be changed in the future? Why is this not actually
    // a bearer token?! Super hacky for now...
    graphQLParams.token = readCookie(SESSION_COOKIE.API_TOKEN)
    return fetch(`${domain}/graphql`, {
      method: 'post',
      headers,
      body: JSON.stringify(graphQLParams),
    }).then(function (response) {
      if(response.status == 401) {
        // Hacky-ish, but if the user is logged out (401) then redirect to the internal login page.
        // Navigating to this page instantly makes an introspection query, so this behaves as a redirect
        // for any traffic coming in.
        //
        // signOut handles pushing the historical route + redirecting to login page.
        logout();
      }
      return response.text();
    }).then(function (responseBody) {
      try {
        return JSON.parse(responseBody);
      } catch (error) {
        return responseBody;
      }
    });
  }

  if (!schema) {
    graphQLFetcher({
      query: getIntrospectionQuery()
    }).then(result => {
      if (result && result.data) {
        setSchema(buildClientSchema(result.data))
      }
    })
  }

  return (
    <div style={{all: 'unset'}}>
      <div style={{height: '100vh'}}>
        <GraphiQL
          fetcher={graphQLFetcher}
          query={query}
          />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(signOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmbeddedSandbox);