import React, { Component } from "react";
import Navbar from "./Navbar_container";
import { updateMacro, fetchMacros } from "../actions/macro_actions";
import { connect } from "react-redux";

class MacroEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      body: "",
      tags: ""
    };
  }

  handleChange = field => e => this.setState({ [field]: e.target.value });

  componentDidMount() {
    if (Object.keys(this.props.macros).length === 0) {
      this.props.fetchMacros();
    }
  }
  submitMacro = () => {
    for (let key in this.state) {
      if (key === "tags") {
        continue;
      } else if (this.state[key].length === 0) {
        alert(`MACRO NEEDS A ${key.toUpperCase()}`);
        return;
      }
    }

    this.setState({ submitting: true });

    this.props
      .updateMacro(
        this.state.title,
        this.state.body,
        this.state.tags.split(", ")
      )
      .then(() => {
        this.setState({ submitting: false });
        alert("Updated!");
      });
  };

  fetchMacro = () => {
    const macro = this.props.macros[this.state.title];
    if (!macro) {
      alert("That macro doesn't exist yet!");
      return;
    }

    this.setState({ body: macro.text, tags: macro.associatedTags.join(", ") });
  };

  render() {
    return (
      <main>
        <Navbar />
        <div className="blog-post">
          <div className="blog-edit">
            <div className="blog-images">
              <h2>SELECT MACRO TO EDIT (or make new)</h2>
              <input
                type="text"
                value={this.state.title}
                placeholder="macro title"
                onChange={this.handleChange("title")}
              />
              <button onClick={this.fetchMacro}>Load Macro</button>
              <select onChange={this.handleChange("title")}>
                <option value={""} disabled selected>
                  Existing Macros
                </option>
                {Object.keys(this.props.macros).map((title, i) => (
                  <option key={i} value={title}>
                    {title}
                  </option>
                ))}
              </select>
            </div>
            <div className="blog-post-creator">
              <h2>MACRO CREATOR</h2>
              <textarea
                onChange={this.handleChange("body")}
                value={this.state.body}
                className="blog-text"
              />
              <input
                type="text"
                value={this.state.tags}
                placeholder="macro tags"
                onChange={this.handleChange("tags")}
              />
              <p>(comma separated -> ex: achrevoke, deny, tom-is-great)</p>
            </div>
            <div className="blog-meta-data">
              <h2>SUBMISSION</h2>
              {this.state.submitting ? null : (
                <button onClick={this.submitMacro}>Submit Macro</button>
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  macros: state.entities.macros
});

const mapDispatchToProps = dispatch => ({
  updateMacro: (title, body, tags) => dispatch(updateMacro(title, body, tags)),
  fetchMacros: () => dispatch(fetchMacros())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MacroEditor);
