import { domain } from "../helpers/route_helper";

export const completeIdentification = (id, decision) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/users/complete_identification`,
    data: { id: id, decision: decision }
  });
};

export const fetchDocuments = (user_id, loan_id = null) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/users/fetch_documents`,
    data: { user_id: user_id, loan_id: loan_id }
  });
};

export const fetchIdentifications = () => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/users/fetch_identifications`,
    data: {}
  });
};

export const dashboardEditInfo = (parameter, text, id, force) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/users/edit_info`,
    data: { parameter, text, id, force }
  });
};

export const resendApprovalText = id => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/users/resend_approval_text`,
    data: { loan_id: id }
  });
};
