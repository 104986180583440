import * as APIUtil from "../util/search_api_util";
import { receiveUser } from "./user_actions";
import { receiveLoans } from "./loan_actions";
import { receiveLoading } from "./loader_actions";

export const search = (parameter, search) => dispatch =>
  APIUtil.search(parameter, search).then(
    payload => {
      dispatch(receiveUser(payload.user));
      dispatch(receiveLoans(payload.loans));
      dispatch(receiveLoading(false));
    },
    () => {
      dispatch(receiveUser(null));
      dispatch(receiveLoans(null));
      dispatch(receiveLoading(false));
    }
  );
