import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchIdentifications,
  completeIdentification
} from "../actions/task_actions";
import Navbar from "./Navbar_container";
import UserIdentification from "./UserIdentification";
import "../styles/css/review.css";

class IdentificationList extends Component {
  componentDidMount() {
    this.props.fetchIdentifications();
  }

  render() {
    return (
      <div>
        <Navbar />

        <div className="review_container">
          {this.props.identifications.map(user_identifications => (
            <UserIdentification
              key={user_identifications.user_id}
              user_identifications={user_identifications}
              completeIdentification={this.props.completeIdentification}
            />
          ))}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  identifications: Object.values(
    state.entities.identifications.identifications
  ),
  aws_token: state.entities.identifications.accessKeyId,
  bucket: state.entities.identifications.bucket,
  secretAccessKey: state.entities.identifications.secretAccessKey
});

const mapDispatchToProps = dispatch => ({
  fetchIdentifications: () => dispatch(fetchIdentifications()),
  completeIdentification: (id, decision) =>
    dispatch(completeIdentification(id, decision))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdentificationList);
