import * as APIUtil from "../util/tasks_api_util";
// import { receiveSessionErrors } from "./error_actions";
import { receiveLoading } from "./loader_actions";
export const UPDATE_TASK = "UPDATE_TASK";
export const RECEIVE_IDENTIFICATIONS = "RECEIVE_IDENTIFICATIONS";
export const COMPLETE_IDENTIFICATION = "COMPLETE_IDENTIFICATION";

export const receiveIdentifications = identifications => ({
  type: RECEIVE_IDENTIFICATIONS,
  identifications
});

export const updateTask = (task, id) => ({
  type: UPDATE_TASK,
  task,
  id
});

export const removeIdentification = id => ({
  type: COMPLETE_IDENTIFICATION,
  id
});

export const dashboardEditInfo = (parameter, text, id, force) => dispatch =>
  APIUtil.dashboardEditInfo(parameter, text, id, force).then(
    tasks => dispatch(updateTask(tasks)),
    error => {
      alert(error.responseJSON.errors[0]);
    }
  );

export const completeIdentification = (id, decision) => dispatch => {
  APIUtil.completeIdentification(id, decision).then(identifications =>
    dispatch(removeIdentification(id))
  );
};

export const fetchIdentifications = () => dispatch =>
  APIUtil.fetchIdentifications().then(identifications => {
    dispatch(receiveIdentifications(identifications));
    dispatch(receiveLoading(false));
  }, null);
