import { domain } from "../helpers/route_helper";

export const getOpenTickets = (num) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/tickets`,
    data: { num },
  });
};

export const getTicket = (id, preview) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/ticket`,
    data: { id, preview },
  });
};

export const closeTicket = (id) => {
  return window.$.ajax({
    method: "DELETE",
    url: `${domain}/internal/api/ticket`,
    data: { id },
  });
};

export const archiveTicket = (id) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/archive_ticket`,
    data: { id },
  });
};

export const unarchiveTicket = (id) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/unarchive_ticket`,
    data: { id },
  });
};

export const escalateTicket = (id) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/escalate_ticket`,
    data: { id },
  });
};

export const deEscalateTicket = (id) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/deescalate_ticket`,
    data: { id },
  });
};

export const fetchUserInfo = (id) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/user_info`,
    data: { id },
  });
};

export const editUserInfo = (id, parameter, text) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/user_info`,
    data: { id, parameter, text },
  });
};

export const fetchLoanInfo = (id) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/internal/api/loan_info`,
    data: { id },
  });
};

export const createEarlyRepay = (loan_id, date) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/early_repay`,
    data: { loan_id, date },
  });
};

export const cancelEarlyRepay = (pmt_id, date) => {
  return window.$.ajax({
    method: "DELETE",
    url: `${domain}/internal/api/early_repay`,
    data: { pmt_id },
  });
};

export const updatePaymentDate = (pmt_id, date) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/payment_date`,
    data: { pmt_id, date },
  });
};

export const reOpenApproval = (loan_id) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/reopen_approval`,
    data: { loan_id },
  });
};

export const togglePaymentMethod = (pmt_id) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/toggle_payment_method`,
    data: { pmt_id },
  });
};

export const smallerPayment = (pmt_id, amt) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/smaller_payment`,
    data: { pmt_id, amt },
  });
};

export const settlement = settlement => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/settlement`,
    data: { settlement }
  })
}

export const creditBounceFee = (pmt_id) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/credit_bounce_fee`,
    data: { pmt_id },
  });
};

export const linkTicket = (user_id, ticket_id, email) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/users/link_ticket`,
    data: { user_id, ticket_id, email },
  });
};

export const toggleACH = (loan_id) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/internal/api/toggle_ach`,
    data: { loan_id },
  });
};
