import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import Modal from "./Modal";
import "../styles/css/toggle.css";
import { addInfo } from "../actions/user_actions";

const Toggle = ({ 
  userInfo,
  type,
  addInfo
  }) => {

  const [openModal, setOpenModal] = useState(false);
  const [whichModal, setWhichModal] = useState(null);
  const [dnc, setDnc] = useState(userInfo.dnc);
  const [dnl, setDnl] = useState(userInfo.dnl);
  const [reason, setReason] = useState('');
  const [context, setContext] = useState('');
  const [toggleState, setToggleState] = useState(false);

  const current = new Date();
  const dateTime = `${current.getUTCFullYear()}-${current.getUTCMonth()+1}-${current.getUTCDate()} (${current.getUTCHours()}:${current.getUTCMinutes()} UTC)`;

  useEffect(() => {
    switch(type) {
      case "dnc":
        dnc ? setToggleState(true) : setToggleState(false);
        break;
      case "dnl":
        dnl ? setToggleState(true) : setToggleState(false);
        break;
    }
  }, [dnc, dnl]);
  
  // handles toggle on/off. toggle change triggers confirmation modal pop up
  const handleToggle = () => {
    setOpenModal(true)
    switch(type) {
      case "dnc":
        // true => remove modal because DNC is currently true
        dnc ? setWhichModal(dncRemoveModal) : setWhichModal(dncAddModal);
        break;
      case "dnl":
        dnl ? setWhichModal(dnlRemoveModal) : setWhichModal(dnlAddModal);
        break;
    }
  };

  // handles modal submit
  const handleSubmit = (text) => {
    // comment elements formatting
    const reason_formatted = reason.length > 0 ? `${reason}. ` : "";
    const context_formatted = context.length > 0 ? `${context}.` : "";
    // comment decision (sets text paramter for add_info call)
    switch(type) {
      case "dnc":
        // set DNC comment
        text = `${dateTime}: DNC ${dnc ? "removed":"added"}. ${reason_formatted}${context_formatted}`;
        setDnc(!dnc);
        break;
      case "dnl":
        // set DNL comment
        text = `${dateTime}: DNL ${dnl ? "removed":"added"}. ${reason_formatted}${context_formatted}`;
        setDnl(dnl ? 0 : 1);
        break;
    }
    // BE call
    addInfo(type, text, userInfo.id);
    closeModal();
  };

  const closeModal = () => {
    setOpenModal(false);
    setReason('');
    setContext('');
  };

  // modal partial options
  const dncAddModal = (
    <div>
      <h2><b>Why are you ADDING this DNC?</b></h2>
      <select name="dnc-options" id="dnc-options" onChange={e => setReason(e.target.value)}>
        <option value=""></option>
        <option value="User Requested">User requested</option>
        <option value="Authorized third party requested">Authorized third party requested</option>
        <option value="In bankruptcy">In bankruptcy</option>
        <option value="Identity theft/fraud">Identity theft/fraud</option>
        <option value="Profanity/threats">Profanity/threats</option>
      </select>
    </div>
  );

  const dncRemoveModal = (
    <div>
      <h2><b>Are you sure you want to REMOVE the DNC on this borrower?</b></h2>
    </div>
  );   

  const dnlAddModal = (
    <div>
      <h2><b>Why are you ADDING this DNL?</b></h2>
      <select name="dnl-options" id="dnl-options" onChange={e => setReason(e.target.value)}>
        <option value=""></option>
        <option value="In bankruptcy">In bankruptcy</option>
        <option value="Identity theft/fraud">Identity theft/fraud</option>
        <option value="Profanity/threats">Profanity/threats</option>
      </select>
    </div>
  );

  const dnlRemoveModal = (
    <div>
      <h2><b>Are you sure you want to REMOVE the DNL on this borrower?</b></h2>   
    </div>
  );

  return (
    <>
      <li className="toggle-li toggle-name" style={{ textTransform: 'uppercase' }}><b>{type}</b></li>
      <li className="toggle-li">
        <input
          checked={toggleState}
          className="toggle-checkbox"
          id="toggle"
          type="checkbox"
          readOnly
        />
        <label
          className="toggle-label"
          htmlFor="toggle"
          onClick={handleToggle}
        >
          <span 
            className="toggle-button"
          />
        </label>
      </li>
      {openModal && (
        <Modal closeAction={closeModal}>
          <form>
            {whichModal}
            <label htmlFor="context">Additional context (optional):</label>
            <br></br>
            <textarea id="context" name="context" onChange={e => setContext(e.target.value)}/>
            <br></br>
            <button onClick={handleSubmit}>Submit</button>
          </form>
        </Modal>
      )}
    </>
  )
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  addInfo: (parameter, text, userId) =>
    dispatch(addInfo(parameter, text, userId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Toggle);
