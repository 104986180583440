import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatJSONDate } from "../helpers/date_helper";
import "../styles/css/loan.css";
import StatementView from "./StatementView";
import Editable from "./Editable";
import Modal from "./Modal";
import DebouncedButton from "./DebouncedButton";
import Loader from "react-loader";
import moment from "moment";

export default class LoanView extends Component {
  static propTypes = {
    loanInfo: PropTypes.object,
    changePayment: PropTypes.func.isRequired,
    openStatementLink: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      experiment: "",
      earlyRepay: null,
      customPayments: false,
      customPaymentsAmount: "",
      customPaymentsFrequency: null,
      confirmCancel: false,
      showDelay: false,
      buttonClicks: [],
      modalOpen: false,
      modalComponent: null,
      selectedPayment: null,
      splitPaymentDate: moment(),
      splitPaymentSize: null,
      splitPaymentLoading: false,
      link: "statements",
      settleAmt: null
    };
  }

  spinnerOptions = {
    lines: 13,
    length: 5,
    width: 5,
    radius: 5,
    scale: 1.0,
    corners: 1,
    color: "#41b29e",
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    left: "50%",
    shadow: false,
    hwaccel: false,
    position: "relative"
  };

  handleCancel = () => {
    if (this.state.confirmCancel) {
      this.props.cancel(this.props.loanInfo.id);
    } else {
      this.setState({ confirmCancel: true });
    }
  };

  changePaymentMethod = id => () => {
    this.setState({
      buttonClicks: this.state.buttonClicks.concat(["toggleMethod"])
    });
    const payment = {
      loan: this.props.loanInfo.id,
      payment: id,
      action: "toggle_method"
    };
    this.props.changePayment(payment).then(() =>
      this.setState({
        buttonClicks: this.state.buttonClicks.filter(a => a !== "toggleMethod")
      })
    );
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      modalComponent: null,
      splitPaymentLoading: false,
      splitPaymentDate: moment(),
      splitPaymentSize: null
    });
  };

  toggleValid = id => () => {
    this.setState((state, props) => {
      buttonClicks: state.buttonClicks.concat(["toggleValid"]);
    });
    const payment = {
      loan: this.props.loanInfo.id,
      payment: id,
      action: "toggle_valid"
    };

    this.props.changePayment(payment).then(() =>
      this.setState({
        buttonClicks: this.state.buttonClicks.filter(a => a !== "toggleValid")
      })
    );
  };

  refund = id => () => {
    this.setState({ buttonClicks: this.state.buttonClicks.concat(["refund"]) });

    const payment = {
      loan: this.props.loanInfo.id,
      payment: id,
      action: "refund"
    };

    this.props.changePayment(payment).then(
      () =>
        this.setState({
          buttonClicks: this.state.buttonClicks.filter(a => a !== "refund")
        }),
      error => {
        let comp;
        if (error.status === 500) {
          comp = <h1>500 Error. Let Samer know.</h1>;
        } else {
          comp = <h1>{error.responseJSON.errors[0]}</h1>;
        }
        this.setState({
          modalComponent: comp,
          modalOpen: true,
          buttonClicks: this.state.buttonClicks.filter(a => a !== "refund")
        });
      }
    );
  };

  render() {
    let loanInfo,
      splitPayment,
      customPayments,
      openStatementLink,
      short,
      manualDenialEmail,
      manualApproval,
      appeal,
      counterOffer,
      offerRefi,
      calcEarlyRepay,
      createEarlyRepay,
      confirmCB,
      toggleACH,
      clearAlert,
      currentUser,
      userId;

    ({
      loanInfo,
      splitPayment,
      customPayments,
      openStatementLink,
      short,
      manualDenialEmail,
      manualApproval,
      appeal,
      counterOffer,
      offerRefi,
      calcEarlyRepay,
      createEarlyRepay,
      confirmCB,
      toggleACH,
      clearAlert,
      currentUser,
      userId
    } = this.props);

    if (!loanInfo) {
      return <div id="loan-info">No Loan Info To Show</div>;
    }

    const updatePaymentDate = e => {
      this.setState({ splitPaymentDate: e.target.value }, setSplitPaymentModal);
    };

    const splitter = e => {
      e.stopPropagation();
      // this.setState({
      //   buttonClicks: this.state.buttonClicks.concat(["split"])
      // });
      const paymentID = e.target.dataset.payment;
      const selectedPayment = loanInfo.paymentsDue[paymentID];

      this.setState(
        {
          selectedPayment,
          splitPaymentDate: formatJSONDate(selectedPayment.due)
        },
        setSplitPaymentModal
      );

      // splitPayment(payment).then(() =>
      //   this.setState({
      //     buttonClicks: this.state.buttonClicks.filter(a => a !== "split")
      //   })
      // );
    };

    const onSplitSuccess = dispatch => {
      dispatch();
      const comp = <div>Success!</div>;
      this.setState({ modalComponent: comp });
    };

    const onSplitError = errors => {
      let comp;
      const paymentInfo = this.state.selectedPayment;
      if (errors[0].startsWith("Warning")) {
        comp = (
          <div className="flex-col-container">
            <p>{errors[0]}</p>
            <button
              onClick={() => {
                this.setState(
                  { splitPaymentLoading: true },
                  setSplitPaymentModal
                );
                splitPayment(
                  loanInfo.id,
                  paymentInfo.id,
                  this.state.splitPaymentSize,
                  this.state.splitPaymentDate,
                  true,
                  onSplitSuccess,
                  onSplitError
                );
              }}
            >
              Confrim
            </button>
          </div>
        );
      } else {
        comp = (
          <div className="flex-col-container">
            The following error occured: <p>{errors[0]}</p>
          </div>
        );
      }
      this.setState({ modalComponent: comp });
    };

    const setSplitPaymentModal = () => {
      const paymentInfo = this.state.selectedPayment;
      const modalComponent = (
        <div className="flex-col-container">
          <h1>Split Payment</h1>
          <div className="flex-col-container">
            <div className="flex-row-container third">
              <p>ID: {paymentInfo.id}</p>
              <p>Amt: ${paymentInfo.expected_amt}</p>
              <p>
                Due:{" "}
                {moment(paymentInfo.due.substring(0, 10)).format("MM/DD/YY")}
              </p>
            </div>
            <div className="flex-row-container third">
              <p>New Payment Due On:</p>

              <p>
                {moment(this.state.splitPaymentDate).format(
                  "dddd, MMMM Do YYYY"
                )}
              </p>

              <input
                data-payment={paymentInfo}
                data-loan={loanInfo.id}
                type="date"
                value={this.state.splitPaymentDate}
                onChange={updatePaymentDate}
              />
            </div>
          </div>
          <span style={{ width: "30%" }}>
            Split Size: $
            <input
              onChange={e => {
                this.setState({ splitPaymentSize: e.target.value });
              }}
              style={{ width: "50px", marginLeft: "5px" }}
            />
          </span>
          <br />
          <Loader
            options={this.spinnerOptions}
            loaded={!this.state.splitPaymentLoading}
          >
            <button
              className="split-button"
              onClick={() => {
                this.setState(
                  { splitPaymentLoading: true },
                  setSplitPaymentModal
                );
                splitPayment(
                  loanInfo.id,
                  paymentInfo.id,
                  this.state.splitPaymentSize,
                  this.state.splitPaymentDate,
                  false,
                  onSplitSuccess,
                  onSplitError
                );
              }}
            >
              Split
            </button>
          </Loader>
        </div>
      );
      this.setState({ modalOpen: true, modalComponent });
    };

    const triggerDecisionConfirmModal = decision => {
      const comp = (
        <div>
          <h1>
            Are you sure you want to <b>{decision}</b> loan id {id}?
          </h1>
          <button
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              this.setState({
                modalComponent: (
                  <div style={{ padding: "30px" }}>
                    <Loader loaded={false}></Loader>
                  </div>
                )
              });
              this.props.submitDecision(
                id,
                decision,
                onDecisionSuccess,
                onDecisionError
              );
            }}
          >
            {decision}
          </button>
          <button onClick={this.closeModal}>Cancel</button>
        </div>
      );
      this.setState({ modalComponent: comp, modalOpen: true });
    };

    const onDecisionSuccess = () => {
      this.setState({
        modalComponent: (
          <div style={{ padding: "20px" }}>
            <center>
              <h1>Success!</h1>
            </center>
          </div>
        )
      });
    };

    const onDecisionError = error => {
      this.setState({
        modalComponent: (
          <div style={{ padding: "20px" }}>
            <center>
              <h1>{error}</h1>
            </center>
          </div>
        )
      });
    };

    let id,
      applied,
      approved,
      funded,
      funding_returned,
      cash_advanced,
      installment_num,
      applied_amt,
      total_payments,
      approval_code,
      accepted,
      apr,
      url,
      upload_open,
      payoff_balance,
      amount_credited_to_loan,
      settlementMin,
      ach,
      denied,
      deny_reason,
      closed_at,
      comments,
      experiment,
      paymentsMade,
      paymentsDue,
      invalidPayments,
      amount,
      type,
      prequal,
      preapproval,
      repeatRecovery,
      trueAccord,
      needsDisclosure,
      bounces,
      paymentDays;
    ({
      id,
      applied,
      approved,
      funded,
      funding_returned,
      cash_advanced,
      installment_num,
      applied_amt,
      total_payments,
      accepted,
      approval_code,
      payoff_balance,
      amount_credited_to_loan,
      settlementMin,
      apr,
      url,
      upload_open,
      ach,
      denied,
      deny_reason,
      closed_at,
      comments,
      experiment,
      paymentsMade,
      paymentsDue,
      invalidPayments,
      amount,
      type,
      prequal,
      preapproval,
      repeatRecovery,
      trueAccord,
      needsDisclosure,
      bounces,
      paymentDays
    } = loanInfo);
    paymentsDue = paymentsDue ? Object.values(paymentsDue) : [];
    paymentsMade = paymentsMade ? Object.values(paymentsMade) : [];
    invalidPayments = invalidPayments ? Object.values(invalidPayments) : [];
    url = url
      ? this.state.link === "statements"
        ? `https://www.figloans.com/loans/statements/new/${url}`
        : this.state.link === "paystubs"
        ? `https://www.figloans.com/loans/paystubs/new/${url}`
        : this.state.link === "identity"
        ? `https://www.figloans.com/users/identity/new/${url}`
        : null
      : null;

    const copyPaymentInfo = () => {
      const el = document.createElement("textarea");
      let paymentInfo = `Payments \n`;

      paymentsDue
        .sort(
          (a, b) =>
            new Date(formatJSONDate(a.due)) - new Date(formatJSONDate(b.due))
        )
        .forEach(payment => {
          paymentInfo += `ID: ${payment.id}  Amt: $${
            payment.expected_amt
          }   Due: ${moment(formatJSONDate(payment.due)).format(
            "MMMM D, YYYY"
          )} ${
            this.state.showDelay
              ? `(${moment(formatJSONDate(payment.due)).diff(
                  moment(formatJSONDate(payment.original_due)),
                  "days"
                )} days delayed)`
              : ""
          } ${payment.processing ? "--Processing--" : ""}\n`;
        });

      el.value = paymentInfo;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    };
    const copyString = string => {
      return () => {
        const el = document.createElement("textarea");
        el.value = string;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
      };
    };

    const openLink = e => {
      e.preventDefault();
      openStatementLink(id);
      copyString(url)();
    };

    return (
      <div id="loan-info">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3>Loan Info </h3>
        </div>

        <ul>
          <li className={type}>
            {" "}
            ID:{" "}
            {
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://internal.figloans.com/#/loan_review?id=${id}`}
              >
                {id}
              </a>
            }{" "}
          </li>
          {bounces && bounces.length > 0 ? (
            <li className="underwriting"> Bounces: {bounces.join(" ")}</li>
          ) : null}
          {paymentDays ? (
            <li className="underwriting">{paymentDays} days delayed </li>
          ) : null}
          {needsDisclosure ? (
            <li>***OH: NEEDS TO UPDATE BANK ACCOUNT***</li>
          ) : null}
          <li className={type}> {type}</li>
          {prequal ? <li className="CreditBuilder">PREQUAL</li> : null}
          {preapproval ? <li className="CreditBuilder">PREAPPROVAL</li> : null}
          {repeatRecovery ? (
            <li className="CreditBuilder">REPEAT RECOVERY</li>
          ) : null}
          {trueAccord ? <li className="CreditBuilder">TRUE ACCORD</li> : null}
          {approved ? (
            <li>
              {" "}
              <span style={{ color: ach ? "black" : "red" }}>ACH:</span>{" "}
              <button
                onClick={() => toggleACH(loanInfo.id)}
                style={{
                  backgroundColor: ach ? "white" : "red",
                  color: ach ? "black" : "white"
                }}
              >
                {ach ? "ON" : "OFF"}
              </button>{" "}
            </li>
          ) : null}
          {applied && !approved && !denied && !closed_at ? (
            <li>
              <button onClick={() => triggerDecisionConfirmModal("approve")}>
                Approve
              </button>
              <button onClick={() => triggerDecisionConfirmModal("deny")}>
                Deny
              </button>
            </li>
          ) : null}

          <li>
            {closed_at || funded ? null : (
              <button onClick={this.handleCancel}>
                {this.state.confirmCancel ? "Sure?" : "Cancel"}
              </button>
            )}{" "}
            {type === "CreditBuilder" && !approved && !denied && !closed_at ? (
              <button onClick={() => confirmCB(id)}>Confirm CB</button>
            ) : null}
          </li>
          <li className="denial">
            {denied
              ? [
                  `Denied: ${new Date(denied).toLocaleString("en-US")}`,
                  <button onClick={() => appeal(loanInfo.id)}>Appeal</button>
                ]
              : ""}
          </li>
          <li className="loan-status">
            {applied && !(approved || denied || closed_at)
              ? `Application in Process`
              : ""}
            {closed_at
              ? `Closed: ${new Date(closed_at).toLocaleString("en-US")}`
              : ""}
            {closed_at ? (
              <button onClick={() => this.props.reopenLoan(id)}>Reopen</button>
            ) : null}
          </li>
          <li> {deny_reason ? `Denial Reason: ${deny_reason}` : ""} </li>
          <li>
            {" "}Amount: ${cash_advanced}{" "}{applied_amt !== cash_advanced && `(${applied_amt})`}{" "}
          </li>
          {payoff_balance ? (
            <li>
              {" "}
              <span
                onClick={() => this.setState({ earlyRepay: true })}
                className="button-word"
              >
                Payoff
              </span>{" "}
              Balance: ${payoff_balance}{" "}
            </li>
          ) : (
            ""
          )}
          {amount_credited_to_loan ? (
            <li>
              Amount Credited to Loan: ${amount_credited_to_loan.toFixed(2)}
            </li>
          ) : (
            ""
          )}
          {this.state.earlyRepay ? (
            <li>
              <input
                data-loan={id}
                type="date"
                onChange={e => {
                  calcEarlyRepay(id, e.target.value);
                  this.setState({ earlyRepay: e.target.value });
                }}
              />{" "}
              <b>{amount}</b>
              {amount ? (
                <button
                  onClick={() => {
                    createEarlyRepay(id, this.state.earlyRepay);
                    this.setState({ earlyRepay: null });
                  }}
                >
                  Submit
                </button>
              ) : null}
            </li>
          ) : null}
          <li>
            APR: {apr}
          </li>
          {url && !approved ? (
            <li>
              {" "}
              <select onChange={e => this.setState({ link: e.target.value })}>
                <option value="statements">Statements</option>
                <option value="paystubs">Paystubs</option>
                <option value="identity">Identity</option>
              </select>{" "}
              Link:{" "}
              {(this.state.link === "statements" && upload_open) ||
              this.state.link !== "statements" ? (
                <button onClick={copyString(url)}>Copy</button>
              ) : (
                <button onClick={openLink}>Open and Copy</button>
              )}
              <br />
            </li>
          ) : (
            ""
          )}
          <li> Applied: {new Date(applied).toLocaleString("en-US")} </li>
          <li className="approved">
            {approved
              ? `Approved: ${new Date(approved).toLocaleString("en-US")}`
              : null}
          </li>
          {approved
            ? [
                <li className="accepted">
                  Accepted:{" "}
                  {accepted ? (
                    new Date(accepted).toLocaleString("en-US")
                  ) : (
                    <button onClick={copyString(approval_code)}>
                      Copy Code
                    </button>
                  )}
                </li>,

                <li
                  className={`funded`}
                  style={funding_returned ? { color: "red" } : null}
                >
                  {funded
                    ? funding_returned
                      ? "Funding Returned"
                      : `Funded: ${new Date(funded).toLocaleString("en-US")}`
                    : null}
                </li>
              ]
            : ""}
          {paymentsDue.length > 0
            ? [
                <button onClick={copyPaymentInfo}>Copy Payment Info</button>,
                <label>
                  Show Delay:
                  <input
                    name="showDelay"
                    type="checkbox"
                    checked={this.state.showDelay}
                    onChange={e =>
                      this.setState({ showDelay: e.target.checked })
                    }
                  />
                </label>
              ]
            : ""}
          {paymentsDue
            .sort(
              (a, b) =>
                new Date(formatJSONDate(a.due)) -
                new Date(formatJSONDate(b.due))
            )
            .map(payment => (
              <li key={payment.id} className="unpaid">
                {payment.needsDisclosure ? (
                  <span>***OH: NEEDS PAYMENT REPRESENTATION DISCLOSURE***</span>
                ) : null}
                Payment ID: {payment.id}
                <br /> Amt: ${payment.expected_amt}
                <button
                  data-payment={payment.id}
                  data-loan={id}
                  onClick={splitter}
                >
                  Split
                </button>
                <br />
                <input
                  type="date"
                  value={formatJSONDate(payment.due)}
                  onChange={ev => {
                    const reschedulePayment = {
                      loan: this.props.loanInfo.id,
                      payment: payment.id,
                      action: "reschedule",
                      date: ev.target.value
                    };

                    this.props.changePayment(reschedulePayment).then(() => {
                      this.setState({
                        buttonClicks: this.state.buttonClicks.filter(
                          a => a !== "reschedule"
                        )
                      });
                    });
                  }}
                />
                <br />
                Original Due:{" "}
                {new Date(formatJSONDate(payment.original_due)).toLocaleString(
                  "en-us",
                  {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                    timeZone: "UTC"
                  }
                )}
                <br />
                Method:{" "}
                {this.state.buttonClicks.includes("toggleMethod") ? (
                  "..."
                ) : (
                  <button onClick={this.changePaymentMethod(payment.id)}>
                    {payment.method}
                  </button>
                )}
                {payment.bounces > 0
                  ? [<br />, `Bounced - ${payment.bounces}`]
                  : null}
                <br />
                Status:
                {payment.processing ? "--Processing--" : ""} {payment.type}
                {payment.proposed_due ? (
                  <p>
                    Proposed Due:{" "}
                    {new Date(
                      formatJSONDate(payment.proposed_due)
                    ).toLocaleString("en-us", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                      timeZone: "UTC"
                    })}
                  </p>
                ) : null}
                {parseInt(currentUser.employeeID, 10) === 6 ||
                (payment.type === "EarlyRepayment" &&
                  paymentsMade.length +
                    paymentsDue.length +
                    invalidPayments.length ===
                    installment_num + 1) ? (
                  <button onClick={this.toggleValid(payment.id)}>
                    Invalidate
                  </button>
                ) : null}
              </li>
            ))}
          {paymentsMade
            .sort(
              (a, b) =>
                new Date(formatJSONDate(a.due)) -
                new Date(formatJSONDate(b.due))
            )
            .map((payment, i) => (
              <li key={payment.id} className="paid">
                Payment ID: {payment.id} <br /> Amt: ${payment.expected_amt}{" "}
                <br /> Paid:{" "}
                {new Date(formatJSONDate(payment.paid)).toLocaleString(
                  "en-us",
                  {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                    timeZone: "UTC"
                  }
                )}
                <br />
                Original Due:{" "}
                {new Date(formatJSONDate(payment.original_due)).toLocaleString(
                  "en-us",
                  {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                    timeZone: "UTC"
                  }
                )}
                <br />
                Method: {payment.method}
                {payment.bounces > 0
                  ? [<br />, `Bounced - ${payment.bounces}`]
                  : null}
                {(payment.method === "ach" || payment.method === "debit") &&
                payment.refundable ? (
                  this.state.buttonClicks.includes("refund") ? (
                    <button
                      style={{
                        height: "20px",
                        width: "60px",
                        margin: "6px",
                        padding: "1px 6px"
                      }}
                    >
                      <Loader
                        loaded={false}
                        options={{
                          position: "relative",
                          scale: ".3",
                          color: "#41b29e"
                        }}
                      />
                    </button>
                  ) : (
                    <button onClick={this.refund(payment.id)}>Refund</button>
                  )
                ) : null}
              </li>
            ))}
          {invalidPayments
            .sort(
              (a, b) =>
                new Date(formatJSONDate(a.due)) -
                new Date(formatJSONDate(b.due))
            )
            .map(payment => (
              <li key={payment.id} className="invalid">
                Payment ID: {payment.id}
                <br />
                Amt: ${payment.expected_amt} <br />
                Due:{" "}
                {new Date(formatJSONDate(payment.due)).toLocaleString("en-us", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  timeZone: "UTC"
                })}
                <br />
                Original Due:{" "}
                {new Date(formatJSONDate(payment.original_due)).toLocaleString(
                  "en-us",
                  {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                    timeZone: "UTC"
                  }
                )}
                <br />
                Method: {payment.method}
                {payment.bounces > 0
                  ? [<br />, `Bounced - ${payment.bounces}`]
                  : null}
                {parseInt(currentUser.employeeID, 10) === 6 ? (
                  <button onClick={this.toggleValid(payment.id)}>
                    Validate
                  </button>
                ) : null}
              </li>
            ))}
          {this.state.customPayments ? (
            <li>
              <input
                data-loan={id}
                type="date"
                onChange={e => {
                  this.setState({ customPayments: e.target.value });
                }}
              />{" "}
              <input
                type="text"
                onChange={e => {
                  this.setState({ customPaymentsAmount: e.target.value });
                }}
                value={this.state.customPaymentsAmount}
              />
              <select
                value={this.state.customPaymentsFrequency}
                onChange={e =>
                  this.setState({ customPaymentsFrequency: e.target.value })
                }
              >
                <option value={null}></option>
                <option value="monthly">Monthly</option>
                <option value="biweekly">Biweekly</option>
                <option value="weekly">Weekly</option>
              </select>
              <button
                onClick={() => {
                  customPayments(
                    id,
                    this.state.customPayments,
                    this.state.customPaymentsAmount,
                    this.state.customPaymentsFrequency
                  );
                  this.setState({ customPayments: null });
                }}
              >
                Submit
              </button>
            </li>
          ) : (
            <li>
              <button onClick={() => this.setState({ customPayments: true })}>
                Custom
              </button>
            </li>
          )}
          <li> Total Payments: ${total_payments} </li>
          <li>
            {" "}
            Comments:{" "}
            <div className="comments-box">
              {comments.map((c, i) => (
                <p key={i}>
                  {new Date(formatJSONDate(c.created_at)).toLocaleString(
                    "en-us",
                    {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                      timeZone: "UTC"
                    }
                  )}{" "}
                  - {c.comment}
                </p>
              ))}
              <Editable
                parameter="comment"
                type="loan"
                id={id}
                text={"[NEW]"}
              />{" "}
            </div>
          </li>
          {comments &&
          comments.filter(a => a.comment.includes("fraud alert")).length > 0 &&
          !approved ? (
            <li>
              <button
                onClick={() => {
                  clearAlert(loanInfo.id);
                }}
              >
                Clear Alert
              </button>{" "}
            </li>
          ) : null}
          <li>
            {" "}
            Experiment:{" "}
            <Editable
              parameter="experiment"
              type="loan"
              id={id}
              text={experiment || "..."}
            />{" "}
          </li>
          {settlementMin && (<li>
            Settlement Min: ${settlementMin}
          </li>)}
        </ul>
        {short ? null : (
          <StatementView
            loan={loanInfo}
            manualDenialEmail={manualDenialEmail}
            manualApproval={manualApproval}
            counterOffer={counterOffer}
            offerRefi={offerRefi}
          />
        )}
        {this.state.modalOpen ? (
          <Modal closeAction={this.closeModal} open={this.state.modalOpen}>
            {this.state.modalComponent}
          </Modal>
        ) : null}
      </div>
    );
  }
}
