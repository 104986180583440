import React from "react";
import "../styles/css/error.css";

export default function CloseableErrorMessage({ error, closeAction }) {
  if (!error) return null;

  return (
    <div className="error-container">
      {closeAction && (
        <p onClick={() => closeAction()} className="error-close">
          X
        </p>
      )}
      <p className="error-message">{error}</p>
    </div>
  );
}
