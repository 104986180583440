import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { fetchUserInfo } from "../../../actions/ticket_actions";
import Editable from "../../Editable";
import { formatRawPhone } from "../../../helpers/phone_helper";
import { formatJSONDate } from "../../../helpers/date_helper";
import { domain } from "../../../helpers/route_helper";
import { motion } from "framer-motion";
import Toggle from "../../Toggle";
import '../../../styles/css/toggle.css';
import PaymentMethodInfo from "./PersonalInfo/PaymentMethodInfo";

const PersonalInfo = ({
  id,
  userInfo,
  ticket,
  fetchUserInfo,
  addResponse,
  setReplyMessage,
  macros,
  setMacroId,
  errors
}) => {
  const [showMore, setShowMore] = useState(true);

  const [isOpen, setIsOpen] = useState(true);

  const handleShow = () => {
    setShowMore(!showMore);
    setIsOpen(!isOpen);
    if (!userInfo.additional) {
      fetchUserInfo(id);
    }
  };

  useEffect(() => {
    fetchUserInfo(id)
  }, []);

  if (!userInfo) return <div />;

  return (
    <motion.div
      initial={"open"}
      animate={isOpen ? "open" : "closed"}
      variants={collapseVariants}
      className="personal-info"
    >
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 0.5 }}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <h2 className="section-title">Personal Information</h2>
      </motion.div>
      <div>
        {errors.map((error, i) => (
          <li style={{ color: "red" }} key={i}>
            {error}
          </li>
        ))}
      </div>
      <li className={userInfo.dnl ? "dnl" : null}>
        <span className="personal-info-accent">ID:</span>{" "}
        <a
          href={`https://internal.figloans.com/#/user?ID=${userInfo.id}`}
          target="_blank"
        >
          {userInfo.id}
        </a>
      </li>
      <li>
        <span className="personal-info-accent">Name:</span>{" "}
        {userInfo.first_name} {userInfo.middle_initial} {userInfo.last_name}
      </li>
      <li>{displayVerified(userInfo.verified, userInfo.verified_time)}</li>
      <div class="toggles-div">
        <li class="toggle-li-user">
          <Toggle
            userInfo={userInfo}
            type="dnc">
          </Toggle>
        </li>
        <li class="toggle-li-user">
          <Toggle
            userInfo={userInfo}
            type="dnl">
          </Toggle>
        </li>
      </div>
      <li>
        <span className="personal-info-accent">Email:</span>{" "}
        <Editable
          parameter="email"
          type="user"
          id={id}
          text={userInfo.email}
          validation={validateEmail}
          dashboard={true}
          ticket={true}
          afterSubmit={val => {
            setReplyMessage(macros["change_email"].text.replace("_", val));
            setMacroId(macros["change_email"].id);
          }}
        />
      </li>
      <li>
        <span className="personal-info-accent">Phone:</span>{" "}
        <Editable
          parameter="phone"
          type="user"
          id={id}
          text={formatRawPhone(userInfo.phone_number)}
          validation={validatePhone}
          dashboard={true}
          ticket={true}
          afterSubmit={val => {
            setReplyMessage(
              macros["change_phone"].text.replace("_", formatRawPhone(val))
            );
            setMacroId(macros["change_phone"].id);
          }}
        />
      </li>
      <li>
        <span className="personal-info-accent">State:</span> {ticket ? ticket.state : "Couldn't load state" }
      </li>
      {showMore && userInfo.additional && (
        <Fragment>
          <li>
            {" "}
            <span className="personal-info-accent">Birthday:</span>{" "}
            <Editable
              parameter="birthday"
              type="user"
              id={id}
              text={
                userInfo.birthday ? formatJSONDate(userInfo.birthday) : null
              }
              validation={validateDOB}
              dashboard={true}
              ticket={true}
              afterSubmit={val => {
                setReplyMessage(macros["change_dob"].text.replace("_", val));
                setMacroId(macros["change_dob"].id);
              }}
            />
          </li>
          <li>
            <span className="personal-info-accent">Address:</span>{" "}
            {userInfo.additional.street} {userInfo.additional.city}{" "}
            {userInfo.additional.zip} {userInfo.additional.state}
          </li>
          <PaymentMethodInfo
            userInfo={userInfo}
          />
          <li>
            <span className="personal-info-accent">Password:</span>{" "}
            <Editable
              parameter="password"
              type="user"
              id={id}
              text={"*****"}
              dashboard={true}
              ticket={true}
              afterSubmit={val =>
                addResponse(
                  `I've updated your password in our system to ${val}.`
                )
              }
            />
            <button
              className="ui-button"
              onClick={() =>
                sendPasswordReset(id).then(() => alert("Instructions sent."))
              }
            >
              Send Reset
            </button>
          </li>
          <li>
            <span className="personal-info-accent">Comment:</span>{" "}
            <Editable
              parameter="comment"
              type="user"
              id={id}
              text={userInfo.additional.comment}
              dashboard={true}
              ticket={true}
            />
          </li>
        </Fragment>
      )}
      <p className="show-more" onClick={handleShow}>
        {showMore ? "Hide" : "Show More"}
      </p>
    </motion.div>
  );
};

const validateEmail = email => {
  var re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return re.test(String(email).toLowerCase());
};

const validatePhone = phone => {
  const onlyNums = /^[0-9]*$/;
  return phone.length === 10 && onlyNums.test(phone);
};

const validateDOB = dob => {
  const date = new Date(dob);
  return date.toString() !== "Invalid Date";
};

const displayVerified = (status, verifiedTime) => {
  switch (status) {
    case -5:
      return (
        <li style={{ color: "orange" }}>Documents Received / Pending Review</li>
      );
    case -2:
      return <li style={{ color: "red" }}>DL-SSN Not Verified</li>;

    case -1:
      return <li style={{ color: "red" }}>DL Not Verified</li>;
    case 1:
      return (
        <li style={{ color: "green" }}>
          DL Verified ({verifiedTime ? formatJSONDate(verifiedTime) : null})
        </li>
      );
    case 2:
      return (
        <li style={{ color: "green" }}>
          DL-SSN Verified ({verifiedTime ? formatJSONDate(verifiedTime) : null})
        </li>
      );
    default:
      return null;
  }
};

const collapseVariants = {
  open: {
    transition: {}
  },
  closed: {
    transition: {}
  }
};

const sendPasswordReset = id =>
  window.$.ajax({
    method: "POST",
    url: `${domain}/internal/api/send_password_reset`,
    data: { id }
  });

const mapStateToProps = state => ({
  errors: Object.values(state.errors.edit)
});

const mapDispatchToProps = dispatch => ({
  fetchUserInfo: id => dispatch(fetchUserInfo(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInfo);
