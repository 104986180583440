export const smallGreenSpinner = {
  lines: 10,
  length: 5,
  width: 5,
  radius: 5,
  scale: 0.5,
  corners: 1,
  color: "green",
  opacity: 0.25,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 2e9,
  left: "50%",
  shadow: false,
  hwaccel: false,
  position: "relative",
};