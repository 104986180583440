import { merge, assign } from "lodash";
import {
  RECEIVE_OPEN_TICKETS,
  RECEIVE_TICKET,
  RECEIVE_TICKET_USER,
  RECEIVE_TICKET_LOAN,
  RECEIVE_TICKET_ERRORS,
  RECEIVE_CLOSED_TICKET,
  CLEAR_TICKETS,
} from "../actions/ticket_actions";

const emptyState = {
  tickets: {},
  openTickets: [],
  archivedTickets: [],
  closedTickets: [],
  employeeTicketCount: 0,
  totalTicketCount: 0,
  ticketErrors: {},
}

const ticketsReducer = (
  oldState = {
    tickets: {},
    openTickets: [],
    archivedTickets: [],
    closedTickets: [],
    employeeTicketCount: 0,
    totalTicketCount: 0,
    ticketErrors: {},
  },
  action
) => {
  Object.freeze(oldState);
  let newState;
  let task_id;
  switch (action.type) {
    case RECEIVE_OPEN_TICKETS:
      const newTickets = {
        tickets: merge({}, oldState.tickets, action.tickets.tickets),
        openTickets: [...action.tickets.openTickets],
        archivedTickets: [...action.tickets.archivedTickets],
        escalatedTickets: action.tickets.escalatedTickets && [
          ...action.tickets.escalatedTickets,
        ],
      };
      return Object.assign({}, action.tickets, newTickets, {
        ticketErrors: oldState.ticketErrors,
        closedTickets: oldState.closedTickets.filter(
          (a) => !newTickets.openTickets.includes(a)
        ),
      });
    case RECEIVE_TICKET:
      const tickets = merge({}, oldState.tickets, action.ticket);
      return merge({}, oldState, { tickets });
    case CLEAR_TICKETS:
      return emptyState;
    case RECEIVE_TICKET_USER:
      const ticketCopy = { ...oldState.tickets[action.id] };
      ticketCopy["user"] = action.user;
      const ticketsCopy = merge({}, oldState.tickets, {
        [action.id]: ticketCopy,
      });
      return merge({}, oldState, { tickets: ticketsCopy });
    case RECEIVE_TICKET_LOAN:
      const ticketLoanCopy = { ...oldState.tickets[action.id] };
      ticketLoanCopy["loans"][action.loan.id] = action.loan;
      const ticketsLoanCopy = merge({}, oldState.tickets, {
        [action.id]: ticketLoanCopy,
      });
      return merge({}, oldState, { tickets: ticketsLoanCopy });
    case RECEIVE_TICKET_ERRORS:
      const ticketErrors = Object.assign({}, oldState.ticketErrors, {
        [action.id]: action.errors,
      });
      return Object.assign({}, oldState, { ticketErrors });
    case RECEIVE_CLOSED_TICKET:
      const closedTickets = [...oldState.closedTickets];
      closedTickets.push(action.id);
      let tixCopy = oldState.tickets;
      delete tixCopy[action.id];
      return Object.assign({}, oldState, {
        closedTickets,
        tickets: tixCopy,
      });
    default:
      return oldState;
  }
};

export default ticketsReducer;
