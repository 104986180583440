import { motion } from "framer-motion";
import React, { useState } from "react";
import { connect } from "react-redux";
import TicketMessages from "../../dashboard/TicketMessages";

const Communications = ({ ticket, handleShowModal, sent }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <motion.div
      variants={expandedVariants}
      animate={expanded ? "open" : "closed"}
      className={`communications  ${expanded ? "add-shadow" : null}`}
    >
      <div className="section-title-wrapper">
        <motion.h2
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.5 }}
          className="section-title"
        >
          Communications
        </motion.h2>
        <i
          onClick={() => {
            setExpanded((s) => !s);
          }}
          className={`expand-icon fas fa-expand`}
        />
      </div>
      <div className="scroll-communication">
        <TicketMessages task={ticket} handleShowModal={handleShowModal} />
      </div>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Communications);

const expandedVariants = {
  open: {
    height: "80vh",
  },
};
