import React, { Component, useState } from "react";
import { domain } from "../../../helpers/route_helper";
import { connect } from "react-redux";
import { linkTicket } from "../../../actions/ticket_actions";

// ({ id, email, setModal, setModalComponent }) => {

class LinkAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountToLinkKey: "",
      accountToLinkMethod: "ID",
      accountToLinkDetails: null
    };
  }

  lookupAccountDetails = (method, key) => {
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/users/search`,
      data: { parameter: method, search: key }
    })
      .then(data => {
        this.setState({ accountToLinkDetails: data.user });
        this.linkAccount();
      })
      .catch(error => alert(error.responseJSON.errors));
  };

  linkEmailToAccount = () => {
    const setToPrimary = document.getElementById("set-email-to-primary")
      .checked;
    window.$.ajax({
      method: "POST",
      url: `${domain}/internal/api/users/add_info`,
      data: {
        user_id: this.state.accountToLinkDetails.id,
        parameter: "email",
        text: this.props.email,
        primary: setToPrimary
      }
    })
      .then(success => {
        alert(
          `Sucessfully linked ${this.props.email} to UID ${this.state.accountToLinkDetails.id}`
        );
        this.props.linkTicket(
          this.state.accountToLinkDetails.id,
          this.props.id,
          this.props.email
        );
        this.props.setModal(false);
      })
      .catch(error => alert(error.responseJSON.errors[0]));
  };

  linkAccount = () => {
    let comp = (
      <div>
        <h2>
          Link <b>{this.props.email}</b> to Existing User
        </h2>
        <div className="flex-row-container">
          <p>Find User Via</p>
          <select
            onChange={e =>
              this.setState({ accountToLinkMethod: e.target.value })
            }
          >
            <option value="ID">ID</option>
            <option value="Email">Email</option>
            <option value="Phone">Phone</option>
          </select>
        </div>
        <div className="flex-row-container">
          <input
            onChange={e => this.setState({ accountToLinkKey: e.target.value })}
          />
          <button
            onClick={() =>
              this.lookupAccountDetails(
                this.state.accountToLinkMethod,
                this.state.accountToLinkKey.toLowerCase()
              )
            }
          >
            Lookup
          </button>
        </div>
        {this.state.accountToLinkDetails ? (
          <div>
            <p>{`Name: ${this.state.accountToLinkDetails.first_name} ${this.state.accountToLinkDetails.last_name}`}</p>
            <p>{`Primary Email: ${this.state.accountToLinkDetails.email}`}</p>
            <div className="flex-row-container">
              <button onClick={this.linkEmailToAccount}>Confirm</button>
              <label>Set {this.props.email} to primary?</label>
              <input id="set-email-to-primary" type="checkbox" />
            </div>
          </div>
        ) : null}
      </div>
    );
    this.props.setModal(true);
    this.props.setModalComponent(comp);
  };

  render() {
    return (
      <div className="personal-info">
        <button className="ui-button" onClick={this.linkAccount}>
          Link Account
        </button>
        <p>
          <b>Email</b>: {this.props.email}
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  linkTicket: (userID, ticketID, email) =>
    dispatch(linkTicket(userID, ticketID, email))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkAccount);
