import { connect } from "react-redux";
import { withRouter } from "react-router";
import { search } from "../actions/search_actions";
import { changeLoading } from "../actions/loader_actions";
import Search from "./Search";

const mapDispatchToProps = dispatch => ({
  search: (parameter, text) => dispatch(search(parameter, text)),
  load: () => dispatch(changeLoading(true))
});

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(Search)
);
