import { merge } from "lodash";
import { RECEIVE_LOANS, RECEIVE_INFO } from "../actions/loan_actions";

const defaultProps = {};

const loansReducer = (oldState = defaultProps, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_LOANS:
      const newState = Object.assign({}, oldState, action.loans);
      return newState;
    case RECEIVE_INFO:
      return merge({}, oldState, action.info);
    default:
      return oldState;
  }
};

export default loansReducer;
