import { domain } from "../helpers/route_helper";
import { SESSION_COOKIE, readCookie} from "../helpers/cookie_helper";

export const ensureApiTokenPresence = (args) => {
  return {
    ...args,
    ...{token: readCookie(SESSION_COOKIE.API_TOKEN)}
  }
};

export const createUrlParamsForArgs = (args) => {
  let keyValParams = Object.keys(args).map((k) => {
    return `${k}=${args[k]}`;
  });
  return keyValParams.join('&');
}

export const asyncFetchPostRequest = async (url, args = {}, server = domain) => {
  return fetch(`${server}/${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(ensureApiTokenPresence(args)),
  });
};

export const asyncFetchPutRequest = async (url, args = {}, server = domain) => {
  return fetch(`${server}/${url}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(ensureApiTokenPresence(args)),
  });
};

export const asyncFetchPatchRequest = async (url, args = {}, server = domain) => {
  const response = await fetch(`${server}/${url}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(ensureApiTokenPresence(args)),
  });
  return await response.json();
};

export const asyncFetchGetRequest = async (url, args = {}, server = domain) => {
  let urlParams = createUrlParamsForArgs(ensureApiTokenPresence(args));
  const response = await fetch(`${server}/${url}?${urlParams}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await response.json();
};

export const asyncFetchDeleteRequest = async (url, args = {}, server = domain) => {
  let urlParams = createUrlParamsForArgs(ensureApiTokenPresence(args));
  const response = await fetch(`${server}/${url}?${urlParams}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await response.json();
};