let dom;

if (process.env.REACT_APP_STAGE  === 'dev') {
  dom = 'http://localhost';
} else if (window.location.host.includes("new-s-route")) {
  dom = 'https://fig-web.new-s-Route-1KOOBWZ3HNWNS-78065161.us-west-2.convox.site';
} else {
  dom = 'https://app.figloans.com';
}

export const domain = dom;