import React from "react";
import { formatJSONDate } from "../../../../helpers/date_helper";

const PaymentMethodInfo = ({ userInfo }) => {

  const bankAccount = () => {
    if (!userInfo.additional.bank_account.account) {
      return (
        <li>
          No bank account on file
        </li>
      )
    } else if (userInfo.additional.bank_account.dnp) {
      return (
        <li>
          Bank account ending in {userInfo.additional.bank_account.account} not presentable
        </li> 
      )
    } else {
      return (
        <div>
          <li>
            <span>Account number last 4:</span>{" "}
            {userInfo.additional.bank_account.account} 
          </li>
          <li>
            <span>Routing number:</span>{" "}
            {userInfo.additional.bank_account.routing}
          </li>
          <li>
            <span>Account added:</span>{" "}
            {formatJSONDate(userInfo.additional.bank_account.added)}
          </li>
        </div>
      )
    }

  };

  const debitCard = () => {
    if (!userInfo.additional.debit_card.number) {
      return (
        <li>
          No card on file
        </li>
      )
    } else if (userInfo.additional.debit_card.dnp) {
      return (
        <li>
          Debit card ending in {userInfo.additional.debit_card.number} not presentable
        </li> 
      )
    } else {
      return (
        <div>
          <li>
            <span>Card number last 4:</span>{" "}
            {userInfo.additional.debit_card.number} 
          </li> 
          <li>
            <span>Card added:</span>{" "}
            {formatJSONDate(userInfo.additional.debit_card.added)}
          </li> 
        </div>
      )
    }
  };

  return (
    <div>
      <span className="personal-info-accent">Bank Account:</span>{" "}
      {bankAccount()}
      <span className="personal-info-accent">Debit card:</span>{" "}
      {debitCard()}
    </div>
  )



}

export default PaymentMethodInfo;