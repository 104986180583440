import React, { useState, useEffect } from "react";
import { formatRawPhone } from "../helpers/phone_helper";
import { domain } from "../helpers/route_helper";
import { formatRawSSN } from "../helpers/ssn_helper";
import moment from "moment";

import NavBar from "./Navbar";

const loaderOptions = {
  lines: 13,
  length: 4,
  width: 2,
  radius: 6,
  scale: 1.0,
  corners: 1,
  color: "#41b29e",
  opacity: 0.25,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 0,
  shadow: false,
  hwaccel: false,
  position: "relative"
  // left: "10%"
};

const Acdv = ({}) => {
  const [acdv, setAcdv] = useState();
  const [user, setUser] = useState();
  const [count, setCount] = useState();
  const [responseVerification, setResponseVerification] = useState();
  const [acdvResponseCode, setAcdvResponseCode] = useState("01");
  const [loanId, setLoanId] = useState();
  const [hasImage, setHasImage] = useState();
  const [imageString, setImageString] = useState();
  const [skipList, setSkipList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!acdv && !user && !responseVerification) {
      fetchAcdv();
    }
  }, []);

  useEffect(() => {
    if (acdv == null) {
      fetchAcdv();
    }
  }, [acdv]);

  useEffect(() => {
    if (skipList.length > 0) {
      fetchAcdv();
    }
  }, [skipList]);

  const fetchAcdv = () => {
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/fcra/acdv_request`,
      data: { skip: skipList }
    }).then(payload => {
      setAcdv(payload.acdv);
      setUser(payload.user);
      setCount(payload.count);
      setResponseVerification(payload.responseVerification);
      setLoanId(payload.loan_id);
      setHasImage(payload.hasImage);
    });
  };

  const fetchImage = () => {
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/fcra/get_image`,
      data: {
        control_number: acdv.control_number
      }
    }).then(payload => {
      setImageString(payload.imageString);

      const link = document.createElement("a");
      link.href = `data:image/png;base64,${payload.imageString}`;
      link.download = `${acdv.control_number}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const skipAcdv = () => {
    setSkipList(skipList.concat([acdv.control_number]));
  };

  const submitAcdv = () => {
    let control_number = acdv.control_number;

    window.$.ajax({
      method: "POST",
      url: `${domain}/internal/api/fcra/acdv_response`,
      data: {
        control_number: control_number,
        response_verification: responseVerification,
        acdv_response_code: acdvResponseCode
      }
    }).then(payload => {
      setAcdv(null);
      setUser(null);
      setCount(null);
      setResponseVerification(null);
      setAcdvResponseCode("01");
      setLoanId(null);
      setHasImage(null);
      setImageString(null);
    });
  };

  const recordAndProcessAcdv = () => {
    setLoading("record_and_process_acdvs");
    window.$.ajax({
      method: "PATCH",
      url: `${domain}/internal/api/fcra/record_and_process_acdvs`
    }).then(
      payload => {
        setLoading(false);
        setAcdv(null);
        setUser(null);
        setCount(null);
        setResponseVerification(null);
        setAcdvResponseCode("01");
        setLoanId(null);
        setHasImage(null);
        setImageString(null);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const changeResponseVerification = (field, value) => {
    let tmpResponseVerification = { ...responseVerification };
    tmpResponseVerification[field] = value;

    setResponseVerification(tmpResponseVerification);
  };

  const _handleKeyDown = e => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  const acdvDisputeCodeDescriptions = {
    "001": "Not his/hers.",
    "002": "Belongs to another individual with same/similar name.",
    "101":
      "Not liable for account (i.e. account belongs to ex-spouse, business, etc)",
    "103": "Claims true identity fraud/account fraudulently opened."
  };

  const acdvResponseCodeDescriptions = {
    "01": "Account information accurate as of date reported",
    "03": "Delete account",
    "04": "Misrouted ACDV, please reroute",
    "07": "Delete due to fraud",
    "21": "Updated disputed account information only",
    "22":
      "Updated disputed account information. Additional account information was also updated",
    "23":
      "Disputed information accurate. Updated account information unrelated to the dispute"
  };

  return (
    <main>
      <NavBar />
      <div className="fcra-dashboard-wrapper">
        <i
          class={`fas fa-sync ${
            loading === "record_and_process_acdvs" ? "fa-spin" : null
          }`}
          onClick={recordAndProcessAcdv}
        ></i>
        {acdv && responseVerification ? (
          <div className="fcra-dashboard-container">
            {" "}
            <span className="fcra-dashboard-container">
              <span style={{ whiteSpace: "nowrap" }}>Loan ID: {loanId}</span>
              <span style={{ whiteSpace: "nowrap" }}>
                Dispute Code: {acdv.dispute_code}
              </span>
              <span style={{ whiteSpace: "nowrap" }}>
                {acdvDisputeCodeDescriptions[acdv.dispute_code]}
              </span>
            </span>
            <table className="fcra-dashboard-table">
              <tr className="fcra-dashboard-title-row">
                <td>
                  <br />
                  <br />
                  <h2>e-Oscar</h2>
                </td>
                <td style={{ paddingLeft: "10px" }}>
                  <br />
                  <br />
                  <h2>Fig</h2>
                </td>
                <td>
                  <br /> <br />
                  <h2>Status</h2>
                </td>
              </tr>
              <tr>
                <td>First Name</td>
                <td>{acdv.first_name}</td>
                <td style={{ paddingLeft: "10px" }}>{user.first_name}</td>
                <td>
                  <ACDVSelect
                    value={"SAME"}
                    onChange={e =>
                      changeResponseVerification(
                        "consumerFirstNameVerification",
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Middle Name</td>
                <td>{acdv.middle_name}</td>
                <td style={{ paddingLeft: "10px" }}>{user.middle_name}</td>
                <td>
                  <ACDVSelect
                    value={
                      responseVerification["consumerMiddleNameVerification"]
                    }
                    onChange={e =>
                      changeResponseVerification(
                        "consumerMiddleNameVerification",
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Last Name</td>
                <td>{acdv.last_name}</td>
                <td style={{ paddingLeft: "10px" }}>{user.last_name}</td>
                <td>
                  <ACDVSelect
                    value={responseVerification["consumerLastNameVerification"]}
                    onChange={e =>
                      changeResponseVerification(
                        "consumerLastNameVerification",
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>DOB</td>
                <td>
                  {moment(acdv.birthday, "YYYYMMDD").format("YYYY-MM-DD")}
                </td>
                <td style={{ paddingLeft: "10px" }}>{user.birthday}</td>
                <td>
                  <ACDVSelect
                    value={responseVerification["dobVerification"]}
                    onChange={e =>
                      changeResponseVerification(
                        "dobVerification",
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>SSN</td>
                <td>{formatRawSSN(acdv.ssn)}</td>
                <td style={{ paddingLeft: "10px" }}>
                  {formatRawSSN(user.ssn)}
                </td>
                <td>
                  <ACDVSelect
                    value={responseVerification["ssnVerification"]}
                    onChange={e =>
                      changeResponseVerification(
                        "ssnVerification",
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Phone #</td>
                <td>{formatRawPhone(acdv.phone_number)}</td>
                <td style={{ paddingLeft: "10px" }}>
                  {formatRawPhone(user.phone_number)}
                </td>
                <td>
                  <ACDVSelect
                    value={responseVerification["phoneNumberVerification"]}
                    onChange={e =>
                      changeResponseVerification(
                        "phoneNumberVerification",
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{acdv.address}</td>
                <td style={{ paddingLeft: "10px" }}>{user.address}</td>
                <td>
                  <ACDVSelect
                    value={responseVerification["addressVerification"]}
                    onChange={e =>
                      changeResponseVerification(
                        "addressVerification",
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
            </table>
            {hasImage ? (
              <button className="fcra-dashboard-submit" onClick={fetchImage}>
                Get Image
              </button>
            ) : null}
            <button className="fcra-dashboard-submit" onClick={skipAcdv}>
              Skip
            </button>
            <div>
              <button className="fcra-dashboard-submit" onClick={submitAcdv}>
                Submit
              </button>
              <select
                onChange={e => setAcdvResponseCode(e.target.value)}
                className="acdv-response-code-select"
              >
                <option value="01" selected>
                  01 : {acdvResponseCodeDescriptions["01"]}
                </option>
                <option value="03">
                  03 : {acdvResponseCodeDescriptions["03"]}
                </option>
                <option value="04">
                  04 : {acdvResponseCodeDescriptions["04"]}
                </option>
                <option value="07">
                  07 : {acdvResponseCodeDescriptions["07"]}
                </option>
                <option value="21">
                  21 : {acdvResponseCodeDescriptions["21"]}
                </option>
                <option value="22">
                  22 : {acdvResponseCodeDescriptions["22"]}
                </option>
                <option value="23">
                  23 : {acdvResponseCodeDescriptions["23"]}
                </option>
              </select>
            </div>
            <p className="fcra-dashboard-count">Count: {count}</p>
            {imageString ? (
              <img src={`data:image/png;base64,${imageString}`} />
            ) : null}
          </div>
        ) : (
          "No Data loaded"
        )}
      </div>
    </main>
  );
};

export default Acdv;

const ACDVSelect = ({ value, onChange }) => {
  return (
    <select
      className={value === "SAME" ? "same" : value === "DIFF" ? "diff" : "unkn"}
      value={value}
      onChange={onChange}
    >
      <option value="SAME">SAME</option>
      <option value="DIFF">DIFF</option>
      <option value="UNKN">UNKN</option>
    </select>
  );
};
