import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Ticket from "./Ticket";
import { clearTickets, getOpenTickets } from "../../actions/ticket_actions";
import { fetchMacros } from "../../actions/macro_actions";

const TicketStack = ({ openTickets, getOpenTickets, currentUser, fetchMacros, macros }) => {
  const [submitting, setSubmitting] = useState(null);
  const [ticketIds, setTicketIds] = useState([]);
  const dispatch = useDispatch();

  // check for change from offline to online
  // gets tickets if no tickets and fetches macros if no macros
  useEffect(() => {
    if (openTickets.length === 0 && currentUser.online) {
      getOpenTickets(10);
    }
    if (Object.keys(macros).length === 0 && currentUser.online) {
      fetchMacros();
    }
  }, [currentUser]);

  useEffect(() => {
    const ids = openTickets.filter((id) => id !== submitting);
    setTicketIds(ids);
  }, [openTickets]);

  // on dismount, clear tickets from state
  useEffect(() => {
    return () => {
      dispatch(clearTickets());
    };
  }, []);

  const handleSubmit = (id) => {
    setSubmitting(id);
    setTimeout(() => {
      if (submitting === id) setSubmitting(null);
    }, 3000);
  };

  // only render first two tickets
  const display = ticketIds.slice(0, 1).reverse();

  return (
    <div className="ticket-stack">
      {display.map((id, i) => (
        <Ticket key={i} id={id} submit={() => handleSubmit(id)} />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  openTickets: state.entities.tickets.openTickets,
  currentUser: state.session.currentUser,
  macros: state.entities.macros
});

const mapDispatchToProps = (dispatch) => ({
  getOpenTickets: (num) => dispatch(getOpenTickets(num)),
  fetchMacros: () => dispatch(fetchMacros())
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketStack);
