import React, { Component } from "react";
import { domain } from "../../helpers/route_helper";


class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null,
      file: null
    }
  }

  handleFileUpload = e => {
    this.setState({file: e.target.files[0]})
  }

  reset = () => this.setState({url: null, file: null})

  handleSubmit = () => {
    const blog = new FormData();

    blog.append("blog[image]", this.state.file)
    blog.append("blog[name]", this.state.file.name)
    window.$
      .ajax({
        method: "POST",
        url: `${domain}/api/blog/upload_image`,
        data: blog,
        processData: false,
        contentType: false,
        dataType: "json",
      })
      .then(res => {
        this.setState({
          url: res.url
        });
      });
  }


  render() {

    return (
        <div >
          <input
            type="file"
            onChange={this.handleFileUpload}
          />
          <br/>
          {this.state.url ?
            <div>
              <p>{this.state.url}</p>
              <button onClick={this.reset}>Upload Another</button>
            </div>
               :
              this.state.file ? <button onClick={this.handleSubmit}>Submit Image</button> : null
          }
        </div>
    )
  }
}

export default ImageUpload;
