import React, {useState} from "react";
import moment from "moment";
import {verifyIncomeForLoan} from "../../features/incomeVerification/incomeVerificationSlice";
import {useDispatch} from "react-redux";
import DebouncedButton from "../DebouncedButton";

const VerifyIncomeForm = ({ minPaystubDate, loanId, serverErrorMessages, userName }) => {

  const dispatch = useDispatch();

  // set max possible dates for the pay start and end dates to today
  const maxPaystubDate = moment(new Date()).format('YYYY-MM-DD');
  // set max possible date for the pay date itself - this can be in the future
  const maxPayDate = moment(new Date()).add(30, 'days').format('YYYY-MM-DD');

  const [grossEarnings, setGrossEarnings] = useState('');
  const [netPay, setNetPay] = useState('');
  const [payDate, setPayDate] = useState('');
  const [payStartDate, setPayStartDate] = useState('');
  const [payEndDate, setPayEndDate] = useState('');
  const [payFrequency, setPayFrequency] = useState('');
  const [nameCheck, setNameCheck] = useState(false);
  const [frontEndValidations, setFrontEndValidations] = useState(null);

  const submitVerifyIncomeForm = async() => {
    if (nameCheck) {
      setFrontEndValidations(null)
      const formData = {
        gross_earnings: grossEarnings,
        net_pay: netPay,
        pay_date: payDate,
        pay_start_date: payStartDate,
        pay_end_date: payEndDate,
        pay_frequency: payFrequency,
        loan_id: loanId
      };
      await dispatch(verifyIncomeForLoan(formData));
    } else {
      setFrontEndValidations(["Please confirm that the name on the documents matches the user\'s name. If the names do not match, please re-request documents."])
    }
  }

  const errorsComponent = () => {
    if (!frontEndValidations && !serverErrorMessages) {
      return null
    }

    return (
      <div className="server-errors">
        <p>{frontEndValidations || serverErrorMessages}</p>
      </div>
    )
  };

  return (
    <form className="income-verification-form">
      <h2>Verify Income</h2>
      {errorsComponent()}
      <div className="form-section">
        <p>Name check</p>
        <div className="form-label">
          <label htmlFor="user-name">User name: <b>{userName}</b></label>
        </div>
        <div className="form-label">
          <label htmlFor="name-matching">Does the name on the document(s) match the user's name?</label>
        </div>
        <div className="form-input">
            <input name="name-matching" id="name-matching" type="checkbox" onChange={() => setNameCheck(!nameCheck)} />
        </div>
        <div className="clear"/>
      </div>
      <div className="form-section">
        <p>Paystub date (must be dated on or after {minPaystubDate})</p>
        <div className="form-label">
          <label htmlFor="pay-date">Paystub date:</label>
        </div>
        <div className="form-input">
          <span>
            <input name="pay-date" id="pay-date" type="date" min={minPaystubDate} max={maxPayDate} value={payDate} onChange={e => setPayDate(e.target.value)}/>
          </span>
        </div>
        <div className="clear"/>
      </div>
      <div className="form-section">
        <p>Income amount (both fields required)</p>
        <div className="form-label">
          <label htmlFor="gross-earnings">Gross income amount:</label>
        </div>
        <div className="form-input">
          <span>
            <input name="gross-earnings" id="gross-earnings" type="number" placeholder="$" value={grossEarnings} onChange={e => setGrossEarnings(e.target.value)}/>
          </span>
        </div>
        <div className="clear"/>
        <div className="form-label">
          <label htmlFor="net-pay">Net income amount: </label>
        </div>
        <div className="form-input">
          <span>
            <input name="net-pay" id="net-pay" type="number" placeholder="$" value={netPay} onChange={e => setNetPay(e.target.value)}/>
          </span>
        </div>
        <div className="clear"/>
      </div>
      <div className="form-section">
        <p>Income frequency (either frequency OR start and end dates required)</p>
        <div className="form-label">
          <label htmlFor="pay-start-date">Pay start date:</label>
        </div>
        <div className="form-input">
          <span>
            <input name="pay-start-date" id="pay-start-date" type="date" max={maxPaystubDate} value={payStartDate} onChange={e => setPayStartDate(e.target.value)}/>
          </span>
        </div>
        <div className="clear"/>
        <div className="form-label">
          <label htmlFor="pay-end-date">Pay end date:</label>
        </div>
        <div className="form-input">
          <span>
            <input name="pay-end-date" id="pay-end-date" type="date" max={maxPaystubDate} value={payEndDate} onChange={e => setPayEndDate(e.target.value)}/>
          </span>
        </div>
        <div className="clear"/>
        <p>OR</p>
        <div className="form-label">
          <label htmlFor="pay-frequency">Income frequency:</label>
        </div>
        <div className="form-input">
          <span>
            <select name="pay-frequency" id="pay-frequency" value={payFrequency} onChange={e => setPayFrequency(e.target.value)}>
              <option value=''>--</option>
              <option>Monthly</option>
              <option>Semi-monthly</option>
              <option>Bi-weekly</option>
              <option>Weekly</option>
            </select>
          </span>
        </div>
        <div className="clear"/>
      </div>
      <div className="form-submit">
        <DebouncedButton className="ui-button" onClick={submitVerifyIncomeForm}>
          Submit
        </DebouncedButton>
      </div>
    </form>

  );
};

export default VerifyIncomeForm;
