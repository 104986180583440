import React from "react";
import Modal from "../../Modal";
import * as Spinner from "react-loader";
import { smallGreenSpinner } from "../../../styles/config/spinner_config";
import CloseableErrorMessage from "../../CloseableErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBankruptcyErrors,
  closeBankruptcyModal,
} from "../../../features/fcra/fcraSlice";
import {
  fileBankruptcy,
  dischargeBankruptcy
} from "../../../thunks/fcraThunks";
import { BankruptcyAction } from "../../../helpers/bk_processing_helper";

export default function BankruptcyActionModal({
  bankruptcyAction,
}) {
  const dispatch = useDispatch();

  const { loading, errors, open } = useSelector((state) => state.entities.fcra.bankruptcyProcessing);

  let modalHeader =
    bankruptcyAction === BankruptcyAction.FILE
      ? "File Bankruptcy"
      : "Discharge Bankruptcy";
  let bankruptcyDateType =
    bankruptcyAction === BankruptcyAction.FILE ? "File Date" : "Discharge Date";

  function handleBankruptcySubmit(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    const formData = {};
    for (const [key, value] of data.entries()) {
      formData[key] = value;
    }

    if (bankruptcyAction === BankruptcyAction.FILE) {
      dispatch(fileBankruptcy(formData));
    } else {
      dispatch(dischargeBankruptcy(formData));
    }
  }

  function clearErrors() {
    dispatch(clearBankruptcyErrors());
  }

  function closeModal() {
    dispatch(closeBankruptcyModal())
    clearErrors();
  }

  if (!open) {
    return (null)
  }

  return (
    <Modal method="post" closeAction={closeModal}>
      <h1>{modalHeader}</h1>
      <div>
        <form onSubmit={handleBankruptcySubmit}>
          {loading ? (
            <Spinner options={smallGreenSpinner} />
          ) : (
            <div>
              <br />
              <label htmlFor="loanId">Loan ID</label>
              <input id="loanId" type="number" name="loanId" required />
              <br />
              <label htmlFor="bankruptcyType">Bankruptcy Type</label>
              <select
                id="bankruptcyType"
                name="bankruptcyType"
                defaultValue="DEFAULT"
                required
              >
                <option value="DEFAULT" disabled>
                  Select an Option
                </option>
                <option value="7">7</option>
                <option value="13">13</option>
              </select>
              <br />
              <label htmlFor="bankruptcyCaseCode">Case Code</label>
              <input
                id="bankruptcyCaseCode"
                name="bankruptcyCaseCode"
                type="number"
                required
              />
              <br />
              <label htmlFor="bankruptcyEventDate">{bankruptcyDateType}</label>
              <input
                id="bankruptcyEventDate"
                name="bankruptcyEventDate"
                type="date"
                required
              />
              <br />
              <br />
              {errors && (
                <div>
                  <CloseableErrorMessage
                    error={errors}
                    closeAction={clearErrors}
                  />
                </div>
              )}
              <br></br>
              <button type="submit">Submit form</button>
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
}
