import React from "react"
import "../../../styles/css/fcraDashboard.css"

export const AccountHistory = ({ accountHistory }) => {
  const months = ['Dec', 'Nov', 'Oct', 'Sep', 'Aug', 'Jul', 'Jun', 'May', 'Apr', 'Mar', 'Feb', 'Jan'];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Prepend dashes to accountHistory to account for this month + future months that aren't included
  // in the given account history
  const futureMonthsCount = (currentYear === currentYear) ? (12 - currentMonth) : 0; 
  const prependedDashes = '-'.repeat(futureMonthsCount);
  const adjustedAccountHistory = prependedDashes + accountHistory;

  const renderMonthHeader = () => (
    <div className="header-row">
      <div className="header-cell"></div>
      {months.map(month => (
        <div key={month} className="header-cell">{month}</div>
      ))}
    </div>
  );

  const renderHistory = () => {
    const years = [];
    for (let i = 0; i < 7; i++) {
      const year = currentYear - i;
      const yearData = adjustedAccountHistory.slice(i * 12, (i + 1) * 12).split('');

      years.push(
        <div key={year} className="history-row">
          <div className="history-cell header-cell">{year}</div>
          {months.map((month, index) => {
            // Adjust index to match currentMonth (0 = Jan, 11 = Dec)
            const monthIndex = 11 - index;
            const isDisabled = (year === currentYear && monthIndex >= currentMonth) || year > currentYear;
            const value = yearData[index] || '-'; // Use '-' if no value is present
            return (
              <div key={month} className={`history-cell ${isDisabled ? 'disabled-cell' : ''}`}>
                {isDisabled ? '' : value}
              </div>
            );
          })}
        </div>
      );
    }
    return years;
  };

  return (
    <div className="account-history">
      <h2>
        <b>Account history</b>
      </h2>
      <div className="history-grid">
        {renderMonthHeader()}
        {renderHistory()}
      </div>
    </div>
  );
};