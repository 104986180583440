import React, { useEffect } from "react";
import { enableMfa, selectOtpUri, removeOtpUri } from "../features/sessions/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { Link } from "react-router-dom";
import CredentialForm from "./CredentialForm";

export default function EnableMfa() {
  const dispatch = useDispatch();

  useEffect(() => {

    return () => {
      dispatch(removeOtpUri());
    }
  }, [])

  const otpUri = useSelector(selectOtpUri);

  const handleSubmit = (e, formData) => {
    e.preventDefault();
    dispatch(enableMfa(formData));
  };

  return (
    <div className="login-page">
      <div className="login-page-header"> Fig Tech Employee Portal </div>
      <div className="login-form">
        <div className="login-form-content">
          {!otpUri ? (
            <div>
              <div className="title">Enable MFA</div>
              <p className="subtitle">
                <em>
                  Please enter your Fig email and password below. On the next
                  page, you'll receive a QR code. Scan the QR code with an
                  authentication app to generate your one-time password.
                </em>
              </p>
              <CredentialForm mfaFlow={true} submitAction={handleSubmit} />
            </div>
          ) : (
            <div>
              <p>
                Scan the QR code below into an authenticator app to generate a
                one-time password (OTP). Then, click the 'Back to sign in' link
                below to head back to the main sign-in page.
              </p>
              <br></br>
              <QRCodeSVG value={otpUri} />
              <br></br>
              <Link to="/login"> Back to sign in </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
