import { merge } from "lodash";
import { RECEIVE_MACROS, RECEIVE_MACRO } from "../actions/macro_actions";

const defaultProps = {};

const macrosReducer = (oldState = defaultProps, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_MACROS:
      return merge({}, oldState, action.macros);
    case RECEIVE_MACRO:
      return merge({}, oldState, action.macro);
    default:
      return oldState;
  }
};

export default macrosReducer;
