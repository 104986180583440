import React from "react";
import ImageRender from "./ImageRender";
import PDFRender from "./PDFRender";

const DocumentViewerAndSelector = ({ imageUrl, imageFileType }) => {

  const PDF_TYPE = 'application/pdf';

  return (
    <div>
      { imageFileType === PDF_TYPE ?
        (
          <PDFRender url={imageUrl} />
        ) : (
          <ImageRender url={imageUrl} />
        )
      }
    </div>
  );
};

export default DocumentViewerAndSelector;
