import React, { Component } from "react";

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = { merge: "", password: "" };
  }

  handleChange = field => {
    return e => {
      this.setState({ [field]: e.target.value });
    };
  };

  handleAdd = field => {
    return () => {
      let text = this.state[field];

      if (field === "phone") {
        text = text.split(/[\s()-]+/).join("");
      }
      this.props.addInfo(field, text, this.props.userId);
      this.setState({ [field]: "" });
    };
  };

  setHotlink = link => {
    const el = document.createElement("textarea");
    this.setState({ el });

    el.value = link;
    document.body.appendChild(el);
  };

  copyHotlink = () => {
    this.state.el.select();
    document.execCommand("copy");
    document.body.removeChild(this.state.el);
    this.setState({ el: null });
  };

  handleHotlink = () => {
    this.props.hotlink(this.props.userId, this.setHotlink);
  };

  render() {
    if (!this.props.userId) {
      return <div />;
    }

    // const deprecated = [
    //   <input
    //     type="text"
    //     value={this.state.email}
    //     placeholder="New Email"
    //     onChange={this.handleChange("email")}
    //   />,
    //   <button id="new-email-button" onClick={this.handleAdd("email")}>
    //     Add Email
    //   </button>,
    //   <br />,
    //   <input
    //     type="text"
    //     value={this.state.phone}
    //     placeholder="New Phone"
    //     onChange={this.handleChange("phone")}
    //   />,
    //   <button id="new-phone-button" onClick={this.handleAdd("phone")}>
    //     Add Phone
    //   </button>,
    //   <br />,
    //   <input
    //     type="text"
    //     value={this.state.comment}
    //     placeholder="Comment"
    //     onChange={this.handleChange("comment")}
    //   />,
    //   <button id="new-comment-button" onClick={this.handleAdd("comment")}>
    //     Add Comment
    //   </button>
    // ];
    return (
      <div className="user-actions">
        {this.props.errors.map((error, i) => (
          <li style={{ color: "red" }} key={i}>
            {error}
          </li>
        ))}
        {this.state.el ? (
          <button onClick={this.copyHotlink}>Copy Hotlink</button>
        ) : (
          <button onClick={this.handleHotlink}>Hotlink</button>
        )}
        <button onClick={this.handleAdd("verified")}>Verify</button>
        <br />
        <input
          type="text"
          value={this.state.password}
          placeholder="Enter new password"
          onChange={this.handleChange("password")}
        />
        <button onClick={this.handleAdd("password")}>Update Password</button>
        <br />
        <input
          type="text"
          value={this.state.merge}
          placeholder="Enter merging account"
          onChange={this.handleChange("merge")}
        />
        <button onClick={this.handleAdd("merge")}>Merge Account</button>
      </div>
    );
  }
}
