import React from "react";
import ReactDOM from "react-dom";
import "./styles/css/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Root from "./components/Root";
import { SESSION_COOKIE, readCookie } from "./helpers/cookie_helper";
// import registerServiceWorker from "./registerServiceWorker";

if (
  process.env.REACT_APP_STAGE !== "dev" &&
  window.location.protocol !== "https:"
)
  window.location.href =
    "https://internal-web.stagi-route-1l7tcdzxje8cm-1157069208.us-west-2.convox.site";

document.addEventListener("DOMContentLoaded", () => {
  const email = readCookie(SESSION_COOKIE.EMAIL);
  const apiToken = readCookie(SESSION_COOKIE.API_TOKEN);
  if (email && apiToken) {
    window.$.ajaxSetup({ data: { token: apiToken } });
  }

  ReactDOM.render(<Root />, document.getElementById("root"));
});
