import React, { Component } from "react";
import {connect} from 'react-redux'
import PropTypes from "prop-types";
import Navbar from "./Navbar_container";
import UserView from "./UserView";
import LoanView from "./LoanView_container";
import UserActions from "./UserActions_container";
import MessageOrigination from './MessageOrigination'
import "../styles/css/user_show.css";
import Loader from "./Loader";
import { fetchMacros } from "../actions/macro_actions";

class UserShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message:
        props.task && props.task.guesses[0]
          ? props.task.guesses[0].response
          : "",
      subject: '',
      sending: false
    };
  }

  componentDidMount() {
    if (!this.props.macros || Object.keys(this.props.macros).length == 0) {
      this.props.fetchMacros();
    }
  }

  checkMacro = (e, reply, setReply) => {
    const macroRegex = /(?:|^)#[A-Za-z0-9]+(?:|$)/;
    let snippet = reply.match(macroRegex);
    let replyCopy = reply;
    const macros = this.props.macros;

    if (e.key === "Enter" && snippet && macros[snippet[0].slice(1)]) {
      let {text} = macros[snippet[0].slice(1)];
      const newReply = replyCopy.replace(macroRegex, text);
      setReply(newReply);
    }
  };

  componentDidUpdate() {
    if (
      this.props.location.search &&
      this.props.location.pathname === "/user"
    ) {
      const search = this.props.location.search;
      const params = new URLSearchParams(search);

      for (let pair of params.entries()) {
        if (
          this.props.userInfo.id &&
          pair[0] === "ID" &&
          pair[1] !== this.props.userInfo.id.toString()
        ) {
          this.props.search(pair[0], pair[1]);
        }
      }
    }
  }
  render() {
    let userInfo, loans;
    ({ userInfo, loans } = this.props);
    return (
      <main>
        <Navbar />
        <Loader color="white">
          <div className="user-show">
            <div>
              <UserView userInfo={userInfo} loans={loans} />
              <UserActions userId={userInfo.id} />
                <div>
                  {this.state.sending ? (
                    <b>Processing Reply</b>
                  ) :
                   <MessageOrigination
                     checkMacro={this.checkMacro}
                     userId={userInfo.id}
                     setSending={(sending) => this.setState({sending})}
                     />
                 }
                </div>
            </div>
            <div className="loans">
              {loans
                .sort((a, b) => b.id - a.id)
                .map(loan => {
                  return (
                    <LoanView
                      key={loan.id}
                      loanInfo={loan}
                      userId={this.props.userInfo.id}
                    />
                  );
                })}
            </div>
          </div>
        </Loader>
      </main>
    );
  }
}

UserShow.propTypes = {
  userInfo: PropTypes.object,
  loans: PropTypes.array
};

const mapDispatchToProps = dispatch => ({
  fetchMacros: () => dispatch(fetchMacros())
})

export default connect(null ,mapDispatchToProps)(UserShow);
