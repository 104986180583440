import React, {useEffect, useState} from "react";
import Navbar from "../Navbar_container";
import {useDispatch, useSelector} from "react-redux";
import {
  createNewAdjustmentAndNotify,
  selectCreateAdjustmentErrors,
  selectCreateAdjustmentResults,
} from "../../features/creditReportAdjustments/creditReportAdjustmentsSlice";
import DebouncedButton from "../DebouncedButton";

const CreditReportAdjustments = () => {

  const dispatch = useDispatch();
  const createAdjustmentErrors = useSelector(selectCreateAdjustmentErrors);
  const createAdjustmentResults = useSelector(selectCreateAdjustmentResults);

  // React-controlled form inputs
  const [userId, setUserId] = useState('');
  const [loanId, setLoanId] = useState('');
  const [adjType, setAdjType] = useState('');
  const [reason, setReason] = useState('');

  // MD: Use this structure of handling value changes on inputs to avoid React warnings about 'controlled components'
  const onLoanIdChange = event => setLoanId(event.target.value);
  const onUserIdChange = event => setUserId(event.target.value);
  const onAdjTypeChange = event => setAdjType(event.target.value);
  const onReasonChange = event => setReason(event.target.value);

  const submitCreditReportAdjustmentsForm = async() => {
    const formData = {
      userId: userId,
      loanId: loanId,
      adjType: adjType,
      reason: reason,
      genAdjFile: !isAdjTypeDnr(adjType) // MD: If the adj type is DNR, we don't want to generate an adjustment file
    };
    await dispatch(createNewAdjustmentAndNotify(formData));
  }

  const isAdjTypeDnr = (adjType) => {
   return /(do_not_report)/g.test(adjType);
  }

  return (
    <div>
      <Navbar />
      <form className="credit-reporting-adjustments-form">
        <div className="grid-3-col-wrapper">

        <div className="grid-row-1 grid-col-3 grid-block-white">
          {createAdjustmentErrors &&
          <p className="grid-content-margin grid-text-error">{createAdjustmentErrors}</p>
          }
          {createAdjustmentResults && createAdjustmentResults.map((result) => {
              return (<p className="grid-content-margin">{result}</p>)
            })
          }
        </div>

          <div className="grid-row-1 grid-col-2 grid-block-white">
            <div style={{textAlign: 'left'}}>
              <h1 style={{fontSize: '1.5em'}}>Special Credit Report Adjustments</h1>
            </div>

            <p className="grid-content-margin">Please fill this out to request credit report deletions, resubmissions and do-not-reports. You must fill out ALL information on the account in question so that we have accurate tracking + can accurately act on the adjustment being made.</p>
          </div>
          <div className="grid-row-2 grid-col-2 grid-block-white">
            <div className="grid-content-margin">
              <label htmlFor="loan-id">Loan ID:</label>
            </div>
            <div className="grid-content-margin">
              <span>
                <input name="loan-id" id="loan-id" type="number" value={loanId} onChange={onLoanIdChange}/>
              </span>
            </div>
          </div>
          <div className="grid-row-3 grid-col-2 grid-block-white">
            <div className="grid-content-margin">
              <label htmlFor="user-id">User ID:</label>
            </div>
            <div className="grid-content-margin">
              <span>
                <input name="user-id" id="user-id" type="number" value={userId} onChange={onUserIdChange}/>
              </span>
            </div>
          </div>
          <div className="grid-row-4 grid-col-2 grid-block-white">
            <div className="grid-content-margin">
              <label htmlFor="adj-type">Adjustment Type (Please select):</label>
            </div>
            <div className="grid-content-margin">
              <span>
                <select name="adj-type" id="adj-type" value={adjType} onChange={onAdjTypeChange}>
                  <option value=''>--</option>
                  <option value='delete'>Delete</option>
                  <option value='resend'>Resend</option>
                  <option value='do_not_report'>Do Not Report</option>
                </select>
              </span>
            </div>
          </div>
          <div className="grid-row-5 grid-col-2 grid-block-white">
            <div className="grid-content-margin">
              <label htmlFor="adj-type">Reason (Optional):</label>
            </div>
            <div className="grid-content-margin">
              <span>
                <input name="hoobastank-the-reason" id="hoobastank-the-reason" type="text" value={reason} onChange={onReasonChange}/>
              </span>
            </div>
          </div>

          <div className="grid-row-6 grid-col-2">
            <div className="">
              <DebouncedButton onClick={submitCreditReportAdjustmentsForm}>
                Submit
              </DebouncedButton>
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default CreditReportAdjustments;