import { combineReducers } from "redux";
import user from "./users_reducer";
import loans from "./loans_reducer";
import tickets from "./tickets_reducer";
import identifications from "./identifications_reducer";
import macros from "./macros_reducer";
import incomeVerificationReducer from '../features/incomeVerification/incomeVerificationSlice'
import creditReportAdjustmentsReducer from '../features/creditReportAdjustments/creditReportAdjustmentsSlice'
import fcraReducer from "../features/fcra/fcraSlice";

const entitiesReducer = combineReducers({
  user,
  loans,
  identifications,
  macros,
  tickets,
  incomeVerification: incomeVerificationReducer,
  creditReportAdjustments: creditReportAdjustmentsReducer,
  fcra: fcraReducer
});

export default entitiesReducer;
