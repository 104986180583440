import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import { domain } from "../helpers/route_helper";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import Loader from "react-loader";

export default function TicketVisualizer(props) {
  const [data, setData] = useState(null);
  const [chartLoaded, setChartLoaded] = useState(false);
  const [metadata, setMetadata] = useState({});
  const [employeeList, setEmployeeList] = useState({});
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [employeeTicketData, setEmployeeTicketData] = useState({});
  const [totalNumberOfConversations, setTotalNumberOfConversations] = useState(
    "--"
  );
  const [totalInboundMessages, setTotalInboundMessages] = useState("--");
  const [totalOutboundMessages, setTotalOutboundMessages] = useState("--");
  const [averageFirstResponseTime, setAverageFirstResponseTime] = useState(
    "--"
  );
  const [
    adjAverageFirstResponseTime,
    setAdjAverageFirstResponseTime
  ] = useState("--");

  const [autoResponseData,setAutoResponseData] = useState({auto: [], noFollow: []});
  const [paymentChanges, setPaymentChanges] = useState("--");
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [dateRangeFocusedInput, setDateRangeFocusedInput] = useState();
  const [timerStart, setTimerStart] = useState(moment());

  useEffect(() => {
    metricFetch();
  }, []);

  useEffect(() => {
    fetchEmployeeList();
    fetchEmployeeTicketData();
    fetchTotalNumberOfConversations();
    fetchTotalInboundMessages();
    fetchTotalOutboundMessages();
    fetchAverageFirstResponseTime();
    fetchAdjAverageFirstResponseTime();
    fetchInquiryData();
    fetchPaymentChanges();
    fetchAutoResponseData();
  }, [timerStart]);

  useEffect(() => {
    d3.select(".chart-svg")
      .selectAll("g")
      .remove();
    d3.select(".chart-svg")
      .selectAll("text")
      .remove();
    d3.select(".dashboard-analytics-chart-container")
      .select("h1")
      .remove();
    d3.selectAll("path").remove();
    d3.selectAll("circle").remove();
    if (data && Object.keys(data).length > 0) {
      renderData();
      setChartLoaded(true);
    } else if (data !== null) {
      alert(`this inquiry returned no results`);
    }
  }, [data]);

  const resetOptions = () => {
    setSelectedEmployeeList([]);
  };

  const fetchEmployeeTicketData = () => {
    let employeeIdList = selectedEmployeeList.map(name => employeeList[name]);
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/employee_ticket_analytics`,
      data: {
        start_date: startDate.format("YYYY-MM-DD HH:mm:00"),
        end_date: endDate.format("YYYY-MM-DD HH:mm:00"),
        employees: employeeIdList
      }
    }).then(response => {
      setEmployeeTicketData(response.data);
      printTimeElapsed("employeeTicket");
      resetOptions();
    });
  };

  const fetchEmployeeList = () => {
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/employee_list`
    }).then(data => setEmployeeList(data));
  };

  const fetchTotalNumberOfConversations = () => {
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/total_number_of_conversations`,
      data: {
        start_date: startDate.format("YYYY-MM-DD HH:mm:00"),
        end_date: endDate.format("YYYY-MM-DD HH:mm:00")
      }
    }).then(response => {
      setTotalNumberOfConversations(response);
      printTimeElapsed("totalNumberOfConvos");
    });
    setTotalNumberOfConversations("loading...");
  };

  const fetchAverageFirstResponseTime = () => {
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/average_first_response_time`,
      data: {
        start_date: startDate.format("YYYY-MM-DD HH:mm:00"),
        end_date: endDate.format("YYYY-MM-DD HH:mm:00")
      }
    }).then(data => {
      setAverageFirstResponseTime(data);
      printTimeElapsed("avgFirstResponse");
    });
    setAverageFirstResponseTime("loading...");
  };

  const fetchAdjAverageFirstResponseTime = () => {
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/adjusted_average_first_response_time`,
      data: {
        start_date: startDate.format("YYYY-MM-DD HH:mm:00"),
        end_date: endDate.format("YYYY-MM-DD HH:mm:00")
      }
    }).then(data => {
      setAdjAverageFirstResponseTime(data);
      printTimeElapsed("adjAvgFirstResponse");
    });
    setAdjAverageFirstResponseTime("loading...");
  };

  const fetchInquiryData = (start = null, end = null) => {
    if (start) {
      setStartDate(start);
      start = start.format("YYYY-MM-DD HH:mm:00");
    }
    if (end) {
      setEndDate(end);
      end = end.format("YYYY-MM-DD HH:mm:00");
    }
    setChartLoaded(false);

    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/text_email_analytics`,
      data: {
        text: true,
        email: true,
        start_date: start ? start : startDate.format("YYYY-MM-DD HH:mm:00"),
        end_date: end ? end : endDate.format("YYYY-MM-DD HH:mm:00")
      }
    }).then(response => {
      setMetadata(response.metadata);
      setData(response.data);
      printTimeElapsed("inquiryData (Chart)");
      resetOptions();
    });
  };

  const fetchTotalOutboundMessages = () => {
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/total_outbound_messages`,
      data: {
        start_date: startDate.format("YYYY-MM-DD HH:mm:00"),
        end_date: endDate.format("YYYY-MM-DD HH:mm:00")
      }
    }).then(data => {
      setTotalOutboundMessages(data);
      printTimeElapsed("outboundMessages");
    });
    setTotalOutboundMessages("loading...");
  };

  const fetchTotalInboundMessages = () => {
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/total_inbound_messages`,
      data: {
        start_date: startDate.format("YYYY-MM-DD HH:mm:00"),
        end_date: endDate.format("YYYY-MM-DD HH:mm:00")
      }
    }).then(data => {
      setTotalInboundMessages(data);
      printTimeElapsed("inboundMessages");
    });
    setTotalInboundMessages("loading...");
  };

  const fetchPaymentChanges = () => {
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/update_payment_analytics`,
      data: {
        start_date: startDate.format("YYYY-MM-DD HH:mm:00"),
        end_date: endDate.format("YYYY-MM-DD HH:mm:00")
      }
    }).then(data => {
      setPaymentChanges(data.data);
      printTimeElapsed("paymentChanges");
    });
    setPaymentChanges("loading...");
  };

  const fetchAutoResponseData = () => {
    window.$.ajax({
      method: "GET",
      url: `${domain}/internal/api/auto_response_analytics`,
      data: {
        start_date: startDate.format("YYYY-MM-DD HH:mm:00"),
        end_date: endDate.format("YYYY-MM-DD HH:mm:00")
      }
    }).then(payload => {
      setAutoResponseData(payload.data);
    });
  }

  const printTimeElapsed = fetchName => {
    console.log(`${fetchName}: ${moment().diff(timerStart)} ms`);
  };

  const metricFetch = () => {
    if (startDate <= endDate) {
      // setting timer
      setTimerStart(moment());
    }
  };

  const maxYVal = () => {
    let max = 0;
    Object.keys(data).forEach(key => {
      max = Math.max(max, data[key].Texts + data[key].Emails);
    });
    return max;
  };

  var options = {
    lines: 13,
    length: 5,
    width: 2,
    radius: 2,
    scale: 1.0,
    corners: 1,
    color: "#41b29e",
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: "10px",
    left: "90%",
    shadow: false,
    hwaccel: false,
    position: "relative"
  };

  var chartSpinnerOptions = {
    lines: 13,
    length: 20,
    width: 10,
    radius: 30,
    scale: 0.5,
    corners: 1,
    color: "#41b29e",
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: "50vh",
    left: "40vw",
    shadow: false,
    hwaccel: false,
    position: "absolute"
  };

  const renderData = () => {
    const yMax = maxYVal();
    var chartSVG = d3.select(".chart-svg");
    const margin = { top: 20, right: 50, bottom: 20, left: 50 };
    const height = parseInt(chartSVG.style("height"));
    const width = parseInt(chartSVG.style("width"));
    var yScale = d3
      .scaleLinear()
      .domain([0, yMax * 1.2])
      .range([height - margin.top, margin.bottom + 50]);
    let domainStart = new Date(parseInt(Object.keys(data)[0]));
    let domainEnd = new Date(
      parseInt(Object.keys(data)[Object.keys(data).length - 1])
    );
    var xScale = d3
      .scaleTime()
      .domain([domainStart, domainEnd])
      .rangeRound([margin.left, width - margin.right]);

    var line = d3.line().curve(d3.curveMonotoneX);
    let totalPoints = [];
    // Object.keys(data).forEach( val => emailPoints = [...emailPoints, [xScale(new Date(parseInt(val))), yScale(data[val]['Emails']), 'Emails', val]])
    // Object.keys(data).forEach( val => textPoints = [...textPoints, [xScale(new Date(parseInt(val))), yScale(data[val]['Texts']), 'Texts', val]])
    Object.keys(data).forEach(
      val =>
        (totalPoints = [
          ...totalPoints,
          [
            xScale(new Date(parseInt(val))),
            yScale(data[val]["Texts"] + data[val]["Emails"]),
            "Texts + Emails",
            val
          ]
        ])
    );

    // chartSVG.append("path")
    // .attr("d", line(emailPoints))
    // .attr('fill', 'none')
    // .attr('stroke', 'black')
    // .attr("transform", `translate(${25}, ${0})`)
    //
    // chartSVG.append("path")
    // .attr("d", line(textPoints))
    // .attr('fill', 'none')
    // .attr('stroke', 'orange')
    // .attr("transform", `translate(${25}, ${0})`)

    chartSVG
      .append("path")
      .attr("d", line(totalPoints))
      .attr("fill", "none")
      .attr("stroke", "#41b29e")
      .attr("stroke-width", 5)
      .attr("transform", `translate(${25}, ${0})`);

    chartSVG
      .selectAll("circle")
      .data([...totalPoints])
      .enter()
      .append("circle")
      .attr("cx", d => d[0] + 25)
      .attr("cy", d => d[1])
      .attr("r", 5)
      .attr("fill", "#324d5d")
      .attr("class", "chart-circle")
      .on("mouseover", function(d, i) {
        const messageType = d[2];
        const numOfMessages =
          messageType === "Texts + Emails"
            ? data[d[3]]["Emails"] + data[d[3]]["Texts"]
            : data[d[3]][messageType];
        const xAdjust = width - d[0] < 220 ? 220 : -10;
        const yAdjust = height - d[1] < 110 ? 110 : -10;
        let xPosInfo = parseInt(this.getAttribute("cx")) - xAdjust;
        let yPosInfo = parseInt(this.getAttribute("cy")) - yAdjust;
        let tooltip = d3
          .select(".chart-svg")
          .append("g")
          .attr("class", "tooltip")
          .attr("transform", `translate(${xPosInfo}, ${yPosInfo})`);
        tooltip
          .append("rect")
          .attr("x", 2)
          .attr("y", 2)
          .attr("width", 190)
          .attr("height", 100)
          .attr("fill", "black")
          .attr("opacity", 0.4)
          .attr("rx", 2)
          .attr("ry", 2);
        tooltip
          .append("rect")
          .attr("width", 190)
          .attr("height", 100)
          .attr("fill", "white")
          .attr("rx", 2)
          .attr("ry", 2);
        tooltip
          .append("text")
          .attr("x", 4)
          .attr("y", 18.5)
          .text(`Total: ${numOfMessages}`);
        tooltip
          .append("text")
          .attr("x", 4)
          .attr("y", 43.5)
          .text(`E: ${data[d[3]]["Emails"]}`);
        tooltip
          .append("text")
          .attr("x", 65)
          .attr("y", 43.5)
          .text(`T: ${data[d[3]]["Texts"]}`);
        tooltip
          .append("text")
          .attr("x", 4)
          .attr("y", 68.5)
          .text(
            `Start: ${moment(parseInt(d[3]))
              .add("seconds", -parseInt(metadata["time_period"]))
              .format("MM/DD/YY h:mm a")}`
          );
        tooltip
          .append("text")
          .attr("x", 4)
          .attr("y", 93.5)
          .text(`End: ${moment(parseInt(d[3])).format("MM/DD/YY h:mm a")}`);
        d3.select(this)
          .attr("fill", "#41b29e")
          .attr("r", 10);
      })
      .on("mouseout", function(d) {
        d3.select(".tooltip").remove();
        d3.select(this)
          .attr("fill", "#324d5d")
          .attr("r", 5);
      });

    const tickValues = Object.keys(data).map(val => new Date(parseInt(val)));
    if (metadata["time_period"] / 60 / 60 / 24 >= 30) {
      chartSVG
        .append("g")
        .attr("transform", `translate(25,${height - margin.bottom})`)
        .call(
          d3
            .axisBottom()
            .scale(xScale)
            .tickValues(tickValues)
            .tickFormat(d3.timeFormat("%b '%y"))
        );
    } else {
      chartSVG
        .append("g")
        .attr("transform", `translate(25,${height - margin.bottom})`)
        .call(
          d3
            .axisBottom()
            .scale(xScale)
            .tickValues(tickValues)
        );
    }
    chartSVG
      .append("g")
      .attr("transform", `translate(${margin.left + 15}, 0)`)
      .call(d3.axisLeft(yScale));
    chartSVG
      .append("text")
      .attr("transform", `rotate(${-90})`)
      .attr("y", 10)
      .attr("x", 0 - height / 2)
      .style("text-anchor", "middle")
      .text("Inbound Messages");
    let legend = chartSVG.append("g");
    legend
      .append("rect")
      .attr("width", 80)
      .attr("height", 40)
      .attr("fill", "white");
    // legend.append('circle').attr('cx', 10).attr('cy', 10).attr('r', 5).attr('fill', 'black')
    // legend.append('text').attr('x', 20).attr('y', 15).text('Email')
    // legend.append('circle').attr('cx', 10).attr('cy', 30).attr('r', 5).attr('fill', 'orange')
    // legend.append('text').attr('x', 20).attr('y', 35).text('Text')
    // legend.append('circle').attr('cx', 20).attr('cy', 20).attr('r', 5).attr('fill', 'blue')
    legend
      .append("text")
      .attr("x", margin.left + 15)
      .attr("y", 50)
      .text("Total Inbound Messages")
      .attr("class", "chart-title")
      .attr("color");
  };

  const employeeTableData = Object.keys(employeeTicketData).map(
    (key, index) => {
      if (employeeTicketData[key]["count"] <= 0) return null;
      return (
        <tr key={index}>
          <td className="employee-id">{key}</td>
          <td>{employeeTicketData[key]["count"]}</td>
          <td>{`${Math.round(
            employeeTicketData[key]["count"] / employeeTicketData[key]["time"]
          )}/hr`}</td>
        </tr>
      )
    }
  ).filter(Boolean);

  return (
    <div className="ticket-viz-container">
      <DateRangePicker
        startDate={startDate} // momentPropTypes.momentObj or null,
        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
        endDate={endDate} // momentPropTypes.momentObj or null,
        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) => {
          if (startDate) {
            setStartDate(startDate);
          }
          if (endDate) {
            setEndDate(endDate);
          }
        }} // PropTypes.func.isRequired,
        focusedInput={dateRangeFocusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={focusedInput => {
          setDateRangeFocusedInput(focusedInput);
        }} // PropTypes.func.isRequired,
        isOutsideRange={day => day > moment()}
      />
      <button
        onClick={() => {
          metricFetch();
        }}
      >
        Submit{" "}
      </button>
      <div>
        <button
          onClick={() => {
            setEndDate(startDate);
          }}
        >
          End = Start
        </button>
        <button
          onClick={() => {
            setStartDate(moment());
            setEndDate(moment());
          }}
        >
          Today
        </button>
        <button
          onClick={() => {
            setStartDate(moment().add(-1, "day"));
            setEndDate(moment());
          }}
        >
          Past 24hrs
        </button>
        <button
          onClick={() => {
            setStartDate(moment().add(-1, "week"));
            setEndDate(moment());
          }}
        >
          Past Week
        </button>
        <button
          onClick={() => {
            setStartDate(moment().add(-1, "month"));
            setEndDate(moment());
          }}
        >
          Past Month
        </button>
        <button
          onClick={() => {
            setStartDate(moment().add(-1, "year"));
            setEndDate(moment());
          }}
        >
          Past Year
        </button>
      </div>
      <div className="dashboard-analytics-chart-container">
        <Loader loaded={chartLoaded} options={chartSpinnerOptions}></Loader>
        <svg className="chart-svg"></svg>
        <div className="ticket-viz-chart-options-and-summary-stats-container">
          <table>
            <thead>
              <tr>
                <td>
                  <u>Employee</u>
                </td>
                <td>
                  <u>Total Messages Sent</u>
                </td>
                <td>
                  <u>Avg Messages/hr</u>
                </td>
              </tr>
            </thead>
            <tbody>{employeeTableData}</tbody>
          </table>
          <span className="seperator"></span>
          <table className="general-ticket-stats">
            <tbody>
              <tr>
                <td className="long-col">Total Conversations</td>
                <td>
                  <Loader
                    loaded={totalNumberOfConversations !== "loading..."}
                    options={options}
                  >
                    {totalNumberOfConversations}
                  </Loader>
                </td>
              </tr>
              <tr>
                <td className="long-col">Total Inbound Messages</td>
                <td>
                  <Loader
                    loaded={totalInboundMessages !== "loading..."}
                    options={options}
                  >
                    {totalInboundMessages}
                  </Loader>
                </td>
              </tr>
              <tr>
                <td className="long-col">Avg. Inbound Messages/Conversation</td>
                <td>
                  <Loader
                    loaded={
                      totalInboundMessages !== "loading..." &&
                      totalNumberOfConversations !== "loading..."
                    }
                    options={options}
                  >
                    {Math.round(
                      totalInboundMessages / totalNumberOfConversations
                    )}
                  </Loader>
                </td>
              </tr>
              <tr>
                <td className="long-col">Total Outbound Messages</td>
                <td>
                  <Loader
                    loaded={totalOutboundMessages !== "loading..."}
                    options={options}
                  >
                    {totalOutboundMessages}
                  </Loader>
                </td>
              </tr>
              <tr>
                <td className="long-col">Avg. First Response Time (hrs)</td>
                <td>
                  <Loader
                    loaded={averageFirstResponseTime !== "loading..."}
                    options={options}
                  >
                    {averageFirstResponseTime}
                  </Loader>
                </td>
              </tr>
              <tr>
                <td className="long-col">
                  Adjusted Avg. First Response Time (hrs)
                </td>
                <td>
                  <Loader
                    loaded={adjAverageFirstResponseTime !== "loading..."}
                    options={options}
                  >
                    {adjAverageFirstResponseTime}
                  </Loader>
                </td>
              </tr>
              <tr>
                <td className="long-col">
                  % of Autoresponses
                </td>
                <td style={{ width: "250px" }}>
                  <Loader
                    loaded={autoResponseData.auto !== "loading..."}
                    options={options}
                  >
                    {Object.values(autoResponseData.auto).reduce((a,b) => a + b, 0)} => ({Math.round(Object.values(autoResponseData.auto).reduce((a,b) => a + b, 0) * 100 / autoResponseData.total)}%)
                  </Loader>
                </td>
              </tr>
              <tr>
                <td className="long-col">
                  % of Autoresponses (no followup)
                </td>
                <td style={{ width: "250px" }}>
                  <Loader
                    loaded={autoResponseData.noFollow !== "loading..."}
                    options={options}
                  >
                    {Object.values(autoResponseData.noFollow).reduce((a,b) => a + b, 0)} => ({Math.round(Object.values(autoResponseData.noFollow).reduce((a,b) => a + b, 0) * 100 / autoResponseData.total)}%)
                  </Loader>
                </td>
              </tr>
              <tr>
                <td className="long-col">
                  Specific AutoResponse
                </td>
              </tr>
              <Loader
                loaded={autoResponseData.noFollow !== "loading..."}
                options={options}
              >
                {Object.keys(autoResponseData.auto).map(res => <tr><td className="long-col">{Math.round(autoResponseData.noFollow[res] * 100 / autoResponseData.auto[res])}%</td> <td>{res.slice(0,100)}</td></tr>)}
              </Loader>
            </tbody>
          </table>
          <span className="seperator"></span>
          <table>
            <tbody>
              <tr>
                <td className="long-col">User Initiated Payment Changes</td>
                <td style={{ width: "250px" }}>
                  <Loader
                    loaded={paymentChanges !== "loading..."}
                    options={options}
                  >
                    {`${paymentChanges.user} => (${Math.round(
                      (paymentChanges.user / paymentChanges.total) * 100,
                      2
                    )}%)`}
                  </Loader>
                </td>
              </tr>
              <tr>
                <td className="long-col">CS Initiated Payment Changes</td>
                <td style={{ width: "250px" }}>
                  <Loader
                    loaded={paymentChanges !== "loading..."}
                    options={options}
                  >
                    {`${paymentChanges.cs} => (${Math.round(
                      (paymentChanges.cs / paymentChanges.total) * 100,
                      2
                    )}%)`}
                  </Loader>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
