import React from "react";
import TicketStack from "./TicketStack";
import Archive from "./Archive";
import Online from "./Online";
import Escalated from "./Escalated";
import TicketStats from "./TicketStats";
import { onlineStatus } from "../../features/sessions/sessionSlice";
import { useSelector } from "react-redux";

const Main = () => {

  const online = useSelector(onlineStatus);

  return (
    <>
      <div className="controls">
        <Online />
        <TicketStats />
        <Archive />
        <Escalated />
      </div>
      <div className="main-grid">
        <div className="ticket-container">
          {online ? (
            <TicketStack />
          ) : (
            <div className="ticket-stack">
              <div className="ticket" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Main;
