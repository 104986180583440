import React, { useState, useEffect } from "react";
import {
  resetErrors,
  selectPendingStatus,
  selectSessionErrors,
} from "../features/sessions/sessionSlice";
import CloseableErrorMessage from "./CloseableErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "react-loader";

export default function CredentialForm({ mfaFlow, submitAction }) {
  const dispatch = useDispatch();

  // on first load, reset any outstanding errors. used to reset errors when switching back and forth between the
  // sign in form and the MFA form after errors have been returned (for either) since state persists otherwise
  useEffect(() => {
    dispatch(resetErrors());
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");

  const formData = {
    email: email,
    password: password,
    otp: otp,
  };

  const pendingStatus = useSelector(selectPendingStatus);
  const sessionErrors = useSelector(selectSessionErrors);

  const closeErrorMessage = () => {
    dispatch(resetErrors());
  };

  const loaderOptions = {
    color: "#41b29e",
  };

  if (pendingStatus) {
    return (
      <div className="login-pending-container">
        <Loader options={loaderOptions} loaded={false} />
        <div className="loading-title"> Loading </div>
      </div>
    );
  }

  return (
    <div>
      <CloseableErrorMessage
        error={sessionErrors}
        closeAction={closeErrorMessage}
      />
      <form>
        <input
          id="typed"
          className="session-input-email"
          name="email"
          type="text"
          placeholder="Email Address"
          autoComplete="on"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="session-input-password"
          name="password"
          type="password"
          placeholder="Password"
          autoComplete="on"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {!mfaFlow ? (
          <div>
            <input
              className="session-input-otp"
              name="otp"
              type="number"
              placeholder="OTP"
              autoComplete="off"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <br></br>
            <Link to="/enable-mfa"> Enable MFA </Link>
            <br></br>
          </div>
        ) : (
          <div>
            <Link to="/login"> Back to sign in </Link>
          </div>
        )}
        <button className="submit-button" onClick={(e) => submitAction(e, formData)}>
          {!mfaFlow ? "Sign in" : "Get QR code"}
        </button>
      </form>
    </div>
  );
}
