import { connect } from "react-redux";
import { addInfo, hotlink } from "../actions/user_actions";
import UserActions from "./UserActions";

const mapStateToProps = state => ({
  errors: Object.values(state.errors.edit)
});

const mapDispatchToProps = dispatch => ({
  addInfo: (parameter, text, userId) =>
    dispatch(addInfo(parameter, text, userId)),
  hotlink: (userId, callback) => dispatch(hotlink(userId, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserActions);
