import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { formatRawPhone } from "../helpers/phone_helper";
import { formatJSONDate } from "../helpers/date_helper";
import "../styles/css/user.css";
import { Link } from "react-router-dom";
import { fetchUserEmails } from "../util/user_api_util";
import Editable from "./Editable";
import Modal from "./Modal";
import AdvancedServiceFunctions from "./AdvancedServiceFunctions";
import SendAccountStatusModal from "./SendAccountStatusModal";
import Toggle from "./Toggle";

const copyString = string => {
  return () => {
    const el = document.createElement("textarea");
    el.value = string;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
};

const validatePhone = phone => {
  const onlyNums = /^[0-9]*$/;
  return phone.length === 10 && onlyNums.test(phone);
};

const validateEmail = email => {
  var re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return re.test(String(email).toLowerCase());
};

const UserView = ({ userInfo, loans }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalComponent, setModalComponent] = useState(null);
  const [openAccountStatus, setOpenAccountStatus] = useState(false);
  const [selectedLoanId, setSelectedLoanId] = useState(
    loans && loans.length > 0 ? loans[0].id : null
  );

  useEffect(() => {
    setModalComponent(
      <AdvancedServiceFunctions
        close={() => {
          setShowModal(false);
          setModalComponent(null);
        }}
        id={userInfo.id}
        loans={loans}
        selectedLoanId={selectedLoanId}
        setSelectedLoanId={setSelectedLoanId}
      />
    );
  }, [selectedLoanId]);

  const closeModal = () => {
    setShowModal(false);
    setModalComponent(null);
  };

  if (!userInfo.id) {
    return <div id="user-info">No User Info To Show</div>;
  }

  let id,
    first_name,
    last_name,
    verified,
    middle_initial,
    street,
    city,
    state,
    zip,
    phone,
    email,
    birthday,
    comments,
    plaid,
    ibv,
    account,
    debitCard,
    dnp,
    dnl,
    dnc,
    utm_source,
    utm_content,
    referrees,
    referral_code,
    cycle,
    user_credit,
    inboundTexts,
    outboundTexts,
    inboundEmails,
    outboundEmails,
    bounces,
    paymentDays,
    surveyResponses;
  ({
    id,
    first_name,
    verified,
    middle_initial,
    last_name,
    state,
    street,
    city,
    zip,
    phone,
    email,
    birthday,
    comments,
    plaid,
    ibv,
    account,
    debitCard,
    dnp,
    dnl,
    dnc,
    utm_source,
    utm_content,
    referrees,
    referral_code,
    cycle,
    user_credit,
    inboundTexts,
    outboundTexts,
    inboundEmails,
    outboundEmails,
    bounces,
    paymentDays,
    surveyResponses
  } = userInfo);
  const messages = [];

  const displayEmails = async () => {
    let emails = [];
    await fetchUserEmails(id)
      .then(response => (emails = response.data))
      .catch(error => alert(error));
    let emailComp = emails.map(val =>
      val === email ? (
        <p>
          <b>{val}</b>
        </p>
      ) : (
        <p>{val}</p>
      )
    );
    const comp = (
      <div>
        <h1>
          <u>List of Emails for User {id}</u>
        </h1>
        {emailComp}
      </div>
    );
    setModalComponent(comp);
    setShowModal(true);
  };

  for (let i = 0; i < inboundTexts.length; i++) {
    messages.push({ type: "text", object: inboundTexts[i], in: true });
  }
  for (let i = 0; i < outboundTexts.length; i++) {
    messages.push({ type: "text", object: outboundTexts[i], in: false });
  }
  for (let i = 0; i < inboundEmails.length; i++) {
    messages.push({ type: "email", object: inboundEmails[i], in: true });
  }
  for (let i = 0; i < outboundEmails.length; i++) {
    let outboundEmail = outboundEmails[i];
    if (
      outboundEmail.body.startsWith(
        "Thank you so much for reaching out to Fig Loans’ Customer Service. We hope you and yours are well."
      )
    ) {
      outboundEmail.body = "Inquiry Autoresponse Sent";
    }

    messages.push({ type: "email", object: outboundEmail, in: false });
  }

  messages.sort(
    (a, b) => new Date(b.object.created_at) - new Date(a.object.created_at)
  );

  let verifiedDisplay;
  switch (verified) {
    case -5:
      verifiedDisplay = (
        <li style={{ color: "orange" }}>Documents Received / Pending Review</li>
      );
      break;
    case -2:
      verifiedDisplay = <li style={{ color: "red" }}>DL-SSN Not Verified</li>;
      break;
    case -1:
      verifiedDisplay = <li style={{ color: "red" }}>DL Not Verified</li>;
      break;
    case 1:
      verifiedDisplay = <li style={{ color: "green" }}>DL Verified</li>;
      break;
    case 2:
      verifiedDisplay = <li style={{ color: "green" }}>DL-SSN Verified</li>;
      break;
    default:
      verifiedDisplay = null;
      break;
  }

  return (
    <div id="user-info">
      {showModal ? (
        <Modal closeAction={closeModal}>{modalComponent}</Modal>
      ) : null}
      {openAccountStatus && 
        <SendAccountStatusModal
          closeMethod={() => setOpenAccountStatus(false)}
        />
      }
      <h3>User Info </h3>
      <ul>
        <li className={dnl === 1 ? "dnp" : null}>
          {" "}
          ID: <Link to={`/user?ID=${id}`}>{id}</Link>{" "}
          <button
            onClick={() => {
              setModalComponent(
                <AdvancedServiceFunctions
                  close={closeModal}
                  id={id}
                  loans={loans}
                  selectedLoanId={selectedLoanId}
                  setSelectedLoanId={setSelectedLoanId}
                />
              );
              setShowModal(true);
            }}
          >
            Advanced
          </button>
          <button onClick={() => setOpenAccountStatus(true)}>
            Send account status
          </button>
        </li>
        {bounces && bounces.length > 0 ? (
          <li className="underwriting"> Bounces: {bounces.join(" ")}</li>
        ) : null}
        {paymentDays ? (
          <li className="underwriting">{paymentDays} days delayed </li>
        ) : null}
        {verifiedDisplay}
        <li> Cycle: {cycle ? cycle : "unknown"} </li>
        <li>UTM: {utm_source}</li>
        {utm_source === "refer" ? (
          <li>
            Referred By:{" "}
            <Link to={`user?ID=${utm_content}`}>{utm_content}</Link>
          </li>
        ) : null}
        <li>
          {" "}
          Name:{" "}
          <Editable
            parameter="first_name"
            type="user"
            id={id}
            text={first_name}
          />{" "}
          {middle_initial
            ? [
                <Editable
                  parameter="middle_initial"
                  type="user"
                  id={id}
                  text={middle_initial}
                />,
                " "
              ]
            : null}
          <Editable
            parameter="last_name"
            type="user"
            id={id}
            text={last_name}
          />{" "}
        </li>
        <div class="toggle-container-user">
          <div class="toggle-div-user">
              <Toggle
                userInfo={userInfo}
                type="dnc">
              </Toggle>
            </div>
            <div class="toggle-div-user">
              <Toggle
                userInfo={userInfo}
                type="dnl">
              </Toggle>
            </div>
        </div>
        <li>
          {" "}
          Location: {street} {city} {state} {zip}{" "}
        </li>
        <li className={dnc ? "dnp" : null}>
          <span className="link" onClick={displayEmails}>
            Email
          </span>
          :{" "}
          <Editable
            parameter="email"
            type="user"
            id={id}
            text={email}
            validation={validateEmail}
          />{" "}
        </li>
        <li className={dnc ? "dnp" : null}>
          {" "}
          Phone:{" "}
          <Editable
            parameter="phone"
            type="user"
            validation={validatePhone}
            id={id}
            text={phone ? formatRawPhone(phone) : null}
          />{" "}
        </li>
        <li>
          {" "}
          Birthday:{" "}
          <Editable
            parameter="birthday"
            type="user"
            id={id}
            text={birthday ? formatJSONDate(birthday) : null}
          />{" "}
        </li>
        <li>
          {" "}
          Comments: <br />
          <div className="comments-box">
            {comments.map((c, i) => (
              <p key={i}>
                {new Date(formatJSONDate(c.created_at)).toLocaleString(
                  "en-us",
                  {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                    timeZone: "UTC"
                  }
                )}{" "}
                - {c.comment}
              </p>
            ))}
            <Editable parameter="comment" type="user" id={id} text={"[NEW]"} />{" "}
          </div>
        </li>
        <li> Plaid: {plaid ? `${plaid}` : "0"} </li>
        <li> IBV: {ibv ? `${ibv}` : "0"} </li>
        <li className={dnp ? "dnp" : null}> Bank Account: {account} </li>
        {debitCard ? <li> Debit Card: {debitCard.lastFour} </li> : null}
        <li>
          Referrals:{" "}
          {referrees.map(id => [<Link to={`/user?ID=${id}`}>{id}</Link>, " "])}
        </li>
        <li>User Credit: ${user_credit}</li>
        <li>
          Surveys:{" "}
          {surveyResponses.map(path => [
            <a
              href={`#/survey/${path}/${id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {path}
            </a>,
            ", "
          ])}
        </li>
      </ul>
      <div>
        <h4>Recent Messages</h4>
        <ul className="recent-messages">
          {messages.map((m, i) => (
            <li className={m.in ? "inbound" : "outbound"} key={i}>
              {m.type[0]} -{" "}
              {new Date(
                m.object.processed || m.object.created_at
              ).toLocaleString("en-US", {
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit"
              })}
              : {m.object.body}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

UserView.propTypes = {
  userInfo: PropTypes.object
};

export default UserView;
