import * as APIUtil from "../util/tickets_api_util";
export const RECEIVE_OPEN_TICKETS = "RECEIVE_OPEN_TICKETS";
export const RECEIVE_TICKET = "RECEIVE_TICKET";
export const RECEIVE_TICKET_USER = "RECEIVE_TICKET_USER";
export const RECEIVE_TICKET_LOAN = "RECEIVE_TICKET_LOAN";
export const RECEIVE_TICKET_ERRORS = "RECEIVE_TICKET_ERRORS";
export const RECEIVE_CLOSED_TICKET = "RECEIVE_CLOSED_TICKET";
export const CLEAR_TICKETS = "CLEAR_TICKETS";

export const receiveOpenTickets = (tickets) => ({
  type: RECEIVE_OPEN_TICKETS,
  tickets,
});

export const receiveTicket = (ticket) => ({
  type: RECEIVE_TICKET,
  ticket,
});

export const receiveTicketUser = (id, user) => ({
  type: RECEIVE_TICKET_USER,
  user,
  id,
});

export const receiveTicketLoan = (id, loan) => ({
  type: RECEIVE_TICKET_LOAN,
  id,
  loan,
});

export const receiveTicketErrors = (id, errors) => ({
  type: RECEIVE_TICKET_ERRORS,
  id,
  errors,
});

export const receiveClosedTicket = (id) => ({
  type: RECEIVE_CLOSED_TICKET,
  id,
});

export const clearTickets = () => ({
  type: CLEAR_TICKETS
});

export const getOpenTickets = (num) => (dispatch) =>
  APIUtil.getOpenTickets(num).then((payload) => {
    dispatch(receiveOpenTickets(payload));
  });

export const getTicket = (id, preview) => (dispatch) =>
  APIUtil.getTicket(id, preview).then(
    (payload) => {
      dispatch(receiveTicket(payload));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const closeTicket = (id) => (dispatch) =>
  APIUtil.closeTicket(id).then(
    (payload) => {
      dispatch(receiveOpenTickets(payload));
      dispatch(receiveClosedTicket(id));
      dispatch(receiveTicketErrors(id, []));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const archiveTicket = (id) => (dispatch) =>
  APIUtil.archiveTicket(id).then(
    (payload) => {
      dispatch(receiveOpenTickets(payload));
      dispatch(receiveTicketErrors(id, []));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const unarchiveTicket = (id) => (dispatch) =>
  APIUtil.unarchiveTicket(id).then(
    (payload) => {
      dispatch(receiveOpenTickets(payload));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const escalateTicket = (id) => (dispatch) =>
  APIUtil.escalateTicket(id).then(
    (payload) => {
      dispatch(receiveOpenTickets(payload));
      dispatch(receiveTicketErrors(id, []));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const deEscalateTicket = (id) => (dispatch) =>
  APIUtil.deEscalateTicket(id).then(
    (payload) => {
      dispatch(receiveOpenTickets(payload));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const fetchUserInfo = (id) => (dispatch) =>
  APIUtil.fetchUserInfo(id).then(
    (user) => {
      dispatch(receiveTicketUser(id, user));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const editUserInfo = (id, parameter, text) => (dispatch) =>
  APIUtil.editUserInfo(id, parameter, text).then(
    (user) => {
      dispatch(receiveTicketUser(id, user));
      dispatch(receiveTicketErrors(id, []));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const fetchLoanInfo = (id, loanId) => (dispatch) =>
  APIUtil.fetchLoanInfo(loanId).then(
    (loan) => {
      dispatch(receiveTicketLoan(id, loan));
      dispatch(receiveTicketErrors(id, []));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const createEarlyRepay = (id, loanId, date) => (dispatch) =>
  APIUtil.createEarlyRepay(loanId, date).then(
    (loan) => {
      dispatch(receiveTicketLoan(id, loan));
      dispatch(receiveTicketErrors(id, []));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const cancelEarlyRepay = (id, pmtId) => (dispatch) =>
  APIUtil.cancelEarlyRepay(pmtId).then(
    (loan) => {
      dispatch(receiveTicketLoan(id, loan));
      dispatch(receiveTicketErrors(id, []));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const updatePaymentDate = (id, pmtId, date) => (dispatch) =>
  APIUtil.updatePaymentDate(pmtId, date).then(
    (loan) => {
      dispatch(receiveTicketLoan(id, loan));
      dispatch(receiveTicketErrors(id, []));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const togglePaymentMethod = (id, pmtId) => (dispatch) =>
  APIUtil.togglePaymentMethod(pmtId).then(
    (loan) => {
      dispatch(receiveTicketLoan(id, loan));
      dispatch(receiveTicketErrors(id, []));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const reOpenApproval = (id, loanId) => (dispatch) =>
  APIUtil.reOpenApproval(loanId).then(
    (loan) => {
      dispatch(receiveTicketLoan(id, loan));
      dispatch(receiveTicketErrors(id, []));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const smallerPayment = (id, pmtId, amt) => (dispatch) =>
  APIUtil.smallerPayment(pmtId, amt).then(
    loan => {
      dispatch(receiveTicketLoan(id, loan));
      dispatch(receiveTicketErrors(id, []));
    },
    errors => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const settle = (id, settlement) => (dispatch) =>
  APIUtil.settlement(settlement).then(
    loan => {
      dispatch(receiveTicketLoan(id, loan));
      dispatch(receiveTicketErrors(id, []));
    },
    errors => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const creditBounceFee = (id, pmtId) => (dispatch) =>
  APIUtil.creditBounceFee(pmtId).then(
    (loan) => {
      dispatch(receiveTicketLoan(id, loan));
      dispatch(receiveTicketErrors(id, []));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );

export const linkTicket = (userID, ticketID, email) => (dispatch) =>
  APIUtil.linkTicket(userID, ticketID, email).then((ticket) =>
    dispatch(receiveTicket(ticket))
  );

export const toggleACH = (id, loanId) => (dispatch) =>
  APIUtil.toggleACH(loanId).then(
    (loan) => {
      dispatch(receiveTicketLoan(id, loan));
      dispatch(receiveTicketErrors(id, []));
    },
    (errors) => {
      dispatch(receiveTicketErrors(id, errors.responseJSON.errors));
    }
  );
