import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {asyncFetchPostRequest, asyncFetchPutRequest} from "../../util/async_api_util";

const URLS = Object.freeze( {
  CREATE_NEW_ADJUSTMENT: 'internal/api/cr_adj/create_and_notify',
});

// Async function to submit the request for a new CR Adjustment
export const createNewAdjustmentAndNotify = createAsyncThunk('creditReportAdjustments/createNewAdjustmentAndNotify',
  async ({userId, loanId, adjType, reason, genAdjFile}, {rejectWithValue}) => {
    const response = await asyncFetchPutRequest(URLS.CREATE_NEW_ADJUSTMENT, {user_id: userId, loan_id: loanId, adj_type: adjType, reason: reason, gen_adj_file: genAdjFile});
    const respCode = response.status;
    const data = await response.json();
    if (respCode !== 201) { // The request failed to create a new adjustment. Fire a rejection
      return rejectWithValue(data, {respCode: respCode});
    }
    return data;
  },
);

export const selectCreateAdjustmentErrors = state => state.entities.creditReportAdjustments.createAdjustmentErrors;
export const selectCreateAdjustmentResults = state => state.entities.creditReportAdjustments.createAdjustmentResults;

export const creditReportAdjustmentsSlice = createSlice({
  name: 'creditReportAdjustments',
  initialState: {
    loanId: null,
    userId: null,
    adjType: null,
    status: null,
    createAdjustmentErrors: null,
    createAdjustmentResults: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(createNewAdjustmentAndNotify.pending, (state, {payload}) => {
      console.log('Add adjustment request is pending...');
    }).addCase(createNewAdjustmentAndNotify.fulfilled, (state, {payload}) => {
      // reset adj errors
      state.createAdjustmentErrors = null;
      // update adj state
      state.createAdjustmentResults = payload.results;
    }).addCase(createNewAdjustmentAndNotify.rejected, (state, action) => {
      // reset adj results
      state.createAdjustmentResults = null;
      if (action.meta.rejectedWithValue) {
        // This indicates that the response code was not 201, and we have a message from the server
        state.createAdjustmentErrors = action.payload.errors;
      } else {
        state.createAdjustmentErrors = 'There was an error on the server. Please contact Engineering.';
      }
    });
  }
});

export default creditReportAdjustmentsSlice.reducer;