import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideFlashMessage } from '../features/flashMessage/flashMessageSlice';
import '../styles/css/FlashMessage.css';

const FlashMessage = ({ defaultDuration = 7 }) => {
  const dispatch = useDispatch();
  const { message, duration, visible } = useSelector((state) => state.flashMessage);

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        dispatch(hideFlashMessage());
      }, (duration || defaultDuration) * 1000);

      return () => clearTimeout(timer);
    }
  }, [visible, duration, defaultDuration, dispatch]);

  if (!visible) return null;

  return (
    <div className="flash-message">
      <span>{message}</span>
      <button onClick={() => dispatch(hideFlashMessage())}>X</button>
    </div>
  );
};

export default FlashMessage;