import React, { Component } from "react";
import Modal from "./Modal";
import { Document, Page, pdfjs } from "react-pdf";

class StatementView extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, counter: "" };
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }

  render() {
    let pages = [];
    if (this.state.open) {
      for (let i = 0; i < Object.values(this.props.loan.statements)[this.state.open - 1].page_number; i++) {
        pages.push(<Page pageNumber={i + 1} key={i} />);
      }
    }

    return (
      <div>
        {this.state.open ? (
          <Modal
            closeAction={() => this.setState({ open: false })}
            text={
              <div>
                <Document file={Object.values(this.props.loan.statements)[this.state.open - 1].url}>{pages}</Document>
              </div>
            }
          />
        ) : (
          <div>
            {Object.keys(this.props.loan.statements || {}).map((_, i) => [
              <button
                className="statementButton"
                onClick={() => this.setState({ open: i + 1 })}
                key={i}
              >
                Statement {i + 1}
              </button>,
              <br key={(i + 1) * 20} />
            ])}
          </div>
        )}
        <div className="manual-buttons">
          {((this.props.loan.statements?.length >= 3 &&
            !this.props.loan.denialSent &&
            !this.props.loan.stage3) ||
            this.props.loan.manuals) &&
          !this.props.loan.approved ? (
            <button
              onClick={() => this.props.manualDenialEmail(this.props.loan.id)}
            >
              {" "}
              Manual Denial
            </button>
          ) : null}
          {((this.props.loan.statements?.length >= 3 &&
            !this.props.loan.approved &&
            !this.props.loan.stage3) ||
            this.props.loan.manuals) &&
          !this.props.loan.denialSent &&
          this.props.loan.deny_reason !== "stage_3" ? (
            <div>
              <button
                onClick={() => {
                  this.props.manualApproval(
                    this.props.loan.id,
                    this.state.counter
                  );
                  this.setState({ counter: "" });
                }}
              >
                Manual Approval
              </button>
              <br />
              <input
                type="text"
                value={this.state.counter}
                onChange={e => this.setState({ counter: e.target.value })}
                placeholder="$$$Counter$$$"
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default StatementView;
