export const selectAllTexts = (texts, userId) => {
  if (!texts) texts = {};
  return Object.values(texts).filter(text => text.user_id === userId);
};

export const selectAllLoans = loans => {
  return Object.values(loans);
};

export const selectUserLoans = (loans, loan_ids) => {
  loan_ids = loan_ids || [];
  return loan_ids
    .sort((a, b) => a - b)
    .map(id => loans[id])
    .filter(loan => loan !== undefined);
};

export const selectRecentLoan = (loans, loan_ids) => {
  loan_ids = loan_ids || [];
  const sorted = loan_ids
    .sort((a, b) => a - b)
    .map(id => loans[id])
    .filter(loan => loan !== undefined);

  let selected = sorted[sorted.length - 1];

  sorted.forEach(loan => {
    if (loan.payments && Object.values(loan.payments).length > 0) {
      selected = loan;
    }
  });
  return selected;
};
