import React, { useState, useEffect } from "react";

const DebouncedButton = ({ delay = 2000, onClick, ...props }) => {
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!isDisabled) {
      // timeout elapsed, nothing to do
      return;
    }

    // isDisabled was changed to true, set back to false after `delay`
    const handle = setTimeout(() => {
      setDisabled(false);
    }, delay);
    return () => clearTimeout(handle);
  }, [isDisabled, delay]);

  const handleClick = (e) => {
    if (isDisabled) {
      return;
    }

    setDisabled(true);
    return onClick(e);
  };
  return <button {...props} disabled={isDisabled} onClick={handleClick} />;
};

export default DebouncedButton;
