import React from "react";
import { bankruptcyDetail } from "../../../features/fcra/fcraSlice";
import { useSelector } from "react-redux";
import { formatYearFirstDashDate } from "../../../helpers/date_helper";

export default function BankruptcyDetail() {
  const bankruptcies = useSelector(bankruptcyDetail);

  if (!bankruptcies) return null;

  return (
    <div>
      <h2>
        <b>Bankruptcy Details</b>
      </h2>
      <ul className="fcra-info" style={{ padding: 0, margin: 0 }}>
        {Object.entries(bankruptcies).map(([id, bankruptcy]) => (
          <li key={id} className="payment-detail">
            <span data-label="Case code:">{bankruptcy.case_code}</span>
            <ul
              className="fcra-info"
              style={{ padding: 0, margin: 0, marginLeft: "20px" }}
            >
              <li className="payment-detail" data-label="Type:">
                {bankruptcy.b_type}
              </li>
              <li className="payment-detail" data-label="File date:">
                {formatYearFirstDashDate(bankruptcy.filed)}
              </li>
              <li className="payment-detail" data-label="Discharge date:">
                {formatYearFirstDashDate(bankruptcy.discharged)}
              </li>
            </ul>
          </li>
        ))}
      </ul>
      <br></br>
    </div>
  );
}
