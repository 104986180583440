import React, { useEffect, useState } from "react";
import Loader from "react-loader";
import { asyncFetchPostRequest } from "../util/async_api_util";
import DebouncedButton from "./DebouncedButton";
import Modal from "./Modal";

const SendAccountStatusModal = ({ closeMethod }) => {
  const ModalState = Object.freeze({
    LANDING: null,
    SUCCESS: "success",
    FAILURE: "failure",
    PENDING: "pending",
  });

  const baseFormState = {
    loan_id: "",
    send_email: "",
  };

  const [modalState, setModalState] = useState(ModalState.LANDING);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState(baseFormState);

  // initialize the state of the modal when the user opens it after completing another action without a full refresh
  useEffect(() => {
    resetForm();
  }, []);

  const validateInput = () => {
    if (
      !formData.loan_id ||
      (formData.loan_id && formData.loan_id.trim() === "")
    ) {
      return "Please enter a loan id";
    } else if (
      formData.send_email != "true" &&
      formData.send_email != "false"
    ) {
      return "Please select whether or not to email the letter directly to the customer";
    } else {
      return null;
    }
  };

  const handleSubmit = async () => {
    let error = validateInput();
    if (error) {
      setErrorMessage(error);
      return;
    } else {
      setErrorMessage();
      setModalState(ModalState.PENDING);
      const response = await asyncFetchPostRequest(
        "internal/api/users/send_account_status",
        formData
      );
      const data = await response.json();
      if (response.status === 200) {
        setModalState(ModalState.SUCCESS);
      } else {
        setErrorMessage(
          data.error || "Please reach out to internal tools/engineering"
        );
        setModalState(ModalState.FAILURE);
      }
    }
  };

  const closeModal = () => {
    resetForm();
    closeMethod();
  };

  const resetForm = () => {
    setModalState(ModalState.LANDING);
    setErrorMessage();
    setFormData(baseFormState);
  };

  return (
    <Modal open={true} closeAction={closeModal}>
      {modalState === ModalState.LANDING && (
        <div>
          <h1>Generate account status letter</h1>
          <br></br>
          <form>
            <label htmlFor="loan_id">
              <b>Loan id:</b>
            </label>
            &nbsp;&nbsp;
            <input
              type="number"
              id="loan_id"
              name="loan_id"
              onChange={(e) =>
                setFormData({ ...formData, loan_id: e.target.value })
              }
            ></input>
            <br></br>
            <br></br>
            <div>
              <h2>
                <b>
                  Would you like to email the letter or just generate and save
                  it?
                </b>
              </h2>
              <input
                type="radio"
                id="generate_and_send"
                name="send_email"
                value={true}
                onClick={(e) =>
                  setFormData({ ...formData, send_email: e.target.value })
                }
              />
              <label htmlFor="generate_and_send">Send to customer</label>
              <input
                type="radio"
                id="generate_letter"
                name="send_email"
                value={false}
                onClick={(e) =>
                  setFormData({ ...formData, send_email: e.target.value })
                }
              />
              <label htmlFor="send_email">Generate and save letter only</label>
            </div>
          </form>
          <br></br>
          {errorMessage && (
            <div style={{ color: "red", fontWeight: "bold" }}>
              {errorMessage}
            </div>
          )}
          <DebouncedButton onClick={handleSubmit}>Submit</DebouncedButton>
        </div>
      )}
      {modalState === ModalState.PENDING && (
        <div style={{ minHeight: "10vh" }}>
          <h1>Processing</h1>
          <div>
            <Loader loaded={false} style={{ color: "teal" }}></Loader>
          </div>
        </div>
      )}
      {modalState === ModalState.SUCCESS && (
        <div>
          <h1>Success!</h1>
          <div>{`Account status letter generated ${
            formData.send_email === "true" ? "and sent" : ""
          }`}</div>
          <button onClick={() => closeModal()}>Close</button>
        </div>
      )}
      {modalState === ModalState.FAILURE && (
        <div>
          <h1>Something went wrong</h1>
          {errorMessage && (
            <div style={{ color: "red", fontWeight: "bold" }}>
              {errorMessage}
            </div>
          )}
          <button onClick={() => resetForm()}>Go back</button>
          <button onClick={() => closeModal()}>Close</button>
        </div>
      )}
    </Modal>
  );
};

export default SendAccountStatusModal;
