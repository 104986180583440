import {
  RECEIVE_IDENTIFICATIONS,
  COMPLETE_IDENTIFICATION
} from "../actions/task_actions";

const defaultProps = { identifications: [] };

const identificationsReducer = (oldState = defaultProps, action) => {
  Object.freeze(oldState);
  let newState;
  switch (action.type) {
    case RECEIVE_IDENTIFICATIONS:
      newState = Object.assign({}, oldState, action.identifications);
      return newState;
    case COMPLETE_IDENTIFICATION:
      newState = Object.assign({}, oldState, {});
      delete newState.identifications[action.id];
      return newState;
    default:
      return oldState;
  }
};

export default identificationsReducer;
