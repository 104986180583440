import React, { useState } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import Tip from "../Tip";

const TicketStats = ({ 
  totalTicketCount, 
  ticketsWithPaymentsDueTmrwCount, 
  ticketwWithPaymentDue2DaysCount, 
  ticketsCreatedMoreThan24HrsAgoCount,
  ticketsCreatedLessThan24HrsAgoCount,
  employeeTicketCount,
  closedTickets,
  currEmployeeOnline
 }) => {

  const [showList, setShowList] = useState(false);
  
  const ticketStatsDetails = () => {
    if (currEmployeeOnline) {
      return (
        <span className="tiptext ticket-stats-tiptext">
          <div style={{ width: "175px"}}>
            <p>Total unanswered: <b>{totalTicketCount}</b> </p>
            <p>Assigned to you: <b>{employeeTicketCount}</b> </p>
            <p>Pmt scheduled tmrw: <b>{ticketsWithPaymentsDueTmrwCount}</b></p>
            <p>Pmt scheduled 2 days: <b>{ticketwWithPaymentDue2DaysCount}</b></p>
            <p>Created &gt; 24 hrs ago: <b>{ticketsCreatedMoreThan24HrsAgoCount}</b></p>
            <p>Created &lt; 24 hrs ago: <b>{ticketsCreatedLessThan24HrsAgoCount}</b></p>
            <p>Tickets you've closed: <b>{closedTickets.length}</b> </p>
          </div> 
        </span>
      )
    } else {
      return (
        <div></div>
      )
    }
  }

    return (
      <Tip text={ticketStatsDetails()}>
        <>
        <motion.div
          className="ticket-stats"
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: 1 }}
          whileTap={{ scale: 0.9 }}
          transition={{ opacity: { duration: 1 } }}
          onClick={() => {
            if (employeeTicketCount && employeeTicketCount.length >= 1) {
              setShowList((s) => !s);
            }
          }}
        >
          <motion.span
            animate={showList ? "open" : "closed"}
            initial="closed"
            variants={rotateVariants}
            className="fa-stack has-badge"
          >
            <i data-count={employeeTicketCount} className="fas fa-ticket"></i>
          </motion.span>
        </motion.div>
        </>
      </Tip>
    );
};

const rotateVariants = {
  open: {
    scale: 1.2,
    transition: {
      duration: 0.1,
    },
  },
  closed: {
    scale: 1,
    transition: {
      duration: 0.1,
    },
  },
};

const mapStateToProps = (state) => ({
  totalTicketCount: state.entities.tickets.totalTicketCount,
  ticketsWithPaymentsDueTmrwCount: state.entities.tickets.unpaidPaymentDueTmrwCount,
  ticketwWithPaymentDue2DaysCount: state.entities.tickets.unpaidPaymentDueDayAfterTmrwCount,
  ticketsCreatedMoreThan24HrsAgoCount: state.entities.tickets.createdOver24hrsAgoCount,
  ticketsCreatedLessThan24HrsAgoCount: state.entities.tickets.createdUnder24hrsAgoCount,
  closedTickets: state.entities.tickets.closedTickets,
  employeeTicketCount: state.entities.tickets.employeeTicketCount,
  currEmployeeOnline: state.session.currentUser.online
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TicketStats);
