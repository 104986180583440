import React, {useState} from 'react'
import {originateMessage} from '../util/user_api_util'

export default function MessageOrigination(props) {
  const [subject, setSubject] = useState()
  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState('email')
  const [thread, setThread] = useState('new')
  const sendMessage = originateMessage

  return (
    <div className='message-origination-box'>
      <div>
        <select className='messageType' onChange={e => setMessageType(e.target.value)}>
          <option name='messageType' value='email' selected>Email</option>
          <option name='messageType' value='text'>Text</option>
        </select>
        <select className='thread' onChange={e => setThread(e.target.value)}>
          <option name='thread' value='new' selected>New</option>
          <option name='thread' value='existing'>Existing</option>
        </select>
      </div>
      <textarea
        value={subject}
        onChange={e => setSubject(e.target.value)}
        placeholder={'Subject'}
        style={{width: 200, height: 15}}
        />

      <textarea
        value={message}
        onChange={e =>
          setMessage(e.target.value)
        }
        style={{ width: 200, height: 100 }}
        onKeyPress={e =>
          props.checkMacro(e, message, macroAddedMessage =>
            setMessage(macroAddedMessage)
          )
        }
      />
      <button
        onClick={() => {
          props.setSending(true);
          sendMessage(props.userId, subject, message, messageType, thread).then(
            success => alert(success.success[0]),
            error => alert(error.responseJSON.error)
          )
          setMessage('')
          setSubject('')
          props.setSending(false)
        }}
      >
        Send
      </button>
    </div>
  )
}
