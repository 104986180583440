import { motion } from "framer-motion";
import React, { useState } from "react";
import { connect } from "react-redux";
import { archiveTicket, closeTicket } from "../../../actions/ticket_actions";
import { createCookie } from "../../../helpers/cookie_helper";

const ArchiveClose = ({
  id,
  submit,
  archiveTicket,
  closeTicket,
  replyMessage,
  setReplyMessage,
}) => {
  const [disabled, setDisabled] = useState(false);

  const handleArchive = () => {
    console.log("@handleArchive", id.toString());
    createCookie(id.toString(), replyMessage, 1);
    setReplyMessage("");
    setDisabled(true);
    archiveTicket(id).then(() => setDisabled(false));
  };

  const handleClose = () => {
    setReplyMessage("");
    setDisabled(true);
    submit();
    closeTicket(id).then(() => setDisabled(false));
  };

  return (
    <div className="archive-close">
      <motion.button
        initial={{ scale: 1 }}
        whileTap={{ scale: 0.9 }}
        className="ui-button"
        onClick={handleClose}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
        disabled={disabled}
      >
        Close<i className="fas fa-times button-icon"></i>
      </motion.button>
      <motion.button
        initial={{ scale: 1 }}
        whileTap={{ scale: 0.9 }}
        className="ui-button dark-ui"
        onClick={handleArchive}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
        disabled={disabled}
      >
        Archive<i className="fas fa-box-archive button-icon"></i>
      </motion.button>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  archiveTicket: (id) => dispatch(archiveTicket(id)),
  closeTicket: (id) => dispatch(closeTicket(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveClose);
