import { merge } from "lodash";
import { RECEIVE_LOADING } from "../actions/loader_actions";

const defaultProps = { loading: false };

const uiReducer = (oldState = defaultProps, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_LOADING:
      return merge({}, oldState, { loading: action.loading });
    default:
      return oldState;
  }
};

export default uiReducer;
