import React from "react";
import Loader from "react-loader";
import { connect } from "react-redux";

const ConnectedLoader = ({ loading, children, color }) => {
  return (
    <Loader loaded={!loading} options={{ color }}>
      {children}
    </Loader>
  );
};

const mapStateToProps = state => ({
  loading: state.ui.loading
});

export default connect(mapStateToProps)(ConnectedLoader);
