import * as APIUtil from "../util/loan_api_util";
import { receiveEditErrors } from "./error_actions";
export const RECEIVE_LOANS = "RECEIVE_LOANS";
export const RECEIVE_INFO = "RECEIVE_INFO";

export const receiveLoans = loans => ({
  type: RECEIVE_LOANS,
  loans
});

export const receiveInfo = info => ({
  type: RECEIVE_INFO,
  info
});

export const changePayment = payment => dispatch =>
  APIUtil.changePayment(payment).then(payload =>
    dispatch(receiveLoans(payload.loans), errors =>
      dispatch(receiveEditErrors(errors))
    )
  );

export const openStatementLink = loan => dispatch =>
  APIUtil.openStatementLink(loan).then(payload =>
    dispatch(receiveLoans(payload.loans))
  );

export const splitPayment = (
  loan_id,
  payment_id,
  size,
  date,
  warning_accepted,
  onSuccess,
  onError
) => dispatch =>
  APIUtil.splitPayment(loan_id, payment_id, size, date, warning_accepted).then(
    payload => {
      onSuccess(() => {
        dispatch(receiveLoans(payload.loans));
      });
    },
    errors => {
      onError(errors.responseJSON.errors);
    }
  );

export const customPayments = (loan, date, amount, frequency) => dispatch =>
  APIUtil.customPayments(loan, date, amount, frequency).then(payload =>
    dispatch(receiveLoans(payload.loans), errors =>
      dispatch(receiveEditErrors(errors))
    )
  );

export const manualDenialEmail = loan_id => dispatch =>
  APIUtil.manualDenialEmail(loan_id).then(
    payload => dispatch(receiveLoans(payload.loans)),
    errors => dispatch(receiveEditErrors(errors))
  );

export const manualApproval = (loan_id, amount) => dispatch =>
  APIUtil.manualApproval(loan_id, amount).then(
    payload => dispatch(receiveLoans(payload.loans)),
    errors => dispatch(receiveEditErrors(errors))
  );

export const calcEarlyRepay = (loan_id, date) => dispatch =>
  APIUtil.calcEarlyRepay(loan_id, date).then(
    payload => dispatch(receiveInfo(payload)),
    errors => dispatch(receiveEditErrors(errors))
  );

export const createEarlyRepay = (loan_id, date) => dispatch =>
  APIUtil.createEarlyRepay(loan_id, date).then(
    payload => dispatch(receiveLoans(payload.loans)),
    errors => dispatch(receiveEditErrors(errors))
  );

export const cancel = loan_id => dispatch =>
  APIUtil.cancel(loan_id).then(
    payload => dispatch(receiveLoans(payload.loans)),
    errors => dispatch(receiveEditErrors(errors))
  );

export const confirmCB = loan_id => dispatch =>
  APIUtil.confirmCB(loan_id).then(
    payload => dispatch(receiveLoans(payload.loans)),
    errors => dispatch(receiveEditErrors(errors))
  );

export const toggleACH = loan_id => dispatch =>
  APIUtil.toggleACH(loan_id).then(
    payload => dispatch(receiveLoans(payload.loans)),
    errors => dispatch(receiveEditErrors(errors))
  );

export const clearAlert = loan_id => dispatch =>
  APIUtil.clearAlert(loan_id).then(
    payload => dispatch(receiveLoans(payload.loans)),
    errors => dispatch(receiveEditErrors(errors))
  );

export const editInfo = (parameter, text, loan_id, force) => dispatch =>
  APIUtil.editInfo(parameter, text, loan_id, force).then(
    payload => dispatch(receiveLoans(payload.loans)),
    errors => dispatch(receiveEditErrors(errors))
  );

export const reopenLoan = loan_id => dispatch =>
  APIUtil.reopenLoan(loan_id).then(
    payload => dispatch(receiveLoans(payload.loans)),
    errors => dispatch(receiveEditErrors(errors))
  );

export const submitImmediateAch = (
  loanId,
  paymentId,
  onSuccess,
  onError
) => dispatch => {
  APIUtil.submitImmediateAch(loanId, paymentId).then(
    task => {
      onSuccess();
    },
    errors => {
      onError(errors["responseJSON"].errors[0]);
    }
  );
};

export const submitDecision = (
  loanId,
  decision,
  onSuccess,
  onError
) => dispatch => {
  APIUtil.submitDecision(loanId, decision).then(
    payload => {
      onSuccess();
      dispatch(receiveLoans(payload.loans));
    },
    errors => {
      onError(errors["responseJSON"].errors[0]);
    }
  );
};

export const createLargerPayment = (
  loan_id,
  payment_id,
  amount,
  onSuccess,
  onError
) => dispatch =>
  APIUtil.createLargerPayment(
    loan_id,
    payment_id,
    amount,
    onSuccess,
    onError
  ).then(
    payload => {
      dispatch(receiveLoans(payload.loans));
      onSuccess();
    },
    errors => onError(errors)
  );

export const creditLoan = (
  loan_id,
  credit_amount,
  onSuccess,
  onError
) => dispatch =>
  APIUtil.creditLoan(loan_id, credit_amount).then(
    payload => {
      dispatch(receiveLoans(payload.loans));
      onSuccess(payload);
    },
    errors => {
      onError(errors);
    }
  );

export const appeal = loan_id =>
  APIUtil.appeal(loan_id).then(() => alert("Appeal successful"));
