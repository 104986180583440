import React, { Component } from "react";
import Identification from "./Identification";

class UserIdentification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotation: 0
    };
  }

  render() {
    const analytics = this.props.user_identifications.analytics;
    let color = "id_yellow";
    switch (analytics.aggregate_check) {
      case -1:
        color = "id_red";
        break;
      case 0:
        color = "id_yellow";
        break;
      case 1:
        color = "id_green";
        break;
      default:
        color = "id_yellow";
    }

    return (
      <div className="identification">
        <div className={"row_container " + color}>
          {Object.values(this.props.user_identifications.images).map(
            identification => (
              <Identification
                key={identification.ident_id}
                identification={identification}
              />
            )
          )}
        </div>
        <div className={"info_box "}>
          <p>User name: {this.props.user_identifications.user_name}</p>
          <p>Birthday: {this.props.user_identifications.birthday}</p>
          <p>
            Drivers License Number:{" "}
            {this.props.user_identifications.drivers_license
              ? this.props.user_identifications.drivers_license
              : "Unavailable"}
          </p>
          <p>User Id: {this.props.user_identifications.user_id}</p>

          {Object.keys(analytics)
            .sort()
            .map((key, i) => (
              <p key={key}>
                {key && key !== "job_id" ? (
                  analytics[key] !== undefined && analytics[key] ? (
                    <span className={analytics[key] === false ? "id_red" : ""}>
                      {key.toString() + ": " + analytics[key].toString()}
                    </span>
                  ) : (
                    <span className="id_red">{key.toString() + ": false"}</span>
                  )
                ) : (
                  ""
                )}
              </p>
            ))}
          <button
            onClick={() => {
              this.props.completeIdentification(
                this.props.user_identifications.user_id,
                "approve"
              );
            }}
          >
            Approve
          </button>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <button
            onClick={() => {
              this.props.completeIdentification(
                this.props.user_identifications.user_id,
                "request"
              );
            }}
          >
            Re-Request
          </button>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <button
            onClick={() => {
              this.props.completeIdentification(
                this.props.user_identifications.user_id,
                "cancel"
              );
            }}
          >
            Cancel Loan
          </button>
          <button
            onClick={() => {
              this.props.completeIdentification(
                this.props.user_identifications.user_id,
                "selfie"
              );
            }}
          >
            Selfie Cancel
          </button>
        </div>
        <div className="clear"></div>
      </div>
    );
  }
}

export default UserIdentification;
