import moment from "moment";

export const formatDate = d => {
  var month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

// Converts YYYYMMDD => MM/DD/YYYY
export const formatYearFirstDate = d => {
  if (!d) return null;

  const year = d.slice(0,4)
  const month = d.slice(4,6)
  const day = d.slice(6,8)
  return [month, day, year].join("/")
};

// Converts YYYY-MM-DD => MM/DD/YYYY
export const formatYearFirstDashDate = d => {
  if (!d) return null;
  
  const cleaned = d.replace(/-/g, "");
  return formatYearFirstDate(cleaned);
}

export const formatJSONDate = d => {
  if (d) {
    return d.split("T")[0];
  } else {
    return null;
  }
};

// e.g. Tuesday, November 28th 2023
export const prettifyDateLong = d => {
  if (d) {
    return moment.utc(d).format("dddd, MMMM Do YYYY");
  } else {
    return null;
  }
};

export const transformAccountInfoDate = (date) => {
  if (!date) {
    return null
  } else {
    let year = date.substring(0,4)
    let month = date.substring(4,6)
    let day = date.substring(6,8)
    return `${month}/${day}/${year}`
  }
}