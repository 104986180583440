import React from 'react';
import '../styles/css/tip.css'

const Tip = ({ text, children }) => {

  return (
    <div className="tip">
      {children}
      {text}
    </div>
  )

  };

export default Tip;